import React, {useEffect, useState, useRef} from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {Modal} from 'react-bootstrap';
import AxiosInstance from '../helpers'
import URI_BACKEND from '../confProyecto'


const SwalAlert = withReactContent(Swal);

const sizeTextBody = 11


const Empleado = (props)=>{


    const [empleado, setEmpleado] = useState({
        idempleado : 0,
        nombre_empleado : '',
        apellido_empleado : '',
        dpi_empleado : '',
        telefono_empleado: '',
        direccion_empleado : '',
        url_photo : null,
        photo : null,
    })

    const refFoto = useRef();
    const [empleados, setEmpleados] = useState([])
    const [url_foto_temp, setUrlTemp] = useState(''); 
    const [IsOpen, setIsOpen] = useState(false);
    const [textBtn, setTextBtn] = useState('Subir Foto');
    const [colorBtn, setColorBtn] = useState('Subir Foto');


    const {idempleado, nombre_empleado, apellido_empleado, dpi_empleado, telefono_empleado, direccion_empleado, url_photo, photo} = empleado;

    


    useEffect(()=>{
      
        getEmployees().then(()=>{
            console.log('200');
        });

    }, [])


    useEffect(()=>{
       if(photo){
           setUrlTemp(URL.createObjectURL(photo));
           setTextBtn('Cargada');
           setColorBtn('success');
       }else{
           if(IsOpen){
               setTextBtn('Cambiar Foto');
           }else{
              setTextBtn('Subir Foto');
           }
            setColorBtn('info');
       }
    }, [photo]);

    const onChangeValue = (e)=>{
        
        let name = e.target.name;
        let value = e.target.type === 'file'? e.target.files[0] :  [e.target.value];

        setEmpleado(emp => ({
            ...emp,
           [name] : value
        }))

    }

    const saveOrUpdateEmployee = async (e) => {

        const action = e.target.name !== 'update' ? 1 : 2;   // 1 = Para crear un usuario y 2 = Para actualizar
        console.log(empleado);
         
        if (empleado.nombre_empleado === '' || empleado.apellido_empleado === '' || dpi_empleado === ''){
            SwalAlert.fire({
                title: 'Nombre, apellido y DPI requeridos',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
                }).then(result => {
                    return;
                });    
        }else{

            try {
                console.log(empleado);
                let form = new FormData();
                    
                form.append('idempleado', empleado.idempleado);
                form.append('nombre_empleado', empleado.nombre_empleado);
                form.append('apellido_empleado', empleado.apellido_empleado);
                form.append('dpi_empleado', empleado.dpi_empleado);
                form.append('telefono_empleado', empleado.telefono_empleado);
                form.append('direccion_empleado', empleado.direccion_empleado);
                form.append('action', action);
                form.append('url_photo', empleado.url_photo);
    
    
                if (empleado.photo !== null && empleado.photo !== undefined){
                    form.append('photo', empleado.photo)
                }
    
                SwalAlert.fire({
                    title: 'Espere',
                    html: 'Guardando...',
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                      Swal.showLoading()
                    }
                })
    
                let usuario = await AxiosInstance({
                    method : 'post',
                    url : '/api/saveOrUpdateEmployee',
                    data : form,
                    headers : {
                        'Content-Type' : 'multipart/form-data'
                    }  
                })
                
                await getEmployees();

                SwalAlert.close();
                closeAndClean();

            }catch(e){
                console.log(e);
            }



        }
    }

    const updateEmpleado = (row)=> {
        setEmpleado ({
            idempleado : row.idempleado,
            nombre_empleado : row.nombre_empleado,
            apellido_empleado : row.apellido_empleado,
            dpi_empleado : row.dpi_empleado,
            telefono_empleado : row.telefono_empleado,
            direccion_empleado : row.direccion_empleado,
            url_photo : row.url_photo
        });
        let url = row.url_photo ? `${URI_BACKEND}${row.url_photo}` : 'images/anonimo.png'
        setUrlTemp(url);
        setIsOpen(true);
    }


    const closeAndClean = ()=>{
        setEmpleado ({
            idempleado : 0,
            nombre_empleado : '',
            apellido_empleado : '',
            dpi_empleado : '',
            telefono_empleado: '',
            direccion_empleado : '',
            url_photo : null,
            photo : null,
        });
       setUrlTemp('');
       setIsOpen(false);
    }

    const deleteEmpleado = (row)=>{
        SwalAlert.fire({
            title: `¿Seguro que desea eliminar a ${row.nombre_empleado}?`,
            text: "Debes pensarlo despacio",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí eliminar',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {

            if (result.value) {
                
                try{
                    let deleted =  await AxiosInstance({
                        method : 'post',
                        url : '/api/deleteEmployee',
                        data : {
                            idempleado : row.idempleado
                        }
                    });
            
                    await getEmployees();

                    SwalAlert.close();
                    SwalAlert.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'Eliminado exitosamente',
                            showConfirmButton: false,
                            timer: 900
                    }).then(result => {
                        SwalAlert.close();
                        return;
                    })
                   
                  } catch(e){
                     console.log(e);
                  } 
            }
          })
    }

    const getEmployees = async ()=>{
        let res = await AxiosInstance({
            method : 'get',
            url: '/api/getEmployees'
        });

        setEmpleados(res.data);
    }


    const fotoFormatter = (cell, row) =>{
    
        let url = '';
        let alto = 0;

        if(cell){
            url = `${URI_BACKEND}${cell}`;
            alto = 50;
        }else{
            url = 'images/anonimo.png';
            alto = 40;
        }
        return <img src={url} style={{width: 50, height:alto, borderRadius: "50%"}}/>
    }


    const editFormatter = (cell, row)=>{
        return <button onClick={()=>updateEmpleado(row)} className="btn btn-info"><i className="fa fa-edit"></i></button>
    }

    const deleteFormatter = (cell, row)=>{
        return <button onClick={()=>deleteEmpleado(row)} name="delete" className="btn btn-danger"><i className="fa fa-cut"></i></button>
    }

    



    return (
        <div className="container">

        <Modal show={IsOpen}>
                            <Modal.Header>
                            <Modal.Title>Actualizar Empleado</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex justify-content-center">
                                    <img src={url_foto_temp} style={{width : '30%', borderRadius: '50%'}} alt="photo"></img>
                                </div>
                                <br></br>
                                <div className="d-flex justify-content-center">
                                    <button className={`btn btn-${colorBtn}`}  onClick={()=>refFoto.current.click()}>{textBtn}</button>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-10">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td> <label htmlFor="nombre_empleado">Nombre:</label></td>
                                                    <td> <input type="text" name="nombre_empleado" value={nombre_empleado} className="form-control" onChange={onChangeValue}/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="apellido_empleado">Apellido:</label></td>
                                                    <td> <input type="text" name="apellido_empleado" value={apellido_empleado} className="form-control" onChange={onChangeValue}/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="dpi_empleado">DPI:</label></td>
                                                    <td> <input type="number" name="dpi_empleado" value={dpi_empleado} className="form-control" onChange={onChangeValue}/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="telefono_empleado">Telefono:</label></td>
                                                    <td> <input type="number" name="telefono_empleado" value={telefono_empleado}  className="form-control" onChange={onChangeValue}/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="direccion_empleado">Dirección:</label></td>
                                                    <td>   <input type="text" name="direccion_empleado" value={direccion_empleado}  className="form-control" onChange={onChangeValue}/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                       
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button name="update" className="btn btn-info" onClick={saveOrUpdateEmployee}>
                                    Guardar
                                </button>
                                <button className="btn btn-danger" onClick={closeAndClean}>
                                    Cancelar
                                </button>
                            </Modal.Footer>
        </Modal> 
        <br></br>
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-info">
                        <h6>Empleado</h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="nombre_empleado">Nombre: </label>
                                <input type="text" name="nombre_empleado" value={nombre_empleado} className="form-control" onChange={onChangeValue}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="apellido_empleado">Apellido: </label>
                                <input type="text" name="apellido_empleado" value={apellido_empleado} className="form-control" onChange={onChangeValue}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="dpi_empleado">DPI: </label>
                                <input type="number" name="dpi_empleado" value={dpi_empleado} className="form-control" onChange={onChangeValue}/>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="telefono_empleado">Telefono: </label>
                                <input type="text" name="telefono_empleado" value={telefono_empleado} className="form-control" onChange={onChangeValue}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="direccion_empleado">Dirección: </label>
                                <input type="text" name="direccion_empleado" value={direccion_empleado} className="form-control" onChange={onChangeValue}/>
                            </div>
                            <div className="col-md-2">
                                <br></br>
                                <button className={`btn btn-${colorBtn}`} onClick={()=>refFoto.current.click()}><i className="fa fa-upload"></i> {textBtn}</button>
                                <input type="file" name="photo" ref={refFoto} accept="image/*" onChange={onChangeValue}/>
                            </div>
                            <div className="col-md-2">
                                <br></br>
                                <button className="btn btn-primary" name="save" onClick={saveOrUpdateEmployee}><i className="fa fa-save"></i> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br></br>
           <div className="row">
                    <div className="col-md-12">
                    <div className="card">
                    <h6 className="card-header bg-info"><i className="fas fa-list-ol"></i> LISTADO DE EMPLEADOS</h6>
                        <div className="card-body">
                              <BootstrapTable data={empleados}  search={ true } searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                                <TableHeaderColumn isKey dataField='idempleado'      dataSort={ true }  tdStyle={{fontSize:sizeTextBody, width:60}} thStyle={{fontSize: sizeTextBody,width:60, backgroundColor: 'grey', color: 'white'}}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='nombre_empleado'       dataSort={ true }  tdStyle={{fontSize:sizeTextBody, width:150}} thStyle={{fontSize: sizeTextBody, width:150, backgroundColor: 'grey', color: 'white'}}>Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField='apellido_empleado'     dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Apellido</TableHeaderColumn>
                                <TableHeaderColumn dataField='dpi_empleado'          dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>DPI</TableHeaderColumn>
                                <TableHeaderColumn dataField='telefono_empleado'     dataSort={ true }  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: sizeTextBody,  backgroundColor: 'grey', color: 'white'}}>Teléfono</TableHeaderColumn>
                                <TableHeaderColumn dataField='direccion_empleado'    dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Dirección</TableHeaderColumn>
                                <TableHeaderColumn dataField='url_photo'             dataFormat={ fotoFormatter} tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Foto</TableHeaderColumn>
                                <TableHeaderColumn dataField='idempleado'             dataFormat={ editFormatter}  tdStyle={{fontSize:sizeTextBody, width: '60px'}} thStyle={{fontSize: sizeTextBody, width: '60px', backgroundColor: 'grey', color: 'white'}}>Editar</TableHeaderColumn>
                                <TableHeaderColumn dataField='idempleado'             dataFormat={ deleteFormatter} tdStyle={{fontSize:sizeTextBody, width: '60px'}} thStyle={{fontSize: sizeTextBody, width: '60px', backgroundColor: 'grey', color: 'white'}}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                         </div>
                    </div>
                    </div>
                  </div>
      </div>  
    )



}

export default Empleado;
