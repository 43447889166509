import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosInstance from '../helpers'
import {formatearFecha} from '../helpers/helperFuncs'
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';


registerLocale('es', es)

const SwalAlertReact = withReactContent(Swal);

function Gasto (props){

    const [tipoGastoSelect, changeTipoGasto] = useState({
        value : null, label : "Seleccione"
    });

    const [listGastos, changeListGastos] = useState([]);
    const [fecha, changeFecha] = useState(new Date());
    const [monto, changeMonto] = useState(0);
    const [IsModal, changeIsModal] = useState(false);


    function cambiarDatos (){
        changeTipoGasto(props.updated.tipoGastoSelect);
        changeFecha(new Date(props.updated.fecha+"T00:00"));
        changeMonto(props.updated.monto);
        changeIsModal(true);
    }
    
    useEffect(()=>{
        if(props.updated){
            cambiarDatos();
            changeListGastos(props.options.filter(x=> x.value !== null));
        }else{
            getTiposGasto();
        }
    }, [])

    function getTiposGasto(){
        AxiosInstance({
            method : 'get',
            url : '/api/getTiposGasto'
        }).then(res => {
            const listGastos = res.data.map(gasto =>({value: gasto.idtipogasto, label : gasto.tipogasto}));
            changeListGastos(listGastos);
        }).catch(error => {
            console.log(error);
        })
    }

    function onChangeFecha(fecha){
        
        changeFecha(fecha);
    }

    function onChangeMonto(event){
        changeMonto(event.target.value);
    }

    function guardar(){

        if(monto === 0 || tipoGastoSelect.value === null){
            SwalAlertReact.fire({
                title: 'Faltan datos',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
                }).then(result => {
                    return;
                });
                return;
        }else{
            
            SwalAlertReact.fire({
                title: 'Espere',
                html: 'Guardando...',
                timerProgressBar: true,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            })

            let url = '/api/savePago';
            let method = 'post';
            let idpago = 0;
            let data = {};

            if(IsModal){
                url = '/api/updatePago';
                method = 'put';
                idpago = props.updated.idpago;
                data = {
                    idpago,
                    idtipogasto : tipoGastoSelect.value,
                    monto : Number(monto),
                    fecha : formatearFecha(fecha, "english")
                }
            }else{
                data = {
                    idtipogasto : tipoGastoSelect.value,
                    monto : Number(monto),
                    fecha : formatearFecha(fecha, "english"),
                    fecharealizado : formatearFecha(new Date(), "english"),
                    user : props.user 
                }
            }

            AxiosInstance({
                method : method,
                url : url,
                data : data
            }).then(res => {
                SwalAlertReact.close();
                SwalAlertReact.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Guardado exitosamente',
                    showConfirmButton: false,
                    timer: 1000
                }).then(res =>{
                    limpiar();

                    if(IsModal){
                        props.cerrar();
                        props.consultar();
                    }
                })

            })
        }
    }

    function regresar(){
        props.history.goBack();
    }

    function limpiar(){
        changeTipoGasto({
            value : null, label : "Seleccione..."
        });
        changeMonto(0);
    }

    return (
        <div className="container">
            <br></br>
            <div className="card row">
                <div className="card-header"><h5>Registro de Pagos</h5></div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label>Fecha de Pago:</label>
                            <DatePicker
                              className="form-control"
                              selected={fecha}
                              onChange={onChangeFecha}
                              dateFormat="dd/MM/yyyy"
                              locale="es"
                              strictParsing
                            >
                            </DatePicker>
                        </div>
                        <div className="col-md-3">
                            <label>Tipo Pago:</label>
                            <Select
                              options = {listGastos}
                              value = {tipoGastoSelect}
                              onChange = {(value)=> changeTipoGasto(value)}
                            >
                            </Select>
                        </div>
                        <div className="col-md-3">
                            <label>Monto:</label>
                            <input type="number" step="0.000001" className="form-control" value={monto} onChange={onChangeMonto}></input>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-md-3">
                                <Button variant="contained" color="primary" onClick={guardar} startIcon={<SupervisorAccountIcon/>}>Guardar</Button>
                        </div>
                        <div className="col-md-2">
                            { !IsModal ? <Button variant="contained" color="secondary" onClick={regresar} startIcon={<SupervisorAccountIcon/>}>Regresar</Button>
                            : <Button variant="contained" color="secondary" onClick={props.cerrar}>Cerrar</Button>    
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state=>({
    user : state.user
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type: "LOGOUT"});
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(Gasto);
