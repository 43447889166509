import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import {Link} from 'react-router-dom'
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AxiosInstance from '../helpers'
import {formatearFecha} from '../helpers/helperFuncs'
import {connect} from 'react-redux';

registerLocale('es', es)
let TicketComponent = (props) => {
    return(
        <div className="card">
        <h5 className="card-header bg-default"><i className="fas fa-ticket-alt"></i>  TICKET DE COMPRA # {props.numero}</h5>
                <div className="row card-body">
                    <div className="col-md-2">
                        <label>Monto Sin Merma</label>
                        <input type="text" readOnly  className="form-control" value={props.montoSinMerma}/>
                    </div>
                    <div className="col-md-2">
                        <label>Monto Con Merma</label>
                        <input type="text" readOnly  className="form-control" value={props.montoConMerma}/>
                    </div>
                    <div className="col-md-2">
                        <label>Peso Con Merma</label>
                        <input type="text" readOnly  className="form-control" value={props.pesoConMerma}/>
                    </div>
                    <div className="col-md-2">
                        <label>Saldo</label>
                        <input type="text" readOnly className="form-control" value={props.saldo}/>
                    </div>
                   
                </div>
        </div>
    )
}

const MySwal = withReactContent(Swal)

class Compra extends Component {

    constructor(props){
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.saveCompra = this.saveCompra.bind(this);
        this.refTicket = React.createRef();

        this.state = {
            IsModal : this.props.isModal,   //Para verificar si vamos a renderizar el componente formulario compra como modal o no
            proveedores: [],
            documentos: [],
            showAlert: false,
            textBtn: 'Crear Compra',
            usuarios: [],
            idproveedor: null,
            idtipodocumento: null,
            idusuario: null,
            salidaAnterior : 0,
            numero_documento: '',
            porcentaje_merma : 0,
            instanciaFormatter : new Intl.NumberFormat('en-US'),
            cantidad_compra: 0,
            peso_compra: 0,
            preciounitario_compra: 0,
            fecha_compra: new Date(),
            selectedOptionProveedor: null,
            selectedOptionDocumento: null,
            selectedOptionUsuario: null,
        }

        if(this.props.isModal){ //Se verifica si estamos usando el componente compra como Modal (el caso de actualizacion)

            let selectedRow = this.props.selectedRow;
            this.state = {
                ...this.state,
                numero_documento        : selectedRow.numero_documento,
                cantidad_compra         : selectedRow.cantidad_compra,
                porcentaje_merma        : selectedRow.porcentaje_merma,
                peso_compra             : selectedRow.peso_compra,
                salidaAnterior          : selectedRow.cantidad_compra - selectedRow.saldo,
                textBtn                 : 'Actualizar Compra',
                preciounitario_compra   : this.props.selectedRow.preciounitario_compra,
                fecha_compra            : new Date(this.props.selectedRow.fecha_compra+'T00:00'),
                selectedOptionProveedor : {value: selectedRow.idproveedor, label: selectedRow.proveedore.proveedor_proveedores},
                selectedOptionDocumento : {value: selectedRow.idtipodocumento, label: selectedRow.tipos_documento.tipo_documento},
                selectedOptionUsuario   : {value: selectedRow.idusuario, label: selectedRow.usuario.nombre_usuario + ' ' + selectedRow.usuario.apellido_usuario} 
            }
        }
    }

    componentDidMount(){

        AxiosInstance({
            method : 'get',
            url : '/api/datosSelect'
        })
        .then(datos => {
            
            let opcionesProveedor =  [];
            let opcionesDocumento = [];
            let opcionesUsuario = [];

                datos.data.proveedores.forEach((prov, i) => {
                    opcionesProveedor.push({
                        value: prov.idproveedor,
                        label: prov.proveedor_proveedores
                    })
                });

                datos.data.documentos.forEach((doc, i) => {
                    opcionesDocumento.push({
                        value: doc.idtipodocumento,
                        label: doc.tipo_documento
                    })
                });

                datos.data.usuarios.forEach((user, i) => {
                    opcionesUsuario.push({
                        value: user.idusuario,
                        label: user.nombre_usuario +" "+ user.apellido_usuario
                    })
                });

                this.setState({
                    proveedores : opcionesProveedor,
                    documentos: opcionesDocumento,
                    usuarios: opcionesUsuario
                })
            }
        ).catch(err => {
            console.log(err);
        })
    }

    onInputChange(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleChange = (name, selectedOption) => {
        this.setState({
            [name] : selectedOption
        })
    };

    
    handleChangeDate = (fecha_compra) => {
        this.setState({
            fecha_compra
        })
    };

    saveCompra(e){
        e.preventDefault();

    
        if(this.state.selectedOptionProveedor === null || this.state.selectedOptionDocumento===null || this.state.selectedOptionUsuario === null || this.numero_documento === '' || this.state.fecha_compra === null || this.state.cantidad_compra===0 || this.state.peso_compra===0 || this.state.preciounitario_compra ===0){
            MySwal.fire({
                title: 'Debe completar los campos',
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
              }).then(result => {
                   return;
              })
        }else{
                
                let newDate = new Date(this.state.fecha_compra); //Para convertir la fecha string a un objeto Fecha
                
                let data = {
                    idproveedor: this.state.selectedOptionProveedor.value,
                    idtipodocumento: this.state.selectedOptionDocumento.value,
                    idusuario: this.state.selectedOptionUsuario.value, //Cambiar en caso sea el usuario actualmente logueado
                    fecha_compra: formatearFecha(newDate, "english"), //newDate.getFullYear()+'-'+(newDate.getMonth() < 10? '0'+ (newDate.getMonth()+1): (newDate.getMonth()+1)) + '-'+ (newDate.getDate()< 10? '0'+ newDate.getDate(): newDate.getDate()),
                    numero_documento: this.state.numero_documento,
                    cantidad_compra: this.state.cantidad_compra,
                    peso_compra: this.state.peso_compra,
                    preciounitario_compra: this.state.preciounitario_compra,
                    porcentaje_merma : this.state.porcentaje_merma,
                    salidaAnterior : this.state.salidaAnterior
                } 
                
                MySwal.fire({
                    title: 'Espere',
                    html: 'Guardando...',
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                      Swal.showLoading()
                    }
                })
                
                let UrlApi = '/api/saveCompra'
                let method = 'post'

                if(this.state.IsModal){
                    UrlApi = '/api/updateCompra/'+this.props.selectedRow.idcompra;
                    method = 'put';
                }

                AxiosInstance({
                    method: method,
                    url : UrlApi,
                    data: data
                })
                .then(savedCompra => {
                    MySwal.close();
                    MySwal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'Guardado exitosamente',
                            showConfirmButton: false,
                            timer: 900
                        }).then(result => {
                            if(!this.state.IsModal){

                                let montoSinMerma = Number(savedCompra.data.precio_compra);
                                let diferenciaPeso = Number(savedCompra.data.peso_compra)*(Number(savedCompra.data.porcentaje_merma)/100);
                                let diferenciaPrecioCompra = diferenciaPeso * Number(savedCompra.data.preciounitario_compra);

                                let montoConMerma = (montoSinMerma - diferenciaPrecioCompra).toFixed(2);
                                let pesoConMerma = (Number(savedCompra.data.peso_compra) - diferenciaPeso).toFixed(2);

                                let montoSinMermaConFormato = this.state.instanciaFormatter.format(montoSinMerma);
                                let montoConMermaConFormato = this.state.instanciaFormatter.format(montoConMerma);
                                let pesonConMermaConFormato = this.state.instanciaFormatter.format(pesoConMerma);
                                let saldoConFormato = this.state.instanciaFormatter.format(savedCompra.data.saldo);

                                let ticket = <TicketComponent 
                                numero={savedCompra.data.idcompra} 
                                montoSinMerma={montoSinMermaConFormato}
                                montoConMerma={montoConMermaConFormato}
                                pesoConMerma ={pesonConMermaConFormato} 
                                saldo={saldoConFormato}>

                                </TicketComponent>
                                ReactDOM.render(ticket, document.getElementById('ticket'))
                            }else{
                                this.props.getAllComprasFromAPI();
                                this.props.closeModal();
                            }
                        })
                })
                .catch(e => {
                    console.log(e)
                })

                this.setState({
                    idproveedor     : null,
                    idtipodocumento : null,
                    idusuario       : null,
                    numero_documento: '',
                    cantidad_compra : 0,
                    peso_compra     : 0,
                    preciounitario_compra   : 0,
                    fecha_compra            : new Date(),
                    selectedOptionProveedor : null,
                    selectedOptionDocumento : null,
                    selectedOptionUsuario   : null,
                })
            }
    }

    render(){

        let element = <p></p>; 
        if(!this.state.IsModal){  //Con esta condicion evitamos que aparezca el link a indice del Componente compra porque ya esta en el indice
           element  = (<div className="row container" id="btnIndice" >
                        <Link to="/indicecompras" className="btn btn-success"><i className="fas fa-list-ol"></i>  Listado de compras</Link>
                        </div>)
        }

        let montoMermado  = 0;
        let montoSinMerma = 0;
        let montoConMerma = 0;
        let pesoConMerma  = 0;
        let errorSaldo = null;

      if(this.state.IsModal){
            montoMermado  = ( Number(this.state.preciounitario_compra) * Number(this.state.peso_compra) * Number(this.state.porcentaje_merma/100));
            montoSinMerma = ( Number(this.state.preciounitario_compra) * Number(this.state.peso_compra));
            montoConMerma = montoSinMerma - montoMermado;
            pesoConMerma = Number(this.state.peso_compra) - (Number(this.state.porcentaje_merma / 100) * Number(this.state.peso_compra));
            
            errorSaldo = this.state.cantidad_compra < this.state.salidaAnterior ? "De esta compra ya hay una salida superior a la cantidad que está ingresando" : null
      }
       

       //PRIMER FRAME DE INGRESO DE INFORMACIÓN DE COMPRA 
       return (
                <div className="container">
                        <br></br>
                        <div className="card">
                            <h5 className="card-header bg-default"><i className="fas fa-pencil-alt"></i> INGRESO DE COMPRA</h5>
                            <div className="form card-body" onSubmit={this.saveCompra}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Proveedor</label>
                                    <Select 
                                        value={this.state.selectedOptionProveedor}
                                        options={this.state.proveedores}
                                        onChange={this.handleChange.bind(this,'selectedOptionProveedor')}
                                    >
                                    </Select>
                                </div>
                                <div className="col-md-2">
                                    <label>Tipo Documento</label>
                                    <Select 
                                        value={this.state.selectedOptionDocumento}
                                        options={this.state.documentos}
                                        onChange={this.handleChange.bind(this,'selectedOptionDocumento')}
                                    >
                                    </Select>
                                </div>
                                <div className="col-md-2">
                                    <label># Documento</label>
                                    <input type="text" name="numero_documento" className="form-control" value={this.state.numero_documento} onChange={this.onInputChange}/>
                                </div>
                                <div className="col-md-2">
                                <label>Fecha de Compra</label>
                                <DatePicker
                                    className ="form-control"
                                    selected={this.state.fecha_compra}
                                    onChange={this.handleChangeDate.bind(this)}
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    />
                                </div>
                                <div className="col-md-3">
                                <label>Usuario Empleado</label>
                                    <Select 
                                        value={this.state.selectedOptionUsuario}
                                        options={this.state.usuarios}
                                        onChange={this.handleChange.bind(this,'selectedOptionUsuario')}
                                        name="idusuario"
                                    >
                                    </Select>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Cantidad de aves</label>
                                    <input type="number" name="cantidad_compra" className="form-control" value={this.state.cantidad_compra} onChange={this.onInputChange}/>
                                </div>
                                <div className="col-md-2">
                                    <label>Peso Aves</label>
                                    <input type="number" name="peso_compra" className="form-control" value={this.state.peso_compra} onChange={this.onInputChange}/>
                                </div>
                                <div className="col-md-2">
                                    <label>Precio</label>
                                    <input type="number" name="preciounitario_compra" className="form-control" value={this.state.preciounitario_compra} onChange={this.onInputChange}/>
                                </div>
                                <div className="col-md-2">
                                    <label>% Merma</label>
                                    <input type="number" name="porcentaje_merma" className="form-control" value={this.state.porcentaje_merma} onChange={this.onInputChange}/>
                                </div>
                                <div className="col-md-3">
                                    <br></br>
                                    <button className="btn btn-success" disabled={errorSaldo !== null} onClick={this.saveCompra}><i className="fa fa-save"></i> {this.state.textBtn}</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        {errorSaldo !== null && <div class="alert alert-danger" role="alert">{errorSaldo}</div>}
                        { this.state.IsModal && <div className="row">
                            <div className="col-md-2">
                                <p><label>Monto Sin Merma: </label><input className="form-control" disabled value={this.state.instanciaFormatter.format(montoSinMerma)}/></p>
                            </div>
                             <div className="col-md-2">
                                <p><label>Monto Con Merma: </label><input className="form-control" disabled value={this.state.instanciaFormatter.format(montoConMerma)}/></p>
                            </div>
                             <div className="col-md-2">
                                <p><label>Peso Con Merma: </label><input className="form-control" disabled value={this.state.instanciaFormatter.format(pesoConMerma)}/></p>
                            </div>
                            <div className="col-md-2">
                                <p><label>Salida de Aves: </label><input className="form-control" disabled value={this.state.instanciaFormatter.format(this.state.salidaAnterior)}/></p>
                            </div>
                            <div className="col-md-2">
                                <p><label>Saldo Actual: </label><input className="form-control" disabled value={this.state.instanciaFormatter.format(this.state.cantidad_compra - this.state.salidaAnterior)}/></p>
                            </div>
                             <div className="col-md-2">
                                <br/>
                                <button onClick={this.props.closeModal} className="btn btn-danger">Cancelar</button>
                             </div>
                        </div> }
                        <div id="ticket">

                        </div>
                        {element}
                </div>
             )
             
    }
}


const mapStateToProps = (state) => ({
    authenticated : state.authenticated,
    user : state.user,
    errorAuthentication : state.errorAuthentication
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type : 'LOGOUT'})
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Compra);
