import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Edit from '@material-ui/icons/Edit';
import tableIcons from '../TableIcons/tableIcons'
import AxiosInstance from '../helpers'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';
import { InputAdornment } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CallIcon from '@material-ui/icons/Call';
import { Modal } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import URI_BACKEND from '../confProyecto'
import userImage from '../img/user.png';
const SwalAlert = withReactContent(Swal);


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



class Usuarios extends Component {

    constructor(props) {
        super(props)

        this.refPhoto = React.createRef();
        this.state = {
            usuario: {
                idusuario: 0,
                idperfil: 0,
                login_usuario: '',
                pass: '',
                nombre_usuario: '',
                apellido_usuario: '',
                telefono_usuario: '',
                email_usuario: '',
                photo: null,
                url_photo: null,
                createdbygoogle: false,
                isactivated: false,
            },
            openError: false,
            openSave: false,
            perfiles: [],
            IsOpen: false,
            carga: false,
            usuarios: [],
            existe: false,
            defaultPerfil: 'Without',
            errorMessageRequired: null,
            headers: [
                { title: 'ID', field: 'idusuario', type: 'numeric', defaultSort: 'asc' },
                { title: 'LOGIN', field: 'login_usuario' },
                { title: 'NOMBRE USUARIO', field: 'nombre_usuario' },
                { title: 'APELLIDO USUARIO', field: 'apellido_usuario' },
                { title: 'TELEFONO', field: 'telefono_usuario' },
                { title: 'EMAIL', field: 'email_usuario' }
            ],
        }

        this.getPerfilesAndUsers = this.getPerfilesAndUsers.bind(this);
        this.getUsuarios = this.getUsuarios.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.save = this.save.bind(this);
    }




    componentDidMount() {
        this.getPerfilesAndUsers();
    }

    getPerfilesAndUsers() {
        AxiosInstance({
            method: 'get',
            url: '/api/allperfiles'
        }).then(res => {
            console.log(res.data)
            this.setState({
                perfiles: res.data
            })
            this.getUsuarios();
        })
    }

    getUsuarios() {
        AxiosInstance({
            method: 'get',
            url: '/api/getUsuarios'
        }).then(res => {
            this.setState({
                usuarios: res.data,
                carga: false
            })
        })
    }

    onInputChange = async (event) => {
        const { name, value, type, checked, files } = event.target;

        if (type === 'checkbox') {
            this.setState((prevState) => ({
                usuario: {
                    ...prevState.usuario,
                    [name]: checked
                }
            }));
        } else if (type === 'file') {
            if (name === 'url_photo') {
                try {
                    const response = await fetch(value);
                    const blob = await response.blob();
                    const photo = new File([blob], 'photo.jpg', { type: 'image/jpeg' });

                    this.setState((prevState) => ({
                        usuario: {
                            ...prevState.usuario,
                            photo
                        }
                    }));
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.setState((prevState) => ({
                    usuario: {
                        ...prevState.usuario,
                        [name]: files[0]
                    }
                }));
            }
        } else {
            this.setState((prevState) => ({
                usuario: {
                    ...prevState.usuario,
                    [name]: value
                }
            }));
        }
    };


    existeUsuario() {
        AxiosInstance({
            method: 'get',
            url: '/api/existeUsuario',
            params: {
                user: this.state.usuario.login_usuario
            }
        }).then(({ data }) => {
            if (data.existe) {
                this.setState({
                    existe: true
                })
            } else {
                this.setState({
                    existe: false
                })
            }
        }).catch(e => {
            console.log(e)
        })
    }

    async save(e) {

        const action = e.target.name !== 'update' ? 1 : 2;   // 1 = Para crear un usuario y 2 = Para actualizar

        if (this.state.usuario.login_usuario === '' || this.state.usuario.nombre_usuario === '' || this.state.usuario.apellido_usuario === '' || this.state.usuario.idperfil === 0) {
            this.setState({
                openError: true
            })
            return;
        }

        if (action === 1 && this.state.usuario.pass === '') {
            this.setState({
                openError: true
            })
            return;
        }

        try {

            let form = new FormData();

            form.append('idperfil', this.state.usuario.idperfil);
            form.append('login_usuario', this.state.usuario.login_usuario);
            form.append('pass', this.state.usuario.pass);
            form.append('nombre_usuario', this.state.usuario.nombre_usuario);
            form.append('apellido_usuario', this.state.usuario.apellido_usuario);
            form.append('telefono_usuario', this.state.usuario.telefono_usuario);
            form.append('email_usuario', this.state.usuario.email_usuario);
            form.append('action', action);
            form.append('idusuario', this.state.usuario.idusuario);
            form.append('url_photo', this.state.usuario.url_photo);
            form.append('isactivated', this.state.usuario.isactivated);

            console.log(this.state.usuario.photo);
            if (this.state.usuario.photo !== null && this.state.usuario.photo !== undefined) {
                form.append('photo', this.state.usuario.photo)
            }




            SwalAlert.fire({
                title: 'Espere',
                html: 'Guardando...',
                timerProgressBar: true,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            })

            let usuario = await AxiosInstance({
                method: 'post',
                url: '/api/createOrUpdateUser',
                data: form,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            SwalAlert.close();

            this.setState({
                openSave: true,
                usuario: {
                    idusuario: 0,
                    idperfil: 0,
                    login_usuario: '',
                    pass: '',
                    nombre_usuario: '',
                    apellido_usuario: '',
                    telefono_usuario: '',
                    email_usuario: '',
                    photo: null,
                    url_photo: null
                },
                IsOpen: false
            })
            document.querySelectorAll("[type='radio']").forEach(element => {
                element.checked = false;
            })

            this.getPerfilesAndUsers();
        } catch (e) {
            console.log(e);
        }
    }

    desplegarModal = (event, rowData) => {

        this.setState({
            usuario: {
                idusuario: rowData.idusuario,
                idperfil: rowData.idperfil,
                login_usuario: rowData.login_usuario,
                pass: '',
                nombre_usuario: rowData.nombre_usuario,
                apellido_usuario: rowData.apellido_usuario,
                telefono_usuario: rowData.telefono_usuario,
                email_usuario: rowData.email_usuario,
                photo: null,
                url_photo: rowData.url_photo,
                isactivated: rowData.isactivated,
                createdbygoogle: rowData.createdbygoogle,
            },
            IsOpen: true
        })

    }

    closeAndClean = () => {
        this.setState({
            usuario: {
                idusuario: 0,
                idperfil: 0,
                login_usuario: '',
                pass: '',
                nombre_usuario: '',
                apellido_usuario: '',
                telefono_usuario: '',
                email_usuario: '',
                photo: null,
                url_photo: null
            },
            IsOpen: false
        })
    }


    render() {

        const colorButton = this.state.usuario.photo !== null ? 'success' : 'info';
        const textButton = this.state.usuario.photo !== null ? 'Cargada' : (this.state.IsOpen ? 'Cambiar foto' : 'Subir foto');
        const cambio = this.state.usuario.photo !== null;

        const { url_photo, photo,createdbygoogle } = this.state.usuario;
        console.log(url_photo)
        // Construir la URL de la imagen basada en las condiciones
        let urlImagen;
        if (photo !== null) {
            urlImagen = window.URL.createObjectURL(photo); // Usar la imagen seleccionada por el usuario
        }else if (url_photo) {
            if (createdbygoogle) {
                // Si es creado por Google, usar la URL directamente
                urlImagen = url_photo;
            } else {
                // Si no es creado por Google, concatenar con URI_BACKEND
                urlImagen = URI_BACKEND + url_photo;
            }
        } else if(url_photo === null) {
            urlImagen = userImage; // Imagen por defecto
        }

        return (
            <div className="wrapper container">
                <Snackbar open={this.state.openError} autoHideDuration={3000} onClose={(e) => { this.setState({ openError: false }) }}>
                    <Alert onClose={(e) => { this.setState({ open: false }) }} severity="error">
                        Campos invalidos o incompletos
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openSave} autoHideDuration={3000} onClose={(e) => { this.setState({ openSave: false }) }}>
                    <Alert onClose={(e) => { this.setState({ openSave: false }) }} severity="success">
                        Guardado exitosamente
                    </Alert>
                </Snackbar>

                <Modal show={this.state.IsOpen}>
                    <Modal.Header>
                        <Modal.Title>Actualizar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-center">
                            <img src={urlImagen !== 'Foto no Encontrada' ? urlImagen : userImage} style={{ width: '20%', borderRadius: '50%' }} alt="photo"></img>
                        </div>
                        <br></br>
                        <div className="d-flex justify-content-center">
                            <button disabled = {this.state.usuario.createdbygoogle } className={`btn btn-${colorButton}`} onClick={() => this.refPhoto.current.click()}>{textButton}</button>
                        </div>
                        <br></br>
                        <div className="d-flex justify-content-center">
                            {!this.state.usuario.createdbygoogle ?
                                <label className="px-3">Creado por el Sistema</label> :
                                <span className="px-3">Registrado por Google</span>
                            }
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-md-12">
                                <table>
                                    <tbody>

                                        <tr>
                                            <td> <label htmlFor="login_usuario">Usuario:</label></td>
                                            <td> <input type="text" name="login_usuario" value={this.state.usuario.login_usuario} disabled className="form-control" /></td>
                                        </tr>
                                        <tr>
                                            <td> <label htmlFor="pass">Contraseña:</label></td>
                                            <td> <input type="text" name="pass" value={this.state.usuario.pass} onChange={this.onInputChange} className="form-control" /></td>
                                        </tr>
                                        <tr>
                                            <td> <label htmlFor="email_usuario">Email:</label></td>
                                            <td> <input type="text" name="email_usuario" value={this.state.usuario.email_usuario} onChange={this.onInputChange} className="form-control" /></td>
                                        </tr>
                                        <tr>
                                            <td> <label htmlFor="nombre_usuario">Nombre:</label></td>
                                            <td> <input type="text" name="nombre_usuario" value={this.state.usuario.nombre_usuario} onChange={this.onInputChange} className="form-control" /></td>
                                        </tr>
                                        <tr>
                                            <td> <label htmlFor="apellido_usuario">Apellido:</label></td>
                                            <td>   <input type="text" name="apellido_usuario" value={this.state.usuario.apellido_usuario} onChange={this.onInputChange} className="form-control" /></td>
                                        </tr>
                                        <tr>
                                            <td> <label htmlFor="telefono_usuario">Teléfono: </label></td>
                                            <td>  <input type="number" name="telefono_usuario" value={this.state.usuario.telefono_usuario} onChange={this.onInputChange} className="form-control" /></td>
                                        </tr>

                                        <tr>
                                            <td> <label htmlFor="idperfil">Perfil: </label></td>
                                            <td>
                                                <select name="idperfil" value={this.state.usuario.idperfil} className="form-control" onChange={this.onInputChange}>
                                                    {
                                                        this.state.perfiles.map(per =>
                                                            <option key={`p${per.idperfil}`} value={per.idperfil}>{per.perfil}</option>)
                                                    }
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <label htmlFor="activacion">Activar: </label></td>
                                            <td>    <input
                                                type="checkbox"
                                                name="isactivated"
                                                checked={this.state.usuario.isactivated}
                                                onChange={this.onInputChange}
                                                style={{ transform: 'scale(2)', marginLeft: '90px' }}
                                            /></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button name="update" className="btn btn-info" onClick={this.save}>
                            Guardar
                        </button>
                        <button className="btn btn-danger" onClick={this.closeAndClean}>
                            Cancelar
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <h6 className="card-header bg-info">CREAR USUARIO</h6>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="row justify-content-center">
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-center" style={{ paddingTop: 5 }}>
                                                    <TextField id="login_usuario" size="small" error={this.state.existe} variant="outlined" name="login_usuario" label="Login Usuario" value={this.state.usuario.login_usuario} helperText={this.state.existe ? "Ya existe el usuario" : null} onChange={this.onInputChange} onBlur={this.existeUsuario.bind(this)} InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <SupervisorAccountIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}></TextField>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-center" style={{ paddingTop: 5 }}>
                                                    <TextField id="pass" size="small" variant="outlined" name="pass" label="Password" value={this.state.usuario.pass} onChange={this.onInputChange} InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <VisibilityOffIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}></TextField>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-center" style={{ paddingTop: 5 }}>
                                                    <TextField id="email_usuario" size="small" variant="outlined" name="email_usuario" label="Email Usuario" value={this.state.usuario.email_usuario} onChange={this.onInputChange} InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <AlternateEmailIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}></TextField>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row justify-content-center">
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-center" style={{ paddingTop: 5 }}>
                                                    <TextField id="nombre_usuario" size="small" variant="outlined" name="nombre_usuario" label="Nombre Usuario" value={this.state.usuario.nombre_usuario} onChange={this.onInputChange} InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <AssignmentTurnedInIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}></TextField>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-center" style={{ paddingTop: 5 }}>
                                                    <TextField id="apellido_usuario" size="small" variant="outlined" name="apellido_usuario" label="Apellido Usuario" value={this.state.usuario.apellido_usuario} onChange={this.onInputChange} InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <AssignmentTurnedInIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}></TextField>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-center" style={{ paddingTop: 5 }}>
                                                    <TextField id="telefono_usuario" size="small" type="number" variant="outlined" name="telefono_usuario" label="Telefono Usuario" value={this.state.usuario.telefono_usuario} onChange={this.onInputChange} InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <CallIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}></TextField>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                    </div>
                                    <div className="col-md-2">
                                        <Form>
                                            {
                                                this.state.perfiles.map(per => <Form.Check custom key={per.idperfil} type="radio" value={per.idperfil} id={per.idperfil} name="idperfil" label={per.perfil} onChange={this.onInputChange} />)
                                            }
                                        </Form>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-md-3">
                                        <button className={`btn btn-${colorButton} btn-lg btn-block`} onClick={() => this.refPhoto.current.click()}><i className="fa fa-upload"></i> {textButton}</button>
                                        <input type="file" accept="image/*" name="photo" ref={this.refPhoto} onChange={this.onInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.existe}
                                            size="large"
                                            onClick={this.save}
                                            startIcon={<SaveIcon />}
                                        >
                                            Guardar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <h6 className="card-header bg-info">USUARIOS</h6>
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <MaterialTable
                                            isLoading={this.state.carga}
                                            icons={tableIcons}
                                            title='Datos'
                                            columns={this.state.headers}
                                            data={this.state.usuarios}
                                            options={{
                                                exportButton: true,
                                                headerStyle: { fontSize: 10, padding: 5, zIndex: 0 },
                                                cellStyle: { fontSize: 10, padding: 5 },
                                                actionsColumnIndex: -1
                                            }}
                                            localization={
                                                {
                                                    body: {
                                                        emptyDataSourceMessage: 'Sin datos'
                                                    },
                                                    toolbar: {
                                                        searchTooltip: 'Buscar...',
                                                        searchPlaceholder: 'Buscar...',
                                                        exportName: 'Exportar como CSV'
                                                    },
                                                    pagination: {
                                                        labelRowsSelect: 'Filas',
                                                        labelDisplayedRows: ' {from}-{to} de {count}',
                                                        firstTooltip: 'Primera Página',
                                                        previousTooltip: 'Página Anterior',
                                                        nextTooltip: 'Siguiente Página',
                                                        lastTooltip: 'Última Página'
                                                    },
                                                    header: {
                                                        actions: 'OPCIONES'
                                                    },
                                                }
                                            }
                                            actions={[
                                                {
                                                    icon: () => <Edit />,
                                                    tooltip: 'Editar',
                                                    onClick: this.desplegarModal
                                                }
                                            ]}
                                        >
                                        </MaterialTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    logout() {
        sessionStorage.clear();
        dispatch({ type: 'LOGOUT' });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
