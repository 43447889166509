import {createStore} from 'redux'


const InitialState = {
    authenticated : false,
    user : null,
    clicked : false,
    errorAuthentication : null,
    messageAuthentication : null,
    idperfil : 0,
    accesos : [],
    routesForComponent : []
}


const MainReducer = (state = InitialState, action) =>{
    switch(action.type){
        case 'SETCLICK': return {...state, clicked : !state.clicked}
        case 'LOGIN' :  return {...state, authenticated : true, user : action.user, idperfil: action.idperfil, clicked : true, accesos : [...action.accesos], routesForComponent : [...action.routesForComponent]}
        case 'LOGOUT' :  return {...state, authenticated: false, errorAuthentication : null, clicked : false}
        case 'ERROR_AUTHENTICATION' : return {...state, errorAuthentication : action.message}
        default :
            let token = sessionStorage.getItem('token');
            let user = sessionStorage.getItem('user');
            let idperfil = sessionStorage.getItem('idperfil');
            let accesos = JSON.parse(sessionStorage.getItem('accesos')) || [];
            let routesForComponent = JSON.parse(sessionStorage.getItem('routesForComponent')) || [];
            let existToken = token !== null && token !== undefined && token !== ''
            return {...state, authenticated: existToken, user, accesos, idperfil ,routesForComponent};
    }
}

const store = createStore(MainReducer);

export default store;

