import React, {useState, useEffect, useRef} from 'react';
import AxiosInstance from '../helpers'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';


const SwalAlert = withReactContent(Swal);


const Cliente = (props) => {

    const [clienteN, setCliente] = useState({
        id: '',
        cliente : '',
        razon_social_cliente : '',
        nit_cliente: '',
        direccion_cliente : '',
        telefono_cliente : '',
        iddepartamento : '0'
    });

    const {
        id,
        cliente,
        razon_social_cliente,
        nit_cliente,
        direccion_cliente,
        telefono_cliente,
        iddepartamento
    } = clienteN;

    const [departamentos, setDepartamentos] = useState([]); //Viene cada uno con sus respectivos municipios
    const [clientes, setClientes] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [IsOpen, setOpen] = useState(false);
    const [valueMunicipio, setValueMunicipio] = useState('0');
   


    useEffect(()=>{

        AxiosInstance({
            method : 'get',
            url: '/api/getDeptos'
        }).then(res =>{
            setDepartamentos(res.data);

            return AxiosInstance({
                method : 'get',
                url: '/api/getclients'
            })

        }).then(res => {
            
            setClientes(res.data.map(cliente=>({...cliente, municipioN:cliente.municipio?.nombre})));
        })



    }, []);

   
    const setMuni = (e)=>{
        setValueMunicipio(e.target.value);
    }

    const setValues = (e)=>{

           const name = e.target.name;
           const value = e.target.value;
           

        setCliente(cliente => ({
                ...cliente,
                [name] : value 
        }))

        if( name==="iddepartamento"){
            if (value === "0"){
                setMunicipios([]);
                setValueMunicipio('0');
            }else{
                let municipioOptions = departamentos.find(depto => depto.iddepartamento === Number(value))?.municipios;
                setMunicipios(municipioOptions);
                setValueMunicipio(municipioOptions[0]?.idmunicipio || '0');
            }
        }
        
    }

    const saveClient = async (e)=>{

        const buttonName = e.target.name;
        const httpMethod =  buttonName === "save" ? "post" : "put";
        const urlApi = buttonName === "save" ? "/api/saveclient" : "/api/updateclient";

        if(cliente === '' || razon_social_cliente === '' || nit_cliente==='' ||direccion_cliente==='' || valueMunicipio === '0'){
            SwalAlert.fire({
                title: 'Faltan datos',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
                }).then(result => {
                    return;
                });
            return;
        }
        
      try{

        SwalAlert.fire({
            title: 'Espere',
            html: 'Guardando...',
            timerProgressBar: true,
            onBeforeOpen: () => {
              Swal.showLoading()
            }
        })

        let created =  await AxiosInstance({
            method : httpMethod,
            url : urlApi,
            data : {
                ...clienteN,
                idmunicipio: valueMunicipio
            }

        });

        let res = await AxiosInstance({
            method : 'get',
            url: '/api/getclients'
        })
        setClientes(res.data.map(cliente=>({...cliente, municipioN:cliente.municipio?.nombre})));

        SwalAlert.close();
        SwalAlert.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Guardado exitosamente',
                showConfirmButton: false,
                timer: 900
        }).then(result => {
            
             closeModal();
            return;
        })


      } catch(e){
          console.log(e);
            SwalAlert.close();
            SwalAlert.fire({
                position: 'top-center',
                icon: 'error',
                title: 'Error de servidor',
                showConfirmButton: false,
                timer: 900
            }).then(result => {
                closeModal();
                return;
            })
      } 
    }

    
    const editFormatter = (cell, row)=>{
        return <button onClick={()=>updateClient(row)} className="btn btn-info"><i className="fa fa-edit"></i></button>
    }

    const deleteFormatter = (cell, row)=>{
        return <button onClick={()=>deleteClient(row)} name="delete" className="btn btn-danger"><i className="fa fa-cut"></i></button>
    }

    const updateClient = (row)=>{

        setCliente({
            id : row.idcliente,
            cliente : row.cliente,
            razon_social_cliente : row.razon_social_cliente,
            nit_cliente : row.nit_cliente,
            direccion_cliente : row.direccion_cliente,
            telefono_cliente : row.telefono_cliente || '',
            iddepartamento : row.municipio?.iddepartamento || '0'
        });
        
        if(row.municipio){
            let municipioOptions = departamentos.find(depto => depto.iddepartamento === Number(row.municipio.iddepartamento))?.municipios;
            setMunicipios(municipioOptions);
            setValueMunicipio(row.idmunicipio)
        }else{
            setMunicipios([]);
        }
        
        setOpen(true);
    }

    const deleteClient = (row)=>{
        SwalAlert.fire({
            title: `¿Seguro que desea eliminar a ${row.cliente}?`,
            text: "Debes pensarlo despacio",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí eliminar',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {

            if (result.value) {
                
                try{
                    let deleted =  await AxiosInstance({
                        method : 'post',
                        url : '/api/deleteclient',
                        data : {
                            id : row.idcliente
                        }
                    });
            
                    let res = await AxiosInstance({
                        method : 'get',
                        url: '/api/getclients'
                    })

                    SwalAlert.fire(
                        'Eliminado!',
                        'Eliminado correctamente',
                        'success'
                    )
                    setClientes(res.data);
                  } catch(e){
                     console.log(e);
                  } 
            }
          })
    }

    const closeModal = ()=>{
        setCliente({
            id: '',
            cliente : '',
            razon_social_cliente : '',
            nit_cliente: '',
            direccion_cliente : '',
            telefono_cliente : '',
            iddepartamento : '0'
        });
        setMunicipios([]);
        setOpen(false);
    }


    const sizeTextBody = 11
 
   return (
       <div className="container">
                    <Modal show={IsOpen}>
                            <Modal.Header>
                            <Modal.Title>Actualizar</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-10">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td> <label htmlFor="cliente">Nombre Cliente:</label></td>
                                                    <td> <input type="text" name="cliente" value={cliente} onChange={setValues} className="form-control"/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="razon_social_cliente">Razón Social Cliente:</label></td>
                                                    <td> <input type="text" name="razon_social_cliente" value={razon_social_cliente} onChange={setValues} className="form-control"/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="nit_cliente">Nit:</label></td>
                                                    <td> <input type="text" name="nit_cliente" value={nit_cliente} onChange={setValues} className="form-control"/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="direccion_cliente">Dirección:</label></td>
                                                    <td>   <input type="text" name="direccion_cliente" value={direccion_cliente} onChange={setValues} className="form-control"/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="telefono_cliente">Teléfono: </label></td>
                                                    <td>  <input type="number" name="telefono_cliente" value={telefono_cliente} onChange={setValues} className="form-control"/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="telefono_cliente">Departamento: </label></td>
                                                    <td>  
                                                        <select name="iddepartamento" value={iddepartamento} className="form-control" onChange={setValues}>
                                                            <option value="0">Sin datos</option>
                                                            {
                                                            departamentos.length > 0 ? departamentos.map(depto => 
                                                                    <option key={`d${depto.iddepartamento}`} value={depto.iddepartamento}>{depto.nombre}</option>) : <option>Sin datos</option>
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><label htmlFor="idmunicipio">Municipio: </label></td>
                                                    <td>
                                                    <select name="idmunicipio" value={valueMunicipio}  className="form-control" onChange={setMuni} >
                                                            {
                                                                municipios.length > 0 ? municipios.map(muni => <option key={`m${muni.idmunicipio}`} value={muni.idmunicipio}>{muni.nombre}</option>) 
                                                                : <option>Sin datos</option>
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                       
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="contained" name="update" color="primary" onClick={saveClient}>
                                    Guardar
                                </Button>
                                <Button variant="contained" color="secondary" onClick={closeModal}>
                                    Cancelar
                                </Button>
                            </Modal.Footer>
                    </Modal>
           <br></br>
           <div className="card row">
                <div className="card-header">
                    <h6>Cliente</h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="cliente">Nombre Cliente:</label>
                            <input type="text" name="cliente" value={cliente} onChange={setValues} className="form-control"/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="razon_social_cliente">Razón Social Cliente:</label>
                            <input type="text" name="razon_social_cliente" value={razon_social_cliente} onChange={setValues} className="form-control"/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="nit_cliente">Nit:</label>
                            <input type="text" name="nit_cliente" value={nit_cliente} onChange={setValues} className="form-control"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="direccion_cliente">Dirección:</label>
                            <input type="text" name="direccion_cliente" value={direccion_cliente} onChange={setValues} className="form-control"/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="telefono_cliente">Teléfono: </label>
                            <input type="number" name="telefono_cliente" value={telefono_cliente} onChange={setValues} className="form-control"/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="iddepartamento">Departamento: </label>
                            <select name="iddepartamento" value={iddepartamento} className="form-control" onChange={setValues}>
                                <option value="0">Sin datos</option>
                                {
                                  departamentos.length > 0 ? departamentos.map(depto => 
                                        <option key={`d${depto.iddepartamento}`} value={depto.iddepartamento}>{depto.nombre}</option>) : <option>Sin datos</option>
                                }
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="idmunicipio">Municipio: </label>
                            <select name="idmunicipio" value={valueMunicipio}  className="form-control" onChange={setMuni}>
                                {
                                    municipios.length > 0 ? municipios.map(muni => <option key={`m${muni.idmunicipio}`} value={muni.idmunicipio}>{muni.nombre}</option>) 
                                    : <option>Sin datos</option>
                                }
                            </select>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                         <div className="col-md-3">
                            <button className="btn btn-success" name="save" onClick={saveClient}>Guardar</button>
                         </div>
                    </div>
                </div>
           </div>
           <br></br>
           <div className="row">
                    <div className="col-md-12">
                    <div className="card">
                    <h6 className="card-header bg-default"><i className="fas fa-list-ol"></i> LISTADO DE CLIENTES</h6>
                        <div className="card-body">
                              <BootstrapTable data={clientes}  search={ true } searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                                <TableHeaderColumn isKey dataField='idcliente' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:60}} thStyle={{fontSize: sizeTextBody,width:60, backgroundColor: 'grey', color: 'white'}}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='cliente'         dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:150}} thStyle={{fontSize: sizeTextBody, width:150, backgroundColor: 'grey', color: 'white'}}>Cliente</TableHeaderColumn>
                                <TableHeaderColumn dataField='razon_social_cliente' dataSort={ true } tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Razón Social</TableHeaderColumn>
                                <TableHeaderColumn dataField='nit_cliente'       dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Nit</TableHeaderColumn>
                                <TableHeaderColumn dataField='direccion_cliente' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: sizeTextBody,  backgroundColor: 'grey', color: 'white'}}>Dirección</TableHeaderColumn>
                                <TableHeaderColumn dataField='telefono_cliente'  dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Teléfono</TableHeaderColumn>
                                <TableHeaderColumn dataField='municipioN'       dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Municipio</TableHeaderColumn>
                                <TableHeaderColumn dataField='idcliente'             dataFormat={ editFormatter}  tdStyle={{fontSize:sizeTextBody, width: '60px'}} thStyle={{fontSize: sizeTextBody, width: '60px', backgroundColor: 'grey', color: 'white'}}>Editar</TableHeaderColumn>
                                <TableHeaderColumn dataField='idcliente'             dataFormat={ deleteFormatter} tdStyle={{fontSize:sizeTextBody, width: '60px'}} thStyle={{fontSize: sizeTextBody, width: '60px', backgroundColor: 'grey', color: 'white'}}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                         </div>
                    </div>
                    </div>
                  </div>
       </div>
   )    
}

export default Cliente;