import React, {Component} from 'react'
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {formatearFecha} from '../helpers/helperFuncs'
import AxiosInstance from '../helpers'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';

registerLocale('es', es)
const MySwal = withReactContent(Swal)

const NumberFormat = new Intl.NumberFormat('en-US');

class Mortalidad extends Component{
    
    constructor(props){
        super(props)

        let fechadesde = new Date();
        let fechafin = new Date();
        fechafin.setDate(fechadesde.getDate()+7);

        this.refFile = React.createRef();


        this.state = {
            selectedCliente : null,
            cantidadMortalidad : 0,
            montoMortalidad : 0,
            fechadesde,
            fechafin,
            precioMortalidad : 0,
            promedioCompraMortalidad : 0, 
            modalIsOpen : false,
            descuentoPorMortalidad : 0,
            totalAPagar : 0,                //Resta del precio original y el descuento por mortalidad
            montoFacturadoOriginal : 0,
            clientes : [],
            abonados : {abonado : 0},
            vendidoTotal : {vendidototal : 0},
            file : null,
            srcFileModal : null,
            consulta : false,
            observaciones : '',
            disabled : false, 
            consultaCompra : {
                cantidad_tickets : 0,
                cantidad_compra : 0,
                peso_compra : 0,
                peso_merma : 0,
                precio_compra: 0,
                promedio_precio : 0,
                promedio_compra : 0
            },
            consultaVenta : {
                tickets : 0,
                cantidad : 0,
                peso : 0,
                monto : 0,
                promedio_precio : 0
            }
        }
    }

    async componentDidMount(){

      try{

        let clientes = await AxiosInstance({
            method : 'get',
            url : '/api/getclients'
        })

        let mapClientes = clientes.data.map(cliente => ({value : cliente.idcliente, label: cliente.cliente}))

        this.setState({
            clientes : mapClientes
        })

      }catch(e){

      }
    }


    handleTextArea = (e)=>{
        this.setState({
            observaciones : e.target.value
        }, ()=>{
            console.log(this.state.observaciones)
        })
    }

    openModal = ()=>{
        this.setState({
            modalIsOpen : true
        })
    }

    closeModal = ()=>{
        this.setState({
            modalIsOpen : false
        })
    }

    handleCliente = (selectedCliente)=> {
        this.setState({
            selectedCliente
        })
    }

    handleFechaDesde = (fechadesde)=>{
        this.setState({
            fechadesde
        })
    }

    handleFechaFin = (fechafin)=>{
        this.setState({
            fechafin
        })
    }

    handleCantidadMortalidad = (e)=> {

        let cantidad = e.target.value;

        this.setState(prevState => ({
            cantidadMortalidad : cantidad,
            promedioCompraMortalidad : (cantidad * prevState.consultaCompra.promedio_compra).toFixed(2),
        }))
    }

    handlePrecioMortalidad = (e)=> {

        let precio = e.target.value;

        this.setState(prevState => ({
            precioMortalidad : precio,
            descuentoPorMortalidad : Number(prevState.promedioCompraMortalidad * precio).toFixed(2),
            totalAPagar : (Number(prevState.consultaVenta.monto) - Number(prevState.promedioCompraMortalidad * precio)).toFixed(2)// - Number(this.state.abonados.abonado)).toFixed(2)
        })) 
    }


    saveMortalidad = () => {

        const {cantidadMortalidad, precioMortalidad } = this.state;

       
          
        if(Number(cantidadMortalidad) === 0 || Number(precioMortalidad) === 0){
            MySwal.fire({
                title: 'Faltan datos',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 1500
                }).then(result => {
                    return;
                });
                return;
        }
      
        if(Number(this.state.consultaVenta.cantidad) < this.state.cantidadMortalidad){

            MySwal.fire({
                title: 'Excede cantidad vendida',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 1500
                }).then(result => {
                    return;
                });
                return;
        }

           let form = new FormData();

           form.append('idcliente', this.state.selectedCliente.value);
           form.append('fecha_inicio_depende', formatearFecha(this.state.fechadesde, "english"));
           form.append('fecha_final_depende', formatearFecha(this.state.fechafin, "english"));
           form.append('cantidad_mortalidad', Number(this.state.cantidadMortalidad));
           form.append('precio_mortalidad', Number(this.state.precioMortalidad))
           form.append('monto_mortalidad', Number(this.state.descuentoPorMortalidad));
           form.append('observaciones', this.state.observaciones)
           form.append('user', this.props.user)
           if(this.state.file){
               form.append('filemortalidad', this.state.file)
           }

                MySwal.fire({
                    title: 'Espere',
                    html: 'Guardando...',
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    }
                })

           AxiosInstance({
               method : 'post',
               url : '/api/saveMortalidad',
               headers : {
                   'Content-Type' : 'multipart/form-data'
               },
               data : form
           }).then(res => {

                MySwal.close();

                if(res.data.Message){
                    MySwal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: res.data.Message,
                        showConfirmButton: false,
                        timer: 1000
                    }).then(res => {
                        this.limpiar();
                    })
                }

                MySwal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Guardado exitosamente',
                    showConfirmButton: false,
                    timer: 1000
                }).then(res =>{
                    this.limpiar();
                })

           }).catch(e => {

                MySwal.fire({
                    position: 'top-center',
                    icon: 'error',
                    title: 'Hubo un error',
                    showConfirmButton: false,
                    timer: 1000
                }).then(res => {
                    this.limpiar();
                })

           })
      
    }

    consultar = async () => {

        if(this.state.selectedCliente === null){
            MySwal.fire({
                title: 'Seleccione un cliente',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
                }).then(result => {
                    return;
                });

                return;
        }

        if (this.state.fechafin < this.state.fechadesde){
            MySwal.fire({
                title: 'Invalidez de fechas',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
                }).then(result => {
                    return;
                });

                return;
        }

        this.setState({
            consulta : true
        })

        let fechadesde = formatearFecha(this.state.fechadesde, "english");
        let fechafin = formatearFecha(this.state.fechafin, "english");


        let res = await AxiosInstance({
            method : 'get',
            url : '/api/consultaMortalidad',
            params : {
                fechadesde,
                fechafin,
                idcliente : this.state.selectedCliente.value
            }
        })


        this.setState({
            consultaCompra : res.data.consultaCompra,
            consultaVenta : res.data.consultaVenta,
            abonados : res.data.abonado,
            vendidoTotal : res.data.vendidoTotal,
            disabled : true
        })
    }


    handleFile = (e)=>{
        this.setState({
            file : e.target.files[0]
        }, ()=>{
            if(this.state.file !== null){
                this.setState({
                    srcFileModal : URL.createObjectURL(this.state.file)
                })
            }
        })
    }

    limpiar = () => {

        this.setState({
            selectedCliente : null,
            cantidadMortalidad : 0,
            montoMortalidad : 0,
            precioMortalidad : 0,
            promedioCompraMortalidad : 0,
            disabled : false, 
            descuentoPorMortalidad : 0,
            totalAPagar : 0,
            file : null,
            observaciones : '',
            modalIsOpen : false,             
            montoFacturadoOriginal : 0,
            consulta : false,
            consultaCompra : {
                cantidad_tickets : 0,
                cantidad_compra : 0,
                peso_compra : 0,
                precio_compra: 0,
                promedio_precio : 0,
                promedio_compra : 0
            },
            consultaVenta : {
                tickets : 0,
                cantidad : 0,
                peso : 0,
                monto : 0,
                promedio_precio : 0
            }
        })
    }
    
    render(){

       
        let datosCompra = Object.keys(this.state.consultaCompra);
        let datosVenta = Object.keys(this.state.consultaVenta);

        const sizeLabelText = 12

        return (
        <div className="container">
                <Modal size="lg" show={this.state.modalIsOpen} onHide={this.afterOpenModal} >
                        <Modal.Header closeButton>
                        <Modal.Title>Visualicación de archivo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <iframe src={this.state.srcFileModal} title="myfileframe" width="100%" height="500"></iframe>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="contained" color="secondary" onClick={this.closeModal}>
                            Cerrar
                        </Button>
                        </Modal.Footer>
                 </Modal>
            <br></br>
                <div className="row">
                    <div className="col-md-12">
                    <div className="card">
                    <h5 className="card-header bg-default"><i className="fas fa-book-dead"></i> Mortalidad por Semana</h5>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <label>Cliente: </label>
                                <Select
                                    value ={this.state.selectedCliente}
                                    defaultValue={this.state.selectedCliente}
                                    onChange={this.handleCliente}
                                    isDisabled ={this.state.disabled}
                                    options ={this.state.clientes}
                                >
                                </Select>
                            </div>
                            <div className="col-md-2">
                               <label>Fecha Inicial: </label>
                               <DatePicker
                                  className="form-control"
                                  disabled ={this.state.disabled}
                                  name = "fechadesde"
                                  selected ={this.state.fechadesde}
                                  onChange = {this.handleFechaDesde}
                                  dateFormat="dd/MM/yyyy"
                                  locale="es"
                               >

                               </DatePicker>
                            </div>
                            <div className="col-md-2">
                                <label>Fecha Final: </label>
                                <DatePicker
                                  className="form-control"
                                  name = "fechafin"
                                  disabled={this.state.disabled}
                                  selected ={this.state.fechafin}
                                  onChange = {this.handleFechaFin}
                                  dateFormat="dd/MM/yyyy"
                                  locale="es"
                               >
                               </DatePicker>
                            </div>
                            <div className="col-md-2">
                                <br></br>{
                                    !this.state.consulta ? <Button variant="contained" color="primary" onClick={this.consultar}> Consultar</Button> : <Button variant="contained" color="primary" onClick={this.limpiar}>Otra Consulta</Button>
                                }
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-md-12">
                                <h6>INFORMACION DE COMPRA A PROVEEDORES</h6>
                                  <div className="table-responsive">
                                    <table className="table table-bordered table-hover table-sm">
                                        <thead className="bg-default"> 
                                            <tr>
                                                <th>No. Compras</th>
                                                <th>Cantidad Aves</th>
                                                <th>Peso Total</th>
                                                <th>Peso Con Merma</th>
                                                <th>Monto</th>
                                                <th>Promedio Precio</th>
                                                <th>Promedio Compra</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                               {
                                                   datosCompra.map((key, i) => <td key={`c${key}`}>{(i >= 4? 'Q. ' : '') + (NumberFormat.format(Number(this.state.consultaCompra[key])))}</td>)
                                               }
                                            </tr>
                                        </tbody>
                                    </table>
                                 </div>
                            </div>
                            <div className="col-md-12">
                                <h6>INFORMACION DE VENTA A CLIENTE</h6>
                                  <div className="table-responsive">
                                    <table className="table table-bordered table-hover table-sm">
                                        <thead className="bg-default">
                                            <tr>
                                                <th>No. Tickets</th>
                                                <th>Cantidad Aves</th>
                                                <th>Peso Total</th>
                                                <th>Monto</th>
                                                <th>Promedio Precio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                               {
                                                   datosVenta.map((key, i) => <td key={`v${key}`}>{(i >= 3? 'Q. ' : '') + (NumberFormat.format(Number(this.state.consultaVenta[key])))}</td>)
                                               }
                                            </tr>
                                        </tbody>
                                    </table>
                                 </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card">
                    <div className="card-header">Ingresar datos</div>
                        <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label style={{fontSize: sizeLabelText}}>Cantidad Aves Mortalidad: </label>
                                        <input type="number" value={this.state.cantidadMortalidad} onChange={this.handleCantidadMortalidad} className="form-control form-control-sm"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label style={{fontSize: sizeLabelText}}>Promedio de Compra por Mortalidad: </label>
                                        <input type="number" value={this.state.promedioCompraMortalidad} disabled className="form-control form-control-sm"/>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                <div className="col-md-6">
                                        <label style={{fontSize: sizeLabelText}}>Precio Mortalidad:</label>
                                        <input type="number" value={this.state.precioMortalidad} onChange={this.handlePrecioMortalidad} className="form-control form-control-sm"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label style={{fontSize: sizeLabelText}}>Total Descuento Mortalidad:</label>
                                        <input type="text" value={NumberFormat.format(Number(this.state.descuentoPorMortalidad))} disabled className="form-control form-control-sm"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-10">
                                        <label style={{fontSize: sizeLabelText}}>TOTAL A PAGAR POR CLIENTE:</label>
                                        <input type="text" value={NumberFormat.format(Number(this.state.totalAPagar))} disabled className="form-control form-control-sm"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label style={{fontSize: sizeLabelText}}>Observaciones:</label>
                                            <textarea value={this.state.observaciones} onChange={this.handleTextArea} className="form-control" rows="5"/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <br></br>
                                       <div className="row">
                                               <div className="col-md-12">
                                                    <Button variant="contained" startIcon={<FileCopyIcon />} color="primary" fullWidth onClick={(e)=>this.refFile.current.click()}> Cargar Archivo</Button>
                                                    <input type="file" ref={this.refFile} onChange={this.handleFile}/>
                                               </div>
                                               <div className="col-md-12">
                                                    <br></br>
                                                    <Button variant="contained" startIcon={<VisibilityIcon />} color="secondary" fullWidth disabled={this.state.srcFileModal === null} onClick={this.openModal}> Visualizar Archivo</Button>
                                                </div>
                                                <div className="col-md-12">
                                                    <br></br>
                                                   <Button variant="contained" startIcon={<SaveIcon />} color="inherit" fullWidth onClick={this.saveMortalidad}> Crear Mortalidad</Button>
                                                </div>  
                                       </div>
                                    </div>
                                    
                                </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>)
    }
}


const mapStateToProps = state => ({
    user : state.user
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type: 'LOGOUT'});
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(Mortalidad);



