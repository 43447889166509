import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {Modal} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Button from '@material-ui/core/Button';
import AxiosInstance from '../helpers'

const SwalAlert = withReactContent(Swal);

const Proveedor = (props) => {

    const [proveedor, setProveedor] = useState({
        id : 0,
        proveedor_proveedores : '',
        nit_proveedores : '',
        direccion_proveedores : '',
        telefono_proveedores : ''
    })

    const {proveedor_proveedores, nit_proveedores, direccion_proveedores, telefono_proveedores} = proveedor;

    const [proveedores, setProveedores] = useState([]);
    const [IsOpen, setOpen] = useState(false);

    const setValues = (e)=> {

        const name = e.target.name;
        const value = e.target.value;

        setProveedor(prev => ({
            ...prev,
            [name] : value
        }))
    }

    useEffect(()=>{
        getProveedores();
    }, [])

    const saveProveedor = async (e)=>{

        const name = e.target.name;

        if(proveedor.proveedor_proveedores === '' || proveedor.nit_proveedores === '' || proveedor.direccion_proveedores === ''){
            SwalAlert.fire({
                title: 'Faltan datos',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
                }).then(result => {
                    return;
                });
            return;
        }else{

        SwalAlert.fire({
            title: 'Espere',
            html: 'Guardando...',
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        })

         let methodApi = name === 'save' ? 'post' : 'put';
         let urlApi = name === 'save' ? '/api/saveProveedor' : '/api/updateProveedor';

          try{

            let saved = await  AxiosInstance({
                method : methodApi,
                url: urlApi,
                data : proveedor
            });
             getProveedores();

             SwalAlert.close();
             SwalAlert.fire({
                     position: 'top-center',
                     icon: 'success',
                     title: 'Guardado exitosamente',
                     showConfirmButton: false,
                     timer: 900
             }).then(result => {
                  closeModal();
                 return;
             })
             
          }catch(e){
            SwalAlert.close();
            SwalAlert.fire({
                    position: 'top-center',
                    icon: 'error',
                    title: 'Hubo un error, Inténtelo nuevamente',
                    showConfirmButton: false,
                    timer: 900
            }).then(result => {
                 closeModal();
                return;
            })
          }  
        }
    }

    const getProveedores = ()=> {
        AxiosInstance({
            method : 'get',
            url : '/api/getProveedores'
        }).then(res=> {
            setProveedores(res.data);
        }).catch(e=>{
            console.log(e);
        })
    }

    const closeModal = ()=>{
        setProveedor({
            id: 0,
            proveedor_proveedores : '',
            nit_proveedores : '',
            direccion_proveedores : '',
            telefono_proveedores : ''
        });
        setOpen(false);
    }

    const editFormatter = (cell, row)=>{
        return <button onClick={()=>updateProveedor(row)} className="btn btn-info"><i className="fa fa-edit"></i></button>
    }

    const deleteFormatter = (cell, row)=>{
        return <button onClick={()=>deleteProveedor(row)} name="delete" className="btn btn-danger"><i className="fa fa-cut"></i></button>
    }

    const updateProveedor = (row)=>{

        setProveedor({
            id: row.idproveedor,
            proveedor_proveedores : row.proveedor_proveedores,
            nit_proveedores : row.nit_proveedores,
            direccion_proveedores : row.direccion_proveedores,
            telefono_proveedores : row.telefono_proveedores
        }); 
        setOpen(true);
    }

    const deleteProveedor = (row)=>{
        SwalAlert.fire({
            title: `¿Seguro que desea eliminar a ${row.proveedor_proveedores}?`,
            text: "Debes pensarlo despacio",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí eliminar',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {

            if (result.value) {
                
                try{
                    let deleted =  await AxiosInstance({
                        method : 'post',
                        url : '/api/deleteProveedor',
                        data : {
                            id : row.idproveedor
                        }
                    });
            
                    getProveedores();

                    SwalAlert.close();
                    SwalAlert.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'Eliminado exitosamente',
                            showConfirmButton: false,
                            timer: 900
                    }).then(result => {
                         closeModal();
                        return;
                    })
                  } catch(e){
                     console.log(e);
                  } 
            }
          })
    }




    const sizeTextBody = 11


    return (
        <div className="container">

                <Modal show={IsOpen}>
                            <Modal.Header>
                            <Modal.Title>Actualizar</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-10">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td> <label htmlFor="cliente">Proveedor:</label></td>
                                                    <td> <input type="text" value={proveedor_proveedores} name="proveedor_proveedores" className="form-control" onChange={setValues}/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="razon_social_cliente">Nit:</label></td>
                                                    <td> <input type="text" value={nit_proveedores} name="nit_proveedores" className="form-control" onChange={setValues}/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="nit_cliente">Dirección:</label></td>
                                                    <td> <input type="text" value={direccion_proveedores} name="direccion_proveedores" onChange={setValues} className="form-control"/></td>
                                                </tr>
                                                <tr>
                                                    <td> <label htmlFor="direccion_cliente">Teléfono:</label></td>
                                                    <td> <input type="number" value={telefono_proveedores} name="telefono_proveedores"  onChange={setValues} className="form-control"/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                       
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="contained" name="update" color="primary" onClick={saveProveedor}>
                                    Guardar
                                </Button>
                                <Button variant="contained" color="secondary" onClick={closeModal}>
                                    Cancelar
                                </Button>
                            </Modal.Footer>
                    </Modal>


            <div className="card row">
                <div className="card-header">
                    <h6>Proveedor</h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label>Proveedor:</label>
                            <input type="text" name="proveedor_proveedores" value={proveedor_proveedores} className="form-control" onChange={setValues}/>
                        </div>
                        <div className="col-md-3">
                            <label>Nit:</label>
                            <input type="text" name="nit_proveedores" value={nit_proveedores} className="form-control" onChange={setValues}/>
                        </div>
                        <div className="col-md-3">
                            <label>Dirección: </label>
                            <input type="text" name="direccion_proveedores" value={direccion_proveedores} className="form-control" onChange={setValues}/>
                        </div>
                        <div className="col-md-3" >
                        <label>Teléfono: </label>
                            <input type="number" name="telefono_proveedores" value={telefono_proveedores} className="form-control" onChange={setValues}/>
                        </div> 
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-md-3">
                            <button className="btn btn-success" name="save" onClick={saveProveedor}><i className="fa fa-save"></i> Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
             <div className="row">
                    <div className="col-md-12">
                    <div className="card">
                    <h6 className="card-header bg-default"><i className="fas fa-list-ol"></i> LISTADO DE PROVEEDORES</h6>
                        <div className="card-body">
                              <BootstrapTable data={proveedores}  search={ true } searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                                <TableHeaderColumn isKey dataField='idproveedor' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:60}} thStyle={{fontSize: sizeTextBody,width:60, backgroundColor: 'grey', color: 'white'}}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='proveedor_proveedores'         dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:150}} thStyle={{fontSize: sizeTextBody, width:150, backgroundColor: 'grey', color: 'white'}}>Proveedor</TableHeaderColumn>
                                <TableHeaderColumn dataField='nit_proveedores' tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Nit</TableHeaderColumn>
                                <TableHeaderColumn dataField='direccion_proveedores'       dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: sizeTextBody, backgroundColor: 'grey', color: 'white'}}>Dirección</TableHeaderColumn>
                                <TableHeaderColumn dataField='telefono_proveedores' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: sizeTextBody,  backgroundColor: 'grey', color: 'white'}}>Teléfono</TableHeaderColumn>
                                <TableHeaderColumn dataField='idproveedor'         dataFormat={ editFormatter}  tdStyle={{fontSize:sizeTextBody,  width: '60px'}} thStyle={{fontSize: sizeTextBody, width: '60px',backgroundColor: 'grey', color: 'white'}}>Editar</TableHeaderColumn>
                                <TableHeaderColumn dataField='idproveedor'         dataFormat={ deleteFormatter} tdStyle={{fontSize:sizeTextBody, width: '60px'}} thStyle={{fontSize: sizeTextBody, width: '60px', backgroundColor: 'grey', color: 'white'}}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                         </div>
                    </div>
                    </div>
                  </div>

        </div>
    )
}


export default Proveedor;


