import React, { Component, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Compra from "../src/compras/Compra";
import IndiceCompras from "../src/compras/IndiceCompras";
import Venta from "../src/ventas/Venta";
import IndiceVentasOther from "../src/ventas/IndiceVentasOther";
import IndiceGastos from "../src/pagos/IndiceGastos";
import Usuarios from "../src/usuarios/Usuarios";
import Mortalidad from "../src/mortalidad/Mortalidad";
import IndiceMortalidad from "../src/mortalidad/IndiceMortalidad";
import Perfil from "../src/perfiles/Perfil";
import Cliente from "../src/clientes/Cliente";
import PagoComponente from "../src/pagos/Pago";
import Empleado from "../src/empleados/Empleados";
import Proveedor from "../src/proveedores/Proveedor";
import IndicePagos from "../src/pagos/IndicePagos";
import Home from "../src/Home/Home";
import Gasto from "../src/pagos/Gasto";
import Button from "@material-ui/core/Button";
import swal from "sweetalert2";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { connect } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./App.css";
import AxiosInstance from "./helpers";
import URI_BACKEND from "./confProyecto";
import userImage from "./img/user.png";

const STATUS_LOGIN = {
  error: -1,
  none: 0,
  newregister: 1,
  pending: 2,
  isvalidated: 3,
};

const LoginComponent = (props) => {
  const [fieldsLogin, setFieldsLogin] = useState(true);
  const [foto, setFoto] = useState(null);
  let [username, setUsername] = useState(null);
  let [password, setPassword] = useState(null);
  let [errorUsername, setErrorUsername] = useState("");
  let [errorPassword, setErrorPassword] = useState("");
  let [clicked, setClicked] = useState(false);
  let [marginTop, setMarginTop] = useState(10);
  const [textRegisterAndActivation, setTextRegisterAndActivation] =
    useState("");
  const [titleRegisterAndActivation, setTitleRegisterAndActivation] =
    useState("");

  useEffect(() => {
    setClicked(false);
    if (
      props.errorAuthentication !== "" &&
      props.errorAuthentication !== null
    ) {
      swal
        .fire({
          title: "Error de Ingreso!",
          text: "Usuario o Clave Incorrecta!",
          icon: "warning",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        })
        .then((result) => {
          props.setErrorMesagge();
        });
    }
  }, [props.errorAuthentication]);

  const setSizeImg = () => {
    if (window.innerWidth < 576) {
      setMarginTop(10);
    } else {
      setMarginTop(100);
    }
  };

  useEffect(() => {
    setSizeImg();
    window.addEventListener("resize", setSizeImg);

    return () => {
      window.removeEventListener("resize", setSizeImg);
    };
  }, []);

  useEffect(() => {
    //Send Token for validate in backend
    const handleCallBackResponse = (response) => {
      AxiosInstance({
        method: "post",
        url: "/apis/login_google",
        data: { credential_google: response.credential },
      })
        .then((res) => {
          switch (res.data.usuario.statusLogin) {
            case STATUS_LOGIN.isvalidated:
              console.log(res.data.usuario.user);
              configGoogleLogin(res.data.usuario.user);
              break;
            case STATUS_LOGIN.newregister:
              //swal.fire({title: 'REGISTRO EXITOSO', text: 'El usuario ha sido registrado exitosamente, espera a que un administrador active su usuario. GRACIAS', icon: 'info'});
              setTextRegisterAndActivation(
                "El usuario ha sido registrado exitosamente, espera a que un administrador active su usuario. GRACIAS"
              );
              setTitleRegisterAndActivation("REGISTRO EXITOSO");
              setFieldsLogin(false);
              break;
            case STATUS_LOGIN.pending:
              setTextRegisterAndActivation(
                "El usuario esta pendiente de activación"
              );
              setTitleRegisterAndActivation("ACTIVACION PENDIENTE");
              setFieldsLogin(false);
              break;
            case STATUS_LOGIN.error:
              swal.fire("Error", res.data.usuario.message, "error");
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /* global google */
    google.accounts.id.initialize({
      client_id:
        "1020724235559-1magrct2ct2ol7osb95etkjs8pnfg349.apps.googleusercontent.com", //Cuenta google proyecto Surena
      callback: handleCallBackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "filled_blue",
      size: "large",
      shape: "circle",
    });
    //google.accounts.id.prompt();
  }, [fieldsLogin]);

  function validateUsername(value) {
    if (value.length === 0) {
      setErrorUsername("Login de Usuario requerido");
    } else {
      setErrorUsername("");
      setUsername(value);
    }
  }
  //Set the session storage variables
  function configGoogleLogin(dataUser) {
    console.log("configGoogleLogin");
    console.log(dataUser);

    // Guardar los datos relevantes en sessionStorage
    sessionStorage.setItem("id", dataUser.idusuario);
    sessionStorage.setItem("user", dataUser.login_usuario);
    sessionStorage.setItem("nombre_completo", dataUser.nombre_completo);
    sessionStorage.setItem("rol_usuario", dataUser.perfil);
    sessionStorage.setItem("idperfil", dataUser.idperfil);
    sessionStorage.setItem("token", dataUser.token);
    sessionStorage.setItem("vencimiento_token", dataUser.vencimiento_token);
    sessionStorage.setItem("accesos", JSON.stringify(dataUser.accesos));
    sessionStorage.setItem("foto", dataUser.url_photo);
    // Crear una lista de rutas para componentes
    let accesos = dataUser.accesos;
    let routesForComponent = [];

    accesos.forEach((elemento) => {
      elemento.routes.forEach((ruta) => {
        routesForComponent.push(ruta);
      });
    });

    // Almacenar la lista de rutas en sessionStorage
    sessionStorage.setItem(
      "routesForComponent",
      JSON.stringify(routesForComponent)
    );
    console.log(routesForComponent);
    // Configurar encabezados de solicitud para Axios
    AxiosInstance.defaults.headers.common["acces-token"] = dataUser.token;

    // Redireccionar al usuario a la página principal
    window.location = "/";
  }
  function validatePassword(value) {
    if (value.length === 0) {
      setErrorPassword("Password requerido");
    } else {
      setErrorPassword("");
      setPassword(value);
    }
  }

  function trylogin(e) {
    if (e.keyCode == 13) {
      login();
    }
  }

  function login() {
    setClicked(true);
    props.login(username, password);
  }
{/*LOGIN CON GOOGLE*/}
return(
    <div className="form-body without-side">
      <div className="row">
        <div className="img-holder">
          <div className="bg"></div>
        </div>
        {fieldsLogin ? (
          <div className="form-holder">
            <div className="website-logo" style={{ marginTop: "70px" }}>
              <img src="images/logopollo-transparent.png"></img>
            </div>
            <div className="form-content">
              <div className="form-items">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <form>
                  <div className="col-md-12 justify-content-md-center">
                    <div className="col-md-auto" style={{ marginTop: "30px" }}>
                      <div
                        id="signInDiv"
                        style={{ display: "flex", justifyContent: "center" }}
                      ></div>
                    </div>
                    <br></br>
                    <br></br>
                  </div>
                </form>
                <div
                  className="col-md-12 justify-content-center"
                  style={{
                    display: "flex",
                    borderTop: "2px solid #ccc",
                    padding: "10px 0",
                  }}
                >
                  <i
                    style={{ marginTop: "3px", color: "#474747" }}
                    className="fa fa-lock"
                  ></i>
                  <p style={{ marginLeft: "10px", color: "#474747" }}>
                    Encrypted, safe & secure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <form>
                  <div
                    className="col-md-12 justify-content-md-center"
                    style={{ marginTop: "-70px" }}
                  >
                    <div className="col-md-auto">
                      <h3 className="mb-4 text-center" style={{color:'white'}}>
                        {titleRegisterAndActivation}
                      </h3>
                    </div>
                    <label className="col-md-auto" style={{color:'white'}}>
                      {textRegisterAndActivation}
                    </label>
                    <div className='row justify-content-md-center' style={{color:'white'}}>
                                        <button className="col-md-6 btn btn-primary" onClick={() => setFieldsLogin(true)}>
                                            <i className="fa fa-arrow-left"></i>Regresar
                                        </button>
                                    </div>
                    <br></br>
                    <br></br>
                  </div>
                </form>
                <div
                  className="col-md-12 justify-content-center"
                  style={{
                    display: "flex",
                    borderTop: "2px solid #ccc",
                    padding: "10px 0",
                  }}
                >
                  <i
                    style={{ marginTop: "3px", color: "#474747" }}
                    className="fa fa-lock"
                  ></i>
                  <p style={{ marginLeft: "10px", color: "#474747" }}>
                    Encrypted, safe & secure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </div> 
    );
  // return (
  //   <div className="wrapper container">
  //       <div style={{marginTop: marginTop}}>
  //       <div className="row justify-content-center">
  //               <div className="col-md-4">
  //                   <div className="d-flex justify-content-center">
  //                       <img src="images/logopollo.jpg" style={{width: '80%'}}></img>
  //                   </div>
  //               </div>
  //       </div>
  //       <div className="row justify-content-center">
  //                                   <div className="col-md-5">
  //                                       <div className="d-flex justify-content-center">
  //                                           <div className="card">
  //                                                <div className="card-header">
  //                                                   <h6>Inicio de Sesion</h6>
  //                                                </div>
  //                                                <div className="card-body">
  //                                                  <div className="row">
  //                                                       <div class="col-md-12">
  //                                                           <label class="sr-only" for="inlineFormInputGroup">Usuario</label>
  //                                                           <div class="input-group mb-2">
  //                                                               <div class="input-group-prepend">
  //                                                               <div class="input-group-text"><i className="fa fa-user"></i></div>
  //                                                               </div>
  //                                                               <input type="text" onChange={(e) => validateUsername(e.target.value)} class="form-control" id="inlineFormInputGroup" placeholder="Usuario"/>
  //                                                           </div>
  //                                                       </div>
  //                                                       <br></br>
  //                                                        <div class="col-md-12">
  //                                                           <label class="sr-only" for="inlineFormInputGroup">Contraseña</label>
  //                                                           <div class="input-group mb-2">
  //                                                               <div class="input-group-prepend">
  //                                                               <div class="input-group-text"><i className="fa fa-key"></i></div>
  //                                                               </div>
  //                                                               <input type="password" onKeyDown={trylogin}  onChange={(e) => validatePassword(e.target.value)} class="form-control" id="inlineFormInputGroup" placeholder="Contraseña"/>
  //                                                           </div>
  //                                                       </div>
  //                                                       <br></br>
  //                                                       <div className="col-md-12">
  //                                                                    { !clicked ? <div className="d-flex justify-content-center">
  //                                                                       <Button variant="contained"
  //                                                                           color="primary"
  //                                                                           size="large"
  //                                                                           startIcon={<SupervisorAccountIcon />} 
  //                                                                           disabled={!!errorUsername || !!errorPassword}  
  //                                                                           onClick={(e)=> login()}>INGRESAR</Button>
  //                                                                   </div> : <CircularProgress color="secondary"/> }
  //                                                       </div>
  //                                                  </div>
  //                                                </div>
  //                                           </div>
  //                                        </div> {/* end d-flex */}
  //                                   </div>
                                
  //       </div>
  //       </div>
  //       </div>
  // );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foto: null,
      componentes: [
        {
          id: 1,
          component: function (props) {
            return <Venta {...props}></Venta>;
          },
        },
        {
          id: 2,
          component: function (props) {
            return <IndiceVentasOther {...props}></IndiceVentasOther>;
          },
        },
        {
          id: 3,
          component: function (props) {
            return <Compra isModal={false} {...props}></Compra>;
          },
        },
        {
          id: 4,
          component: function (props) {
            return <IndiceCompras {...props}></IndiceCompras>;
          },
        },
        {
          id: 5,
          component: function (props) {
            return <Usuarios {...props}></Usuarios>;
          },
        },
        {
          id: 6,
          component: function (props) {
            return <Perfil {...props}></Perfil>;
          },
        },
        {
          id: 7,
          component: function (props) {
            return <Mortalidad {...props}></Mortalidad>;
          },
        },
        {
          id: 8,
          component: function (props) {
            return <IndiceMortalidad {...props}></IndiceMortalidad>;
          },
        },
        {
          id: 9,
          component: function (props) {
            return <PagoComponente {...props}></PagoComponente>;
          },
        },
        {
          id: 10,
          component: function (props) {
            return <Gasto {...props}></Gasto>;
          },
        },
        {
          id: 11,
          component: function (props) {
            return <IndiceGastos {...props}></IndiceGastos>;
          },
        },
        {
          id: 12,
          component: function (props) {
            return <Cliente {...props}></Cliente>;
          },
        },
        {
          id: 13,
          component: function (props) {
            return <Proveedor {...props}></Proveedor>;
          },
        },
        {
          id: 14,
          component: function (props) {
            return <Empleado {...props}></Empleado>;
          },
        },
        {
          id: 15,
          component: function (props) {
            return <IndicePagos {...props}></IndicePagos>;
          },
        },
      ],
    };
  }

  componentDidMount() {
    // Acceder a la foto almacenada en el sessionStorage
    const storedFoto = sessionStorage.getItem("foto");
    if (storedFoto) {
      // Actualizar el estado con la foto almacenada
      this.setState({ foto: storedFoto });
    }
  }

  render() {
    return (
      <Router>
        <div className={"wrapper"}>
          <Route
            exact
            path="/login"
            render={(props) =>
              !this.props.authenticated ? (
                <LoginComponent
                  {...props}
                  setClick={this.props.setClick}
                  clicked={this.props.clicked}
                  login={this.props.login}
                  errorAuthentication={this.props.errorAuthentication}
                  setErrorMesagge={this.props.setErrorMesagge}
                ></LoginComponent>
              ) : (
                <Redirect {...props} to="/home" />
              )
            }
          ></Route>
        </div>

        <div
          className={this.props.authenticated ? "wrapper" : "wrapper container"}
        >
          {" "}
          {/* row justify-content-center*/}
          <div style={{ display: this.props.authenticated ? "block" : "none" }}>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
              <ul className="navbar-nav">
                <li className="nav-item" style={{ zIndex: 5000 }}>
                  <a className="nav-link" data-widget="pushmenu" href="#">
                    <i className="fas fa-bars"></i>
                  </a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <Link to="/home" className="nav-link">
                    Dashboard
                  </Link>
                </li>
              </ul>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button
                    className="btn btn-danger"
                    onClick={(e) => this.props.logout()}
                  >
                    Salir
                  </button>
                </li>
              </ul>
            </nav>

            <aside
              className="main-sidebar sidebar-dark-primary elevation-4 bg-dark"
              style={{ color: "white" }}
            >
              <a href="/home" className="brand-link">
                <div className="d-flex justify-content-center">
                  <img
                    src="images/logopollo.jpg"
                    alt="La Sureña"
                    className="brand-image img-circle elevation-3"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <p
                    className="brand-text"
                    style={{ fontSize: 10, marginBottom: 0 }}
                  >
                    Distribuidora de Pollo
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <span className="brand-text font-weight-light">
                    "La Sureña" S.A.
                  </span>
                </div>
              </a>

              <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div className="image">
                    <img
                      src={
                        this.state.foto !== "Foto no Encontrada"
                          ? this.state.foto
                          : userImage
                      }
                      className="img-circle elevation-2"
                      alt="foto"
                    />
                  </div>
                  <div className="info">
                    <a href="/home" className="d-block">
                      {this.props.user}
                    </a>
                  </div>
                </div>
                <nav className="mt-2" id="navtree">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {this.props.accesos.map((ruta) => (
                      <li
                        key={ruta.modulo.idmodulos}
                        className="nav-item has-treeview"
                      >
                        <a href="#" className="nav-link">
                          <i className={ruta.modulo.icono}></i>
                          <p>{ruta.modulo.modulo}</p>
                        </a>

                        <ul className="nav nav-treeview bg-secondary">
                          {ruta.routes.map((path) => (
                            <li key={path.idsubmenu} className="nav-item">
                              <Link to={path.acceso} className="nav-link">
                                <div className="row">
                                  <div className="col-md-2"></div>
                                  <div className="col-md-10">
                                    <p>
                                      <i className={path.icono}></i>{" "}
                                      {path.nombre_menu}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </aside>
          </div>
          <section
            className={this.props.authenticated ? "content-wrapper" : "row"}
          >
            <div
              className={
                this.props.authenticated ? "content-fluid" : "col-md-12"
              }
            >
              <Switch>
                {this.props.routesForComponent.map((ruta) => (
                  <Route
                    key={ruta.idsubmenu}
                    exact
                    path={ruta.acceso}
                    render={(props) =>
                      this.props.authenticated ? (
                        this.state.componentes
                          .find((comp) => comp.id === ruta.idsubmenu)
                          .component(props)
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  ></Route>
                ))}
                <Route
                  exact
                  path="/"
                  render={(props) => <Redirect {...props} to="/login" />}
                ></Route>

                {this.props.authenticated ? (
                  <Route
                    exact
                    path="/home"
                    render={(props) => <Home {...props}></Home>}
                  ></Route>
                ) : (
                  <Redirect to="/login"></Redirect>
                )}
              </Switch>
            </div>
          </section>
          {this.props.authenticated ? (
            <div>
              <footer className="main-footer">
                <strong>Powered by </strong>
                <a
                  href="https://ipetik.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ipetik Solutions
                </a>
              </footer>
              <aside className="control-sidebar control-sidebar-dark"></aside>{" "}
            </div>
          ) : null}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.authenticated,
  user: state.user,
  clicked: state.clicked,
  accesos: state.accesos,
  routesForComponent: state.routesForComponent,
  errorAuthentication: state.errorAuthentication,
});

const mapDispatchToProps = (dispatch) => ({
  async login(username, password) {
    try {
      let UserInformation = await AxiosInstance({
        method: "post",
        url: "/apis/login",
        data: { login_usuario: username, pass: password },
      });

      if (UserInformation) {
        if (UserInformation.data.token) {
          sessionStorage.setItem("token", UserInformation.data.token);
          sessionStorage.setItem(
            "user",
            UserInformation.data.usuario.login_usuario
          );
          sessionStorage.setItem(
            "idperfil",
            UserInformation.data.usuario.idperfil
          );
          sessionStorage.setItem(
            "accesos",
            JSON.stringify(UserInformation.data.accesos)
          );
          sessionStorage.setItem(
            "foto",
            URI_BACKEND + UserInformation.data.usuario.url_photo
          );

          let user = UserInformation.data.usuario.login_usuario;
          let accesos = UserInformation.data.accesos;
          let routesForComponent = [];
          let idperfil = UserInformation.data.usuario.idperfil;

          accesos.forEach((elemento) => {
            elemento.routes.forEach((ruta) => {
              routesForComponent.push(ruta);
            });
          });

          sessionStorage.setItem(
            "routesForComponent",
            JSON.stringify(routesForComponent)
          );

          dispatch({
            type: "LOGIN",
            user,
            accesos,
            routesForComponent,
            idperfil,
          });
        }
      }
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 403) {
        console.log("No pasas de aqui");
        dispatch({
          type: "ERROR_AUTHENTICATION",
          message: e.response.data.Message,
        });
      }
    }
  },
  verifyTokenIfRefesh() {
    let token = sessionStorage.getItem("token");
    let user = sessionStorage.getItem("user");
    if (token) {
      dispatch({ type: "LOGIN", user });
    } else {
      dispatch({ type: "LOGOUT" });
    }
  },
  setClick() {
    dispatch({ type: "SETCLICK" });
  },
  logout() {
    sessionStorage.clear();

    dispatch({ type: "LOGOUT" });
  },
  setErrorMesagge() {
    dispatch({ type: "ERROR_AUTHENTICATION", message: "" });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
