import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AxiosInstance from '../helpers'
import {formatearFecha} from '../helpers/helperFuncs'
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap';
//import { PDFDownloadLink, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer'
import Gasto from './Gasto';
import FilaInformativa from '../helpers/FilaInformativa'

const sizeTextBody = 13



function IndiceGastos (props){

    const [desde, changeDesde] = useState(new Date());
    const [hasta, changeHasta] = useState(new Date(desde.getFullYear(), desde.getMonth(), desde.getDate()+7));
    const [tipoGasto, changeTipoGasto] = useState({
        value : null,
        label : 'Todos'
    });
    const [formateador, changeFormateador] = useState(new Intl.NumberFormat('en-US'));

    const [datosFilaInformativa, changeDatosFila] = useState([
        {titulo: 'CANTIDAD PAGOS', dato: 0, signo: '', icono:<i className="fas fa-kiwi-bird"></i>},
        {titulo: 'MONTO PAGADO', dato: 0, signo: '', icono:<i className="fas fa-balance-scale"></i>}
    ])

    const [options, changeOptions] = useState([{
        value : null,
        label : 'Todos'
    }])

    const [IsModalOpen, changeIsModalOpen] = useState(false);

    const [updated, changeUpdated] = useState({});

    const [listGastos, changeListGastos] = useState([])

    

    useEffect(()=>{
        getTiposGasto();
        consultar();
    }, [])

    function consultar (){
        AxiosInstance({
            method : 'get',
            url : '/api/getGastos',
            params:{
                desde,
                hasta,
                idtipogasto : tipoGasto.value
            }
        }).then(res => {

            let cantidadPagos = formateador.format(res.data.cantidadPagos);
            let montoPagos = formateador.format(Number(res.data.montoPagos));

            changeDatosFila([
                {titulo: 'CANTIDAD PAGOS', dato: cantidadPagos, signo: '', icono:<i className="fas fa-kiwi-bird"></i>},
                {titulo: 'MONTO PAGADO', dato: montoPagos, signo: 'Q.', icono:<i className="fas fa-balance-scale"></i>}
            ])

            let lista_de_gastos = res.data.gastos.map(gasto=>({idpago: gasto.idpago, 
                                                                fechapago: gasto.fechapago,
                                                                 tipo_gasto: gasto.tipo_gasto.tipogasto,
                                                                 montopago : gasto.montopago,
                                                                 usuario : gasto.usuario.login_usuario
                                                                }))
            changeListGastos(lista_de_gastos);
        })
    }

    function getTiposGasto(){
        AxiosInstance({
            method : 'get',
            url : '/api/getTiposGasto'
        }).then(res => {
            const listGastos = res.data.map(gasto =>({value: gasto.idtipogasto, label : gasto.tipogasto}));
            changeOptions([...options, ...listGastos]);
        }).catch(error => {
            console.log(error);
        })
    }

    function selectRow(cell, row){
        changeUpdated({
          idpago : row.idpago,
          tipoGastoSelect : {value : row.idtipogasto, label: row.tipo_gasto.tipogasto},
          monto : Number(row.montopago),
          fecha : row.fechapago  
        })

        changeIsModalOpen(true);

    }
    function cerrar (){
        changeIsModalOpen(false);
    }


    function formatFecha(cell, row){
        return formatearFecha(cell);
    }

    function numberFormater(cell, row){
        return formateador.format(cell);
    }

    
    function editar(cell, row){
        return <button className="btn btn-info" onClick={()=>selectRow(cell, row)} ><i className="fa fa-edit"></i> Editar</button>
    }

    return (
        <div className="container">
               <Modal size="lg" show={IsModalOpen}>
                        <Modal.Body>
                        <Gasto updated={updated} cerrar={cerrar} consultar={consultar} options={options}></Gasto>
                        </Modal.Body>
                </Modal>
            <br></br>
            <FilaInformativa columnas = {datosFilaInformativa}></FilaInformativa>
            <br></br>
            <div className="card row">
                <div className="card-header">
                    <h5>Parámetros de consulta</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label>Desde: </label>
                            <DatePicker
                                selected={desde}
                                onChange={date => changeDesde(date)}
                                className="form-control"
                                dateFormat ="dd/MM/yyyy"
                                locale="es"
                                strictParsing
                            >
                            </DatePicker>
                        </div>
                        <div className="col-md-3">
                            <label>Desde: </label>
                            <DatePicker
                                selected={hasta}
                                onChange={date => changeHasta(date)}
                                className="form-control"
                                dateFormat ="dd/MM/yyyy"
                                locale="es"
                                strictParsing
                            >
                            </DatePicker>
                        </div>
                        <div className="col-md-3">
                            <label>Tipo Pago: </label>
                            <Select
                                value = {tipoGasto}
                                options={options}
                                onChange={selected => changeTipoGasto(selected)}
                            >
                            </Select>
                        </div>
                        <div className="col-md-3">
                            <br></br>
                            <Button variant="contained" color="primary" onClick={consultar} startIcon={<Search></Search>}>Consultar</Button>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
                    <div className="card row" style={{padding: 10}}>
                        <BootstrapTable data={listGastos} search={ true } searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                                <TableHeaderColumn isKey dataField='idpago' hidden={true} dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:60}} thStyle={{fontSize: 11,width:60, backgroundColor: 'grey', color: 'white'}}>Id</TableHeaderColumn>
                                <TableHeaderColumn dataField='fechapago' dataFormat={formatFecha}    dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:150}} thStyle={{fontSize: 11, width:150, backgroundColor: 'grey', color: 'white'}}>Fecha</TableHeaderColumn>
                                <TableHeaderColumn dataField='tipo_gasto'  dataSort={ true }  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: 11,  backgroundColor: 'grey', color: 'white'}}>Tipo Pago</TableHeaderColumn>
                                <TableHeaderColumn dataField='montopago' dataFormat={numberFormater} dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Monto</TableHeaderColumn>
                                <TableHeaderColumn dataField='usuario'    dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Realizado por</TableHeaderColumn>
                                <TableHeaderColumn dataField='idpago'    dataFormat= {editar}  dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Editar</TableHeaderColumn>
                        </BootstrapTable>
                    </div>

        </div>
    )


}

const mapStateToProps = state => ({
    user : state.user
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type: 'LOGOUT'});
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(IndiceGastos);