import React, {Component} from 'react';

let FilaInformativa2 = (props)=>{
    return(
        <div className="row">
        {
          props.columnas.map((el, i)=>{
                return(
                    <div key={i} className={'col-md-'+(i !== 0 && i !== props.columnas.length-1? 8/(props.columnas.length-2) : 2)}>
                        <div className="card">
                           <h6 className="card-header bg-info text-center">{el.icono} {el.titulo}</h6>
                                <div className="card-body">
                                    <div className="text-center">
                                          <h6 className="h5 text-center"> {el.signo}{el.dato}</h6>
                                    </div>
                                      
                                </div>
                        </div>
                    </div>
                )
            })
        }
    </div>
    )
}

export default FilaInformativa2;