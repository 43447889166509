import React, {Component} from 'react'
import FilaInformativa from '../helpers/FilaInformativa'
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {formatearFecha} from '../helpers/helperFuncs'
import AxiosInstance from '../helpers'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

registerLocale('es', es)
const SwalAlert = withReactContent(Swal);


class IndiceMortalidad extends Component{


    constructor(props){
        super(props)

        let desde = new Date();
        let hasta = new Date();
        hasta.setDate(hasta.getDate() + 7);
        
        this.state = {
            carga : false,
            itemsTabla : [],
            selected: 1000000,
            allVentas : [],
            instanciaFormatter : new Intl.NumberFormat('en-US'),
            modalIsOpenFile: false,
            selectedCliente : {value : 0, label : 'Todos'},
            clientes : [],
            desde,
            srcFileModal : '',
            hasta,
            columnas: [{titulo: 'CANTIDAD AVES', dato: 0, signo: '', icono:<i className="fas fa-sort-numeric-up-alt"></i>},
                       {titulo: 'TOTAL PESO', dato: 0, signo: '', icono:<i className="fas fa-sort-numeric-up-alt"></i>},
                       {titulo: 'MONTO TOTAL MORTALIDAD', dato: 0, signo: '', icono : <i className="fas fa-sort-numeric-up-alt"></i>}],
           mortalidades : []
        }

        this.getDatosMortalidad = this.getDatosMortalidad.bind(this);

    }

    deleteMortalidad = (idmortalidad) => {
        
        SwalAlert.fire({
            title: 'Espere',
            html: 'Eliminando...',
            timerProgressBar: true,
            onBeforeOpen: () => {
              Swal.showLoading()
            }
        })

        AxiosInstance({
            method : 'post',
            url : '/api/deleteMortalidad',
            data : {
                idmortalidad
            }
        }).then(res => {
            SwalAlert.close();
            SwalAlert.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Eliminado exitosamente',
                showConfirmButton: false,
                timer: 900
            }).then(result => {
                this.getDatosMortalidad();
            });
        })
    }

    deleteFormatter = (cell, row) => {
        return <button className="btn btn-info btn-sm" onClick={()=>this.deleteMortalidad(cell)}>Eliminar</button>
    }




    getClientes(){
        AxiosInstance({
            method : 'get',
            url : '/api/getclients',
        }).then(res => {
            let clientes = res.data.map(cli => ({value : cli.idcliente, label : cli.cliente}));
            clientes.push({value : 0, label : 'Todos'});
            this.setState({
                clientes
            })
    
        }).catch(e => {
            console.log(e);
        })
     }
    

     getDatosMortalidad(){

        AxiosInstance({
            method: 'get',
            url: '/api/getdatamortalidad',
            params : {
                idcliente : this.state.selectedCliente.value,
                fechadesde : formatearFecha(this.state.desde, "english"),
                fechafin : formatearFecha(this.state.hasta, "english")
            }
        }).then(res => {
                
                this.setState({
                    mortalidades : res.data.mortalidades.map(mor=>({...mor, fecha : formatearFecha(mor.fecha)})),
                    columnas: [{titulo: 'CANTIDAD AVES', dato: res.data.totalAvesMortalidad, signo: '', icono:<i className="fas fa-sort-numeric-up-alt"></i>},
                                {titulo: 'CANTIDAD MORTALIDADES', dato: res.data.cantidad, signo:'', icono: <i className="fas fa-sort-numeric-up-alt"></i>},
                                {titulo: 'MONTO TOTAL MORTALIDAD', dato: res.data.totalMonto, signo: 'Q.', icono : <i className="fas fa-sort-numeric-up-alt"></i>}],
                })
        }).catch(e =>{
            console.log(e);
        })

     }

     onDateChangeHasta = (hasta)=>{
        this.setState({
            hasta
        })
    }

    onDateChangeDesde = (desde)=>{
        this.setState({
            desde
        })
    }

    handleSelecCliente = (selectedCliente)=>{
        this.setState({
            selectedCliente
        })
    }

     nuevaMortalidad = ()=> {
         this.props.history.push('/mortalidad')
     }

     openModal = () =>{
         this.setState({
            modalIsOpen : true
         })
     }

     
     closeModal = () =>{
        this.setState({
           modalIsOpen : false
        })
    }


     generarPdf(){

        let rows = this.state.mortalidades.map(m => [
            m.idmortalidad, 
            m.fecha, 
            m.cliente, 
            this.state.instanciaFormatter.format(Number(m.cantidad_mortalidad)),
            m.precio_mortalidad,
            this.state.instanciaFormatter.format(Number(m.monto_mortalidad)),
            this.state.instanciaFormatter.format(Number(m.monto_original)),
            this.state.instanciaFormatter.format(Number(m.nuevo_monto))]);
    
    
        let fecha_desde = formatearFecha(this.state.desde, "spanish")
        let fecha_hasta = formatearFecha(this.state.hasta, "spanish")
          
        let fila = this.state.columnas.reduce((prev, current)=> [...prev, current.dato], []);
    
        var doc = new jsPDF('p', 'pt');
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.setFontStyle('normal');
          doc.text("AVICOLA CANIZ", 20, 50);
    
          doc.setFontSize(12);
          doc.setTextColor(22);
          doc.setFontStyle('normal');
          doc.text(`Rango de fechas: ${fecha_desde} al ${fecha_hasta} `, 20, 70);
    
          doc.setFontSize(12);
          doc.setTextColor(22);
          doc.setFontStyle('normal');
          doc.text(`Cliente: ${this.state.selectedCliente.label}`, 20, 90);
   
    
          doc.autoTable({
              head : [['CANTIDAD AVES', 'CANTIDAD MORTALIDADES', 'MONTO TOTAL']],
              body : [fila],
              startY: 122,
              margin: { horizontal: 20 },
              bodyStyles: { valign: 'top', halign : 'center' },
              styles: { overflow: 'linebreak' },
              theme: "striped"
          })
          
          doc.autoTable({
            head: [['Id', 'Fecha', 'Cliente', 'Cantidad Mortalidad', 'Precio Mortalidad', 'Monto Mortalidad', 'Monto Original', 'Diferencia Monto']],
            body: rows.sort((a, b) => a[0] - b[0]),
            startY: 170,
            margin: { horizontal: 10 },
            styles: { overflow: 'linebreak' },
            modalIsOpen : false,
            headerStyles : { fontSize: 8},
            columnStyles : {    0 : {fontSize : 6},
                                1 : {fontSize : 6},
                                2 : {fontSize : 6},
                                3 : {fontSize : 6},
                                4 : {fontSize : 6},
                                5 : {fontSize : 6},
                                6 : {fontSize : 6},
                                7 : {fontSize : 6},
                                8 : {fontSize : 6},
                                9 : {fontSize : 5},
            },
            bodyStyles: { valign: 'top'},
            theme: "striped"
          });
    
           let date = new Date();
    
           let ano = date.getFullYear();
           let mes = (date.getMonth()+1) < 10? `0${date.getMonth()+1}` : date.getMonth()+1;
           let dia = date.getDate() < 10? `0${date.getDate()}`: date.getDate();
           let hora = date.getHours();
           let min = date.getMinutes();
    
           let fechayhora = `${dia}/${mes}/${ano} -- ${hora}:${min}`;
          
           let str = `Generado por: ${this.props.user}       Fecha: ${fechayhora}`
          
            doc.setFontSize(10);
            doc.text(str, 50, doc.internal.pageSize.height - 15);
    
          doc.save('Ventas.pdf');
    }

    verFile = (path)=>{

        AxiosInstance({
            method : 'post',
            url : '/api/getFileMortalidad',
            data : {filename : path},
            responseType: "blob"
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data], {
                type: "application/pdf"
                })); 
                this.setState({
                    srcFileModal : url,
                    modalIsOpen : true
                })
        })
    }

    fileFormatter = (cell, row) =>{
        return <Button disabled={cell === null} onClick={this.verFile.bind(this, cell)} color="primary"><i className="fa fa-file"></i></Button>
    }

    formatearNumeros = (cell, row) => {
        return this.state.instanciaFormatter.format(Number(cell));
    }

    componentDidMount(){
        this.getClientes();
        this.getDatosMortalidad();
    }

    render(){
   
       const sizeTextBody = 11

        return <div className="container">

                <Modal size="lg" show={this.state.modalIsOpen}>
                            <Modal.Header>
                            <Modal.Title>Visualicación de archivo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <iframe src={this.state.srcFileModal} width="100%" height="500"></iframe>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="contained" color="secondary" onClick={this.closeModal}>
                                Cerrar
                            </Button>
                            </Modal.Footer>
                    </Modal>
            <div className="d-flex justify-content-start">
                <Button
                    variant="contained"
                    fullWidth 
                    onClick={this.nuevaMortalidad}
                    startIcon={<SupervisorAccountIcon/>}
                    color="primary"
                > Nueva Mortalidad</Button>
            </div>
            <br></br>
                  <div className="card row">
                      <div className="card-header">Buscar</div>
                      <div className="card-body">
                        <div className="row">
                            <div className="col-md-2">
                            <label>Desde: </label>
                            <DatePicker
                                className="form-control"
                                selected={this.state.desde}
                                onChange={this.onDateChangeDesde}
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                            >
                            </DatePicker>
                            </div>
                            <div className="col-md-2">
                            <label>Hasta: </label>
                            <DatePicker
                                className="form-control"
                                selected={this.state.hasta}
                                onChange={this.onDateChangeHasta}
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                            >
                            </DatePicker>
                            </div>
                            <div className="col-md-3">
                                <label>Cliente: </label>
                                <Select
                                 defaultValue={this.state.selectedCliente}
                                 options={this.state.clientes}
                                 onChange={this.handleSelecCliente}
                                >
                                </Select>
                            </div>
                            <div className="col-md-2">
                                 <br></br>
                                <button type="button" onClick={this.getDatosMortalidad} className="btn btn-info">
                                    Consultar
                                </button>
                            </div>
                            <div className="col-md-2">
                                <br></br>
                                <button type="button" onClick={this.generarPdf.bind(this)} className="btn btn-danger">
                                <i className="fas fa-file-pdf"></i> Generar PDF
                                </button>
                            </div>
                        </div>
                      </div>
                  </div>
                <FilaInformativa columnas ={this.state.columnas}>
                </FilaInformativa>
                <div className="card row">
                    <div className="card-body">
                       
                            <BootstrapTable data={this.state.mortalidades} search={true} searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                                <TableHeaderColumn isKey dataField='idmortalidad' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:80}} thStyle={{fontSize: 11,width:80, backgroundColor: 'grey', color: 'white'}}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='fecha' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Fecha</TableHeaderColumn>
                                <TableHeaderColumn dataField='cliente' dataSort={ true } tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Cliente</TableHeaderColumn>
                                <TableHeaderColumn dataField='cantidad_mortalidad' dataFormat={this.formatearNumeros}  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Cantidad Aves Mortalidad</TableHeaderColumn>
                                <TableHeaderColumn dataField='precio_mortalidad'  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Precio Mortalidad</TableHeaderColumn>
                                <TableHeaderColumn dataField='monto_mortalidad' dataFormat={this.formatearNumeros}  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: 11,  backgroundColor: 'grey', color: 'white'}}>Monto Mortalidad</TableHeaderColumn>
                                <TableHeaderColumn dataField='monto_original' dataFormat={this.formatearNumeros} tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11,  backgroundColor: 'grey', color: 'white'}}>Monto Original Cliente</TableHeaderColumn>
                                <TableHeaderColumn dataField='nuevo_monto' dataFormat={this.formatearNumeros}  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Diferencia Monto</TableHeaderColumn>
                                <TableHeaderColumn dataField='path_documento'  dataFormat={this.fileFormatter}  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Archivo</TableHeaderColumn>
                                <TableHeaderColumn dataField='idmortalidad'  dataFormat={ this.deleteFormatter} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                    
                    </div>
                </div> 
        </div>
    }

}


const mapStateToProps = state => ({
    user : state.user
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type: 'LOGOUT'});
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(IndiceMortalidad);


