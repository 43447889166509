import axios from 'axios';
import URI_BACKEND from './confProyecto';
import store from './store'

const AxiosInstance = axios.create({
    baseURL : URI_BACKEND
});

AxiosInstance.interceptors.request.use(config => {
    if(config.url !== '/api/login'){
        let token = sessionStorage.getItem('token');
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
})

AxiosInstance.interceptors.response.use(function (response) {
    
   
    return response;
  }, function (error) {
     
     if(error.response.status===403){
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            alert('Sesion vencida, Inicie sesion nuevamente');
            store.dispatch({type : 'LOGOUT'});
     }
    return Promise.reject(error);
  });

export default AxiosInstance;
