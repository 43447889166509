function formatearFecha (dat, caso){
    if(typeof dat === 'object' && caso === "english"){
     let fechaLocal = dat.getFullYear()+'-'+((dat.getMonth()+1) < 10 ? '0'+ (dat.getMonth()+1): (dat.getMonth()+1)) + '-'+ (dat.getDate()< 10? '0'+ dat.getDate(): dat.getDate());
     return fechaLocal
  }
  
   if(typeof dat === 'object' && caso === "spanish"){
     let fechaLocal = (dat.getDate()< 10? '0'+ dat.getDate(): dat.getDate()) +'-'+((dat.getMonth()+1) < 10 ? '0'+ (dat.getMonth()+1): (dat.getMonth()+1)) + '-' + dat.getFullYear();
     return fechaLocal
  }
  
  if(typeof dat === "string"){
      let date = new Date(dat+"T00:00")
      let fechaLocal = (date.getDate()< 10? '0'+ date.getDate(): date.getDate()) +'-'+((date.getMonth()+ 1) < 10 ? '0'+ (date.getMonth()+1): (date.getMonth()+1)) + '-' + date.getFullYear();
      return fechaLocal
  }
}

const NumberFormat = new Intl.NumberFormat('en-US');

function formatearDigitos(numero){
        let redondeado = Number(numero).toFixed(2);
        let formateado = NumberFormat.format(Number(redondeado));
        return formateado;
}

export {formatearFecha, formatearDigitos};