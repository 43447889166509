import React, { Component } from "react"
import { TextField } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AxiosInstance from '../helpers';
import MaterialTable from 'material-table';
import Edit from '@material-ui/icons/Edit';
import tableIcons from '../TableIcons/tableIcons';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap';
import Select from 'react-select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 280,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

function PinnedSubheaderList(props) {
  const classes = useStyles();

  function handleChange(){
    alert('Gueno')
  }


  return (
    <List className={classes.root} subheader={<li />}>
      {props.submenus.map(submenu => (
       
              <ListItem key={submenu.idsubmenu}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={props.checkedsubmenus.find(e=>e.idsubmenu===submenu.idsubmenu)? props.checkedsubmenus.find(e=>e.idsubmenu===submenu.idsubmenu).checked : false}  value={submenu.idsubmenu} onChange={(e, checked) => {props.addSubmenu(e.target.value, checked) }} inputProps={{ 'aria-label': 'primary checkbox' }}/>
                                }
                                label={submenu.nombre_menu}
                            />
              </ListItem>
      ))}
    </List>
  );
}

export default class Perfil extends Component{
    constructor(props){
        super(props);

        this.state = {
            perfil : '',
            allModulosWithItems : [],
            allSubmenus : [],
            perfiles : [],
            modulos : [],
            openError : false,
            openSave : false,
            loading : false,
            errorMessage : '',
            succesMessage : '',
            selectedMenusWithItems : [],
            selectedperfil : 0,
            disabledButton : true,
            submenusToSave : [],
            selectedSubmenus : [],
            open : false,
            selectedModulo : null,
            columns : [{title : 'ID PERFIL', field: 'idperfil', type: 'numeric', defaultSort : 'asc'}, {title:'PERFIL', field: 'perfil'}, {title:'ACCESOS', render : rowData => <Checkbox color="green" disabled checked={rowData.withaccesos}/>},]
        }

        this.getdatasubmenus = this.getdatasubmenus.bind(this)
        this.savePerfil = this.savePerfil.bind(this)
        this.saveAsignation = this.saveAsignation.bind(this)
        this.handleSelectModulo = this.handleSelectModulo.bind(this)
        this.addSubmenu = this.addSubmenu.bind(this)
        this.getPerfiles = this.getPerfiles.bind(this)
        this.getSelectedMenusPerfil = this.getSelectedMenusPerfil.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
    }

    async componentDidMount(){
        await this.getPerfiles()
        await this.getdatasubmenus()
    }

    handleSelectModulo(selectedModulo){
        let selectedMenusWithItems = this.state.allModulosWithItems.find(modulo => modulo.idmodulos === selectedModulo.value).menus;

        this.setState({
            selectedMenusWithItems //CORRESPONDE AL ARREGLO DE MENUS Y SUS RESPECTIVOS SUBMENUS DEL MODULO SELECCIONADO EN EL SELECT
        })
    }

    async getdatasubmenus(){
        try{
            let allModulosWithItems = await AxiosInstance({
                method : 'get',
                url : '/api/getdatasubmenus'
            })

            let modulos = allModulosWithItems.data.map(modulo => ({value : modulo.idmodulos, label : modulo.modulo}))

            let allSubmenus = [];
            allModulosWithItems.data.forEach(modulo => {
                  modulo.menus.forEach(menu => {
                      menu.submenus.forEach(submenu => {
                                allSubmenus.push(submenu);
                      })
                  })
            })

            this.setState({
                allModulosWithItems : allModulosWithItems.data,
                modulos,
                allSubmenus
            })


        }catch(e){
            console.log(e)
        }
    }

    async getPerfiles(){
        try{
            let perfiles = await AxiosInstance({
                method : 'get',
                url : '/api/allperfiles'
            })

            this.setState({
                perfiles : perfiles.data.map(per => ({idperfil : per.idperfil, perfil : per.perfil, withaccesos : per.accesos.length > 0, accesos : per.accesos}))
            })
        }catch(e){

        }
    }


    async savePerfil(){
        this.setState({
            loading : true
        })

        if(this.state.perfil === ''){
            this.setState({
                openError : true,
                errorMessage : 'Debe colocar un nombre de perfil',
                loading : false,
            })
            return;
        }

        try{
            let savedPerfil = await AxiosInstance({
                method : 'post',
                url : '/api/savePerfil',
                data : {perfil : this.state.perfil}
            })

            if(savedPerfil.data.Message){
                this.setState({
                    openError : true,
                    errorMessage : savedPerfil.data.Message,
                    loading : false
                })
            }else{
                this.setState(prevState => ({
                    openSave : true,
                    loading : false,
                    perfil : '',
                    perfiles : [...prevState.perfiles, savedPerfil.data]
                }))
            }
            
        }catch(e){
            console.log(e)
        }
    }

    getSelectedMenusPerfil(id, perfil){
        

        let accesos = [...this.state.perfiles.find(e => e.idperfil === Number(id)).accesos];
        let copySubmenus = this.state.allSubmenus.map(submenu => ({idsubmenu : submenu.idsubmenu, checked : false}))

        accesos.forEach(acceso => {
            let pos = copySubmenus.findIndex(sub => sub.idsubmenu === acceso.idsubmenu)
            copySubmenus[pos].checked = true
        })

        this.setState({
            selectedSubmenus : copySubmenus,
            perfil,
            selectedperfil : id,
            open : true
        })
    }


    onCloseModal (){
        this.setState({
            selectedperfil : 0,
            perfil : '',
            selectedSubmenus : [],
            disabledButton : true,
            open : false
        })
    }


    
    saveAsignation(){
             this.setState({
                 open : false
             })

             let accesos = [...this.state.perfiles.find(e => e.idperfil === this.state.selectedperfil).accesos];

             let agregados = []
             let eliminados = []
        
             this.state.selectedSubmenus.forEach(e => {
                 if(!accesos.some(n => n.idsubmenu === e.idsubmenu) && e.checked){
                   agregados.push(e.idsubmenu)
                 }else{
                   for(let sub of accesos){
                    if (e.idsubmenu === sub.idsubmenu && !e.checked){
                        eliminados.push(e.idsubmenu)
                    }
                 }
                 }
             })

             

            
             AxiosInstance({
                 method : 'post',
                 url : '/api/asignate',
                 data : {idperfil : this.state.selectedperfil, agregados, eliminados}
             }).then(async res => {

               await this.getPerfiles();

                this.setState({
                    openSave : true,
                    selectedSubmenus : [],
                    succesMessage : "Cambios guardados"
                })
             }).catch(e => {
                 console.log(e)
             })
    }

    addSubmenu(value, checked){
        let other = this.state.selectedSubmenus.map(e => {
            if(e.idsubmenu === Number(value)){
              return {...e, checked}
            }else{
              return e
            }
        })
   
        this.setState({
            disabledButton : false,
            selectedSubmenus : other
        })       
    }

    render(){
        return(
        <div className="container">
            <Modal size="lg" show={this.state.open}>
                <Modal.Header closeButton>
                <Modal.Title>{this.state.perfil}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <label>MODULO: </label>
                            <Select onChange={this.handleSelectModulo}
                            options={this.state.modulos} 
                            ></Select>
                        </div>
                    </div>
                  <br></br>
                  <div className="row">
                            {
                              this.state.selectedMenusWithItems.map((submodulo, i) => (<div key={i} className="col-md-4">
                                     <h6 className="h6 bg-danger">{submodulo.menu}</h6>
                                       {
                                           <PinnedSubheaderList submenus={submodulo.submenus} addSubmenu={this.addSubmenu} checkedsubmenus={this.state.selectedSubmenus}></PinnedSubheaderList>
                                       }
                              </div>))
                               
                            }
                  </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" variant="contained" size="large" onClick={this.saveAsignation}>Guardar</Button>
                    <Button color="secondary" variant="contained" size="large" onClick={this.onCloseModal}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <Snackbar open={this.state.openError} autoHideDuration={3000} onClose={(e)=> {this.setState({openError : false})}}>
                    <Alert onClose={(e)=> {this.setState({open : false})}} severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openSave} autoHideDuration={3000} onClose={(e)=> {this.setState({openSave : false})}}>
                    <Alert onClose={(e)=> {this.setState({openSave : false})}} severity="success">
                        {this.state.succesMessage}
                    </Alert>
                </Snackbar>
                <br></br>
                <br></br>
            <div className="row justify-content-center">
                <div className="col-md-3">
                    <label>Perfil: </label>
                   <input type="text" id="perfil" className="form-control"  value={this.state.perfil} name="perfil" onChange={(e)=> this.setState({perfil : e.target.value})}></input>
                </div>
                <div className="col-md-3">
                    <br></br>
                    { !this.state.loading ? <Button color="primary" disabled={this.state.disabledButton} variant="contained" size="large" startIcon={<SaveIcon/>} onClick={this.savePerfil}> Guardar</Button> : <CircularProgress/>}
                </div>
               
            </div>
            <br></br>
            <div className="row justify-content-center">
            <div className="card col-md-5">
                    <h5 className="card-header">PERFILES</h5>
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div>
                                    <MaterialTable
                                    isLoading={false}
                                    columns ={this.state.columns}
                                    data={this.state.perfiles}
                                    icons={tableIcons}
                                    options={{
                                        exportButton: true,
                                        headerStyle:{fontSize: 10, padding: 5, zIndex: 0},
                                        cellStyle:{fontSize: 10, padding: 5},
                                        actionsColumnIndex: -1
                                    }}
                                    localization={
                                        {
                                            body: {
                                            emptyDataSourceMessage: 'Sin datos'
                                            },
                                            toolbar: {
                                            searchTooltip: 'Buscar...',
                                            searchPlaceholder: 'Buscar...',
                                            exportName: 'Exportar como CSV'
                                            },
                                            pagination: {
                                            labelRowsSelect: 'Filas',
                                            labelDisplayedRows: ' {from}-{to} de {count}',
                                            firstTooltip: 'Primera Página',
                                            previousTooltip: 'Página Anterior',
                                            nextTooltip: 'Siguiente Página',
                                            lastTooltip: 'Última Página'
                                            },
                                            header :{
                                                actions : 'PERMISOS'
                                            },
                                        }
                                    }
                                    actions={[
                                        {
                                        icon: () => <Edit/>,
                                        tooltip: 'Asignar Acciones',
                                        onClick: (event, rowData) => this.getSelectedMenusPerfil(rowData.idperfil, rowData.perfil)
                                        }
                                    ]}
                                >
                                </MaterialTable>
                            </div>
                        </div>
                        
                    </div>
            </div>
            </div>
            <div className="">

            </div>
        </div>
        )
    }
}

