import React, {Component} from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {registerLocale} from "react-datepicker";
import {Link} from 'react-router-dom'
import es from 'date-fns/locale/es';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import AxiosInstance from '../helpers'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {formatearFecha} from '../helpers/helperFuncs'
import {connect} from 'react-redux';
import FilaInformativa2 from '../helpers/FilaInformativa2'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Checkbox from '@material-ui/core/Checkbox';


import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



registerLocale('es', es)

const SwalAlert = withReactContent(Swal);

  const sizeTextBody = 11


class IndiceVentasOther extends Component{

 constructor(props){
     super(props);
    
     let desde = new Date();
     let hasta = new Date();
     hasta.setDate(hasta.getDate() + 7);
     
     this.state = {
         carga : false,
         modalIsOpen : false,
         srcModal : false,
         instanciaFormatter : new Intl.NumberFormat('en-US'),
         modalIsOpenFile: false,
         selectedDetails : [{
             idventa : 0,
             fechapro : new Date(),
             cantidad_venta : 0,
             peso_venta : 0,
             precio_unitario_venta : 0,
             precio_venta : 0,
             merma : 0
            }],
         itemsTabla : [], //Son los items de venta pero detallados
         selected: 1000000,
         allVentas : [], //Son todas las ventas junto con sus detalles y clientes y empleados
         selectedCliente : {value : null, label : "Todos"},
         clientes : [],
         ventasTabla : [], //Son los datos mas importantes por venta, que aparecera en la tabla;
         estado : null,
         desde,
         hasta,
         columnas: [{titulo: 'AVES', dato: 0, signo: '', icono:<i className="fas fa-kiwi-bird"></i>},
                    {titulo: 'PESO CON MERMA / SIN MERMA', dato: 0, signo: '', icono:<i className="fas fa-balance-scale"></i>},
                    {titulo: 'MONTO CON MERMA / SIN MERMA', dato: 0, signo: 'Q.', icono:<i className="far fa-money-bill-alt"></i>},
                    {titulo: 'VENTAS', dato: 0, signo: '', icono :<i className="fas fa-sort-amount-up"></i>}],
    }

    this.getAllVentas = this.getAllVentas.bind(this);
    this.onDateChangeDesde = this.onDateChangeDesde.bind(this);
    this.onDateChangeHasta= this.onDateChangeHasta.bind(this);
    this.updateFila = this.updateFila.bind(this);
    this.getClientes = this.getClientes.bind(this);
    this.generarPdf = this.generarPdf.bind(this);
 }
 componentDidMount(){
     this.getClientes();
     this.getAllVentas();
 }

 onDateChangeDesde(desde){
    this.setState({
        desde
    })
 }

 toggle = id => {
     console.log(id)
    this.setState({
        selected : id
    })
 }

 getClientes(){
    AxiosInstance({
        method : 'get',
        url : '/api/getclients',
    }).then(res => {
        let clientes = res.data.map(cli => ({value : cli.idcliente, label : cli.cliente}));
        clientes.push({value : null, label : 'Todos'});
        this.setState({
            clientes
        })

    }).catch(e => {
        console.log(e);
    })
 }


 updateFila(id){
    
    let goToVenta = {}; //Es el conjunto de datos que se enviaran al componente Ventas para su actualizacion

    let finded = this.state.allVentas.find(ve => ve.idventa === id);

    goToVenta.idTicketVentaGenerada = finded.idventa;
    goToVenta.srcModal = finded.path_documentos;
    goToVenta.fechahora_venta = finded.fechahora_venta;
    goToVenta.cancelada = finded.cancelada;
    goToVenta.selectedOptionEmpleado = { value: finded.idempleado, label: finded.empleado.nombre_empleado +' '+finded.empleado.apellido_empleado}
    goToVenta.selectedOptionCliente = {value : finded.idcliente, label: finded.cliente.cliente};
    goToVenta.porcentaje_descuento = finded.cliente.porcentaje_descuento;
    goToVenta.selectedOptionTipoPago = { value: finded.idtipopago, label: finded.tipos_pago.tipopago};
    goToVenta.filasItem = this.state.itemsTabla.filter(venta => venta.idventa === id);



    this.props.history.push({
        pathname : '/nuevaventa',
        data : goToVenta
    });
 }


 onDateChangeHasta(hasta){
    this.setState({
        hasta
    })
}

 getAllVentas(){
   
    let searchParams = {
        desde : formatearFecha(this.state.desde, "english"),
        hasta : formatearFecha(this.state.hasta, "english"),
    }

    if(this.state.selectedCliente.value){
        searchParams = {...searchParams, idcliente : this.state.selectedCliente.value};
    }

    if(this.state.estado !== null){
        searchParams = {...searchParams, estado : this.state.estado};
    }

    AxiosInstance({
        method : 'get',
        url : '/api/allVentas',
        params : searchParams
    })
    .then(ventas => {

        let itemsTabla = [];
        let itemsVenta = [];
        let fila = {};
        let filaVenta = {};

        ventas.data.ventas.forEach(ve => {

            filaVenta = {}; //Cada fila corresponde a una venta

            filaVenta.idventa = ve.idventa;
            filaVenta.cliente = ve.cliente.cliente;
            filaVenta.path_documentos = ve.path_documentos;
            filaVenta.cantidad_venta = Number(ve.cantidad_aves_total);
            filaVenta.peso_venta = Number(ve.peso_aves_total);
            filaVenta.precio_venta = Number(ve.precio_venta_total);
            filaVenta.montoConMerma = Number(ve.precio_venta_total_merma); //Ya estan mermados o se les resto la merma
            filaVenta.pesoConMerma = Number(ve.peso_aves_total_merma); //Ya estan mermados o se les resto la merma
            filaVenta.cancelada = ve.cancelada;
            filaVenta.peso_ventaF = ve.peso_aves_totalF;
            filaVenta.precio_ventaF = ve.precio_venta_totalF;
            filaVenta.cantidad_ventaF = ve.cantidad_aves_totalF;
            filaVenta.montoConMermaF = ve.precio_venta_total_mermaF;
            filaVenta.pesoConMermaF = ve.peso_aves_total_mermaF;

            ve.ventas_detalles.forEach(det => {   //Cada item es un detalle de venta
               
                fila.fecha_procedencia_compras =  det.fecha_procedencia_compras !== null && det.fecha_procedencia_compras !== "null"? det.fecha_procedencia_compras : formatearFecha(new Date(), "english"); //fecha procedencia desde para fines de programacion
                fila.fechapro = det.fecha_procedencia_compras !== null && det.fecha_procedencia_compras !== "null"?  formatearFecha(det.fecha_procedencia_compras) : formatearFecha(new Date(), "spanish");//fecha procedencia que es solo para mostrar en español;
                fila.fecha_procedencia_compras_hasta = det.fecha_procedencia_compras_hasta !== null &&  det.fecha_procedencia_compras_hasta !== "null"? det.fecha_procedencia_compras_hasta :  formatearFecha(new Date(), "english"); //fecha procedencia hasta para fines de programacion
                fila.fechaprohasta = det.fecha_procedencia_compras_hasta !== null &&  det.fecha_procedencia_compras_hasta !== "null"?  formatearFecha(det.fecha_procedencia_compras_hasta) : formatearFecha(new Date(), "spanish"); //fecha pro solo para mostrar al usuario
                fila.iddetalleventa = det.iddetalleventa;
                fila.idventa = ve.idventa;
                fila.cliente = ve.cliente.cliente;
                fila.fechahora_venta = ve.fechahora_venta;  //fecha para fines de programacion
                fila.fechaventa = formatearFecha(ve.fechahora_venta)//fecha solo para mostrar al usuario en español;
                fila.cantidad_venta = det.cantidad_venta;
                fila.peso_venta = det.peso_venta;
                fila.peso_ventaMermado = (Number(det.peso_venta) -  Number(det.peso_venta)*(Number(det.porcentaje_merma)/100)).toFixed(2);
                fila.precio_unitario_venta = det.precio_unitario_venta;
                fila.porcentaje_merma = det.porcentaje_merma; //
                fila.merma = ((Number(det.precio_unitario_venta) * Number(det.peso_venta))*(Number(det.porcentaje_merma)/100)); //Math.round(((Number(det.precio_unitario_venta) * Number(det.peso_venta))*(Number(ve.cliente.porcentaje_descuento)/100))*100)/100;
                fila.mermaD = fila.merma.toFixed(2);
                fila.precio_venta = (Number(det.precio_venta) + ((Number(det.precio_unitario_venta) * Number(det.peso_venta))*(Number(det.porcentaje_merma)/100))).toFixed(2);
                fila.precio_ventaMermado = Number(det.precio_venta).toFixed(2);
                fila.observaciones_venta = det.observaciones_venta;
                fila.cancelada = ve.cancelada ? "CANCELADA" : "PENDIENTE";

                itemsTabla.push(fila);
                fila = {};
            });

            filaVenta.fechaventa = formatearFecha(ve.fechahora_venta);
            itemsVenta.push(filaVenta);

           
        })

        let formateador = new Intl.NumberFormat('en-US');
        
        let totalAves =         formateador.format(Number(ventas.data.totalAves));
        let totalPeso =         formateador.format(Number(ventas.data.totalPeso));
        let totalPrecio =       formateador.format(Number(ventas.data.totalPrecio));
        let totalPesoMerma =    formateador.format(Number(ventas.data.totalPesoMerma));
        let totalPrecioMerma =  formateador.format(Number(ventas.data.totalPrecioMerma));



        this.setState({
            columnas : [{titulo: 'AVES', dato: totalAves, signo: '', icono:<i className="fas fa-kiwi-bird"></i>},
                        {titulo: 'PESO SIN MERMA / CON MERMA', dato: `${totalPeso} /  ${totalPesoMerma}`, signo: '', icono:<i className="fas fa-balance-scale"></i>},
                        {titulo: 'MONTO SIN MERMA  /  CON MERMA', dato: `${totalPrecio}  /  ${totalPrecioMerma}`, signo: 'Q.', icono:<i className="far fa-money-bill-alt"></i>},
                        {titulo: 'VENTAS', dato: itemsVenta.length, signo: '', icono:<i className="fas fa-sort-amount-up"></i>}
                    ],
            allVentas  : ventas.data.ventas,
            ventasTabla : itemsVenta,
            itemsTabla
        })
    })
 }


 generarPdf(){

    let rows = this.state.ventasTabla.map(venta => [venta.idventa, venta.cliente, venta.fechaventa, venta.cantidad_ventaF, 
        venta.peso_ventaF, venta.pesoConMermaF, venta.precio_ventaF, venta.montoConMermaF ,venta.cancelada ? 'Cancelada' : 'Pendiente']);


    let fecha_desde = formatearFecha(this.state.desde, "spanish")
    let fecha_hasta = formatearFecha(this.state.hasta, "spanish")
      
    let fila = this.state.columnas.reduce((prev, current)=> [...prev, current.dato], []);

    let estado = this.state.estado === true ? 'Canceladas' : (this.state.estado === false) ? 'Pendientes' : 'Todos';
    

    var doc = new jsPDF('p', 'pt');
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      doc.text("AVICOLA CANIZ", 20, 50);

      doc.setFontSize(12);
      doc.setTextColor(22);
      doc.setFontStyle('normal');
      doc.text(`Rango de fechas: ${fecha_desde} al ${fecha_hasta} `, 20, 70);

      doc.setFontSize(12);
      doc.setTextColor(22);
      doc.setFontStyle('normal');
      doc.text(`Cliente: ${this.state.selectedCliente.label}`, 20, 90);

      doc.setFontSize(12);
      doc.setTextColor(22);
      doc.setFontStyle('normal');
      doc.text(`Estado: ${estado}`, 20, 110);

     

      doc.autoTable({
          head : [['AVES', 'PESO SIN MERMA / CON MERMA', 'MONTO SIN MERMA / CON MERMA', 'VENTAS']],
          body : [fila],
          startY: 122,
          margin: { horizontal: 20 },
          bodyStyles: { valign: 'top', halign: 'center' },
          styles: { overflow: 'linebreak' },
          theme: "striped"
      })
      
      doc.autoTable({
        head: [['Venta', 'Cliente', 'Fecha Venta', 'Cantidad Venta', 'Peso', 'Peso Con Merma', 'Monto', 'Monto Con Merma', 'Estado']],
        body: rows.sort((a, b) => a[0] - b[0]),
        startY: 170,
        margin: { horizontal: 10 },
        styles: { overflow: 'linebreak' },
        headerStyles : { fontSize: 8},
        columnStyles : {    0 : {fontSize : 6},
                            1 : {fontSize : 6},
                            2 : {fontSize : 6},
                            3 : {fontSize : 6},
                            4 : {fontSize : 6},
                            5 : {fontSize : 6},
                            6 : {fontSize : 6},
                            7 : {fontSize : 6},
                            8 : {fontSize : 6}
        },
        bodyStyles: { valign: 'top'},
        theme: "striped"
      });

       let date = new Date();

       let ano = date.getFullYear();
       let mes = (date.getMonth()+1) < 10? `0${date.getMonth()+1}` : date.getMonth()+1;
       let dia = date.getDate() < 10? `0${date.getDate()}`: date.getDate();
       let hora = date.getHours();
       let min = date.getMinutes();

       let fechayhora = `${dia}/${mes}/${ano} -- ${hora}:${min}`;
      
       let str = `Generado por: ${this.props.user}       Fecha: ${fechayhora}`
      
        doc.setFontSize(10);
        doc.text(str, 50, doc.internal.pageSize.height - 15);

      doc.save('Ventas.pdf');
}

verFile = (row)=>{
    AxiosInstance({
        method : 'post',
        url : '/api/getFileMortalidad',
        data : {filename : row.path_documentos},
        responseType: "blob"
    }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], {
            type: "application/pdf"
            })); 
            this.setState({
                srcModal : url,
                modalIsOpenFile : true
            })
    })
}

   fileFormatter = (cell, row) =>{
        return <Button disabled={row.path_documentos === null} onClick={this.verFile.bind(this, row)} color={row.path_documentos? "primary" : "secondary"}><i className="fa fa-file"></i></Button>
    }

  verDetalleVenta = (cell)=>{
     let detalles = this.state.itemsTabla.filter(item => item.idventa === cell);
     this.setState({
         selectedDetails : detalles,
     }, ()=>{
         this.setState({
             modalIsOpen : true
         })
     })
  }

  closeModal = ()=>{
      this.setState({
          modalIsOpen : false,
          modalIsOpenFile : false,
          selectedDetails : []
      })
  }

 editFormatter = (cell) => {
    return <button className="btn btn-info btn-sm" onClick={this.updateFila.bind(this, cell)}>Editar</button>
  }

  detailsFormatter = (cell) => {
    return <button className="btn btn-info btn-sm" onClick={this.verDetalleVenta.bind(this, cell)}>Detalles</button>
  }

  cancelFormatter = (cell, row) => {
      return <Checkbox disabled checked={cell}/>
  }

  formatearNumeros = (cell, row)=> {
    let formateado = this.state.instanciaFormatter.format(Number(cell));
    return formateado;
 }


  retornarTabla = (data) => (
    <BootstrapTable data={data} search={ true } searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
        <TableHeaderColumn isKey dataField='idventa' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:60}} thStyle={{fontSize: 11,width:60, backgroundColor: 'grey', color: 'white'}}>Id</TableHeaderColumn>
        <TableHeaderColumn dataField='cliente' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:150}} thStyle={{fontSize: 11, width:150, backgroundColor: 'grey', color: 'white'}}>Cliente</TableHeaderColumn>
        <TableHeaderColumn dataField='fechaventa' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Fecha Venta</TableHeaderColumn>
        <TableHeaderColumn dataField='cantidad_venta' dataFormat= {this.formatearNumeros}  dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Cantidad Vendida</TableHeaderColumn>
        <TableHeaderColumn dataField='peso_venta'     dataFormat= {this.formatearNumeros}  dataSort={ true }  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: 11,  backgroundColor: 'grey', color: 'white'}}>Peso Total</TableHeaderColumn>
        <TableHeaderColumn dataField='pesoConMerma'   dataFormat= {this.formatearNumeros}  dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Peso Con Merma</TableHeaderColumn>
        <TableHeaderColumn dataField='precio_venta'   dataFormat= {this.formatearNumeros}  dataSort={ true }   tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Monto Total</TableHeaderColumn>
        <TableHeaderColumn dataField='montoConMerma'  dataFormat= {this.formatearNumeros}  dataSort={ true }   tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Monto Con Merma</TableHeaderColumn>
        <TableHeaderColumn dataField='cancelada'      dataFormat={ this.cancelFormatter}  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 10, backgroundColor: 'grey', color: 'white'}}>CANCELADA</TableHeaderColumn>
        <TableHeaderColumn dataField='idventa'        dataFormat={ this.detailsFormatter}  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 10, backgroundColor: 'grey', color: 'white'}}>Detalles</TableHeaderColumn>
        <TableHeaderColumn dataField='idventa'        dataFormat={ this.editFormatter} tdStyle={{width:80}} thStyle={{fontSize: 11, width:80,backgroundColor: 'grey', color: 'white'}}>Editar</TableHeaderColumn>
        <TableHeaderColumn dataField='idventa'        dataFormat={ this.fileFormatter} tdStyle={{width:80}} thStyle={{fontSize: 11, width:80,backgroundColor: 'grey', color: 'white'}}>Archivo</TableHeaderColumn>
   </BootstrapTable>
  )


 render(){

     return(
         <div className="container">
                    <Modal size="lg" show={this.state.modalIsOpenFile}>
                            <Modal.Header>
                            <Modal.Title>Visualicación de archivo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <iframe src={this.state.srcModal} width="100%" height="500"></iframe>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="contained" color="secondary" onClick={this.closeModal}>
                                Cerrar
                            </Button>
                            </Modal.Footer>
                    </Modal>
                    <Modal size="xl" show={this.state.modalIsOpen}>
                        <Modal.Header>
                        <Modal.Title>Detalle de la Venta</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <TableContainer component={Paper}>
                                    <Table size="large" aria-label="a dense table">
                                        <TableHead>
                                        <TableRow className={{backgroundColor : 'lightblue', color: 'white'}}>
                                            <TableCell>Id</TableCell>
                                            <TableCell align="right"><strong>Fecha Consulta</strong></TableCell>
                                            <TableCell align="right"><strong>Aves</strong></TableCell>
                                            <TableCell align="right"><strong>Peso</strong></TableCell>
                                            <TableCell align="right"><strong>Peso Con Merma</strong></TableCell>
                                            <TableCell align="right"><strong>Precio Unitario</strong></TableCell>
                                            <TableCell align="right"><strong>Monto</strong></TableCell>
                                            <TableCell align="right"><strong>Monto Con Merma</strong></TableCell>
                                            <TableCell align="right"><strong>% Merma</strong></TableCell>
                                            <TableCell align="right"><strong>Merma</strong></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.selectedDetails.map((row, i) => (
                                            <TableRow key={`n${i}`}>
                                                    <TableCell component="th" scope="row">{row.idventa}</TableCell>
                                                    <TableCell align="right">{row.fechapro}<strong>||</strong>{row.fechaprohasta}</TableCell>
                                                    <TableCell align="right">{this.formatearNumeros(Number(row.cantidad_venta), null)}</TableCell>
                                                    <TableCell align="right">{this.formatearNumeros(Number(row.peso_venta), null)}</TableCell>
                                                    <TableCell align="right">{this.formatearNumeros(row.peso_ventaMermado, null)}</TableCell>
                                                    <TableCell align="right">{this.formatearNumeros(Number(row.precio_unitario_venta), null)}</TableCell>
                                                    <TableCell align="right">{this.formatearNumeros(Number(row.precio_venta), null)}</TableCell>
                                                    <TableCell align="right">{this.formatearNumeros(row.precio_ventaMermado, null)}</TableCell>
                                                    <TableCell align="right">{row.porcentaje_merma}</TableCell>
                                                    <TableCell align="right">{this.formatearNumeros(Number(row.mermaD), null)}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                        
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="outlined" color="secondary" onClick={this.closeModal}>
                            Cerrar
                        </Button>
                        </Modal.Footer>
                    </Modal>
             <br></br>
             <div className="row">
                 <div className="col-md-3">
                 <Link to="/nuevaventa" className="btn btn-success"><i className="fa fa-plus"></i> Nueva Venta</Link>
                 </div>
             </div>
             <br></br>
                  <div className="container">
                  <FilaInformativa2 columnas ={this.state.columnas}>
                    </FilaInformativa2>
                    <br></br>
                  <div className="card row">
                      <h5 className="card-header bg-"><i className="fas fa-search"></i> FILTROS DE BUSQUEDA</h5>
                      <div className="card-body">
                        <div className="row">
                            <div className="col-md-2">
                            <label>Desde: </label>
                            <DatePicker
                                className="form-control"
                                selected={this.state.desde}
                                onChange={this.onDateChangeDesde}
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                            >
                            </DatePicker>
                            </div>
                            <div className="col-md-2">
                            <label>Hasta: </label>
                            <DatePicker
                                className="form-control"
                                selected={this.state.hasta}
                                onChange={this.onDateChangeHasta}
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                            >
                            </DatePicker>
                            </div>
                            <div className="col-md-2">
                                <label>Cliente: </label>
                                <Select
                                 defaultValue={this.state.selectedCliente}
                                 options={this.state.clientes}
                                 onChange={(e)=>{this.setState({selectedCliente:e})}}
                                >
                                </Select>
                            </div>
                            <div className="col-md-2">
                               <label>Estado: </label>
                                <Select
                                 defaultValue={{value : null, label : 'Todos'}}
                                 onChange={(e)=> {this.setState({estado : e.value})}}
                                 options={[{value : true, label : 'Canceladas'}, {value : false, label : 'Pendientes'}, {value : null, label : 'Todos'}]}
                                >
                                </Select>
                            </div>
                            
                            <div className="col-md-2">
                                <br></br>
                                <button variant="contained" className="btn btn-success" onClick={this.getAllVentas}><i className="fas fa-search"></i> Consultar</button>
                            </div>
                            <div className="col-md-2">
                                <br></br>
                                <button variant="contained" className="btn btn-info" onClick={this.generarPdf}> <i className="fas fa-file-pdf"></i> Generar PDF</button>
                            </div>
                        </div>
                      </div>
                  </div>
                  </div>
             <br></br>
             <div className="col-md-12">
             <div className="card row" style={{padding: 10}}>
                   { this.retornarTabla(this.state.ventasTabla) }
                </div>
                </div>
         </div>
     )
 }



}

const mapStateToProps = state => ({
    user : state.user
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type: 'LOGOUT'});
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(IndiceVentasOther);