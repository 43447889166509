import React, {Component} from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import "../styles/ventas.css"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import MaterialTable from 'material-table'
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import tableIcons from '../TableIcons/tableIcons'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, Button} from 'react-bootstrap';
import AxiosInstance from '../helpers';
import {formatearFecha, formatearDigitos} from '../helpers/helperFuncs'
import {connect} from 'react-redux'

registerLocale('es', es)

const SwalAlert = withReactContent(Swal);

let ConsultaCompra = (props)=>{
    let compra = props.compra;

    return (
    <div className="card">
        <h5 className="card-header bg-defaut"><i className="fas fa-search"></i> Consulta Compra</h5>
        <div className="card-body">
        <div className="row">
            <div className="col-md-3">
                <label>Fecha de Compra (Desde)</label>
                <DatePicker
                                      className="form-control"
                                      selected={props.date_consulta}
                                      onChange={props.onDateConsultaChange}
                                      disabled={!props.canChangeCompra}
                                      dateFormat="dd/MM/yyyy"
                                      locale="es"
                                    >
                                    </DatePicker>
            </div>
            <div className="col-md-3">
                <label>Fecha de Compra (Hasta)</label>
                <DatePicker
                                      className="form-control"
                                      selected={props.date_consulta_hasta}
                                      onChange={props.onDateConsultaChangeHasta}
                                      disabled={!props.canChangeCompra}
                                      dateFormat="dd/MM/yyyy"
                                      locale="es"
                                    >
                                    </DatePicker>
            </div>
            <div className="col-md-3">
                <br></br>
                <button disabled={!props.canChangeCompra} className="btn btn-success" onClick={props.consultarCompra}>Consultar</button>
            </div>
        </div>
        <br></br>
          <div className="row">
            <table className="table table-bordered table-hover table-sm">
                <thead>
                    <tr className="bg-info">
                        <th>No. Compras Realizadas</th>
                        <th>CANTIDAD COMPRA</th>
                        <th>PESO COMPRA</th>
                        <th>PRECIO PROMEDIO COMPRA Q.</th>
                        <th>MONTO Q.</th>
                        <th>SALDO AVES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> {compra.cantidad_tickets} tickets</td>
                        <td> {formatearDigitos(compra.cantidad_compra)}</td>
                        <td> {formatearDigitos(compra.peso_compra)}</td>
                        <td> {compra.promedio_precio}</td>
                        <td> {formatearDigitos(compra.montoConMerma)}</td>
                        <td> {formatearDigitos(compra.saldo)}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>)
}


let DetalleVenta = (props) => {
    return(
    <div className="card">
        <h5 className="card-header bg-default"><i className="fas fa-ticket-alt"></i> Ticket de venta # {props.idventa}</h5>
        <div className="card-body">
            <div className="row">
                <div className="col-md-3">
                    <label>Cantidad venta</label>
                    <input type="number" min="0"  onChange={props.onInputChange} name="cantidad_venta" className="form-control" value={props.cantidad_venta}/>
                    {props.errorCantidad ? <p className="text-danger">Nuevo Item: Se pasa del saldo. Actualiza: Debe colocar una cantidad menor a la anterior</p> : null}
                </div>
                <div className="col-md-3">
                    <label>Peso Venta</label>
                    <input type="number" min="0"  onChange={(e) => {props.onInputChange(e)}} name="peso_venta" className="form-control" value={props.peso_venta}/>
                </div>
                <div className="col-md-2">
                    <label>Precio Venta</label>
                    <input type="number" min="0"  onChange={(e) => {props.onInputChange(e)}} name="precio_unitario_venta" className="form-control" value={props.precio_unitario_venta}/>
                </div>
                <div className="col-md-2">
                    <label>% Merma</label>
                    <input type="number" min={0} onChange={(e)=> props.onInputChange(e)}  name="porcentaje_descuento" value={props.porcentaje_descuento} className="form-control" />
                </div>
                <div className="col-md-2">
                    <label>Merma</label>
                    <input type="number"   readOnly name="merma" value={props.merma} className="form-control" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                   <label>Observaciones</label>
                    <textarea name="observaciones_venta" className="form-control" onChange={(e)=> props.onInputChange(e)} rows="5" cols="20" value={props.observaciones_venta}></textarea>
                </div>
                <div className="col-md-4">
                    <br></br>
                    <br></br>
                    <button disabled={props.errorCantidad || !props.canChangeCompra} className="btn btn-success" onClick={props.addOrUpdateItem}><i className="fa fa-plus"></i> Agregar</button>
                    <br></br>
                    <br></br>
                    {
                       !props.canChangeCompra ? <div><button disabled={props.errorCantidad} className="btn btn-info" onClick={props.addOrUpdateItem}><i className="fa fa-edit"></i> Actualizar</button> <button className="btn btn-danger" onClick={props.cancelUpdate}>Cancelar</button></div> : null
                    }
                </div>
            </div>
        </div>
    </div>)
}


class Venta extends Component {


    constructor(props){
        super(props);

        this.checkField = React.createRef();
        this.state = {

            idTicketCompra         : 0,
            idTicketVentaGenerada  : 0,  /*Este campo cambia al reutilizar componente en edicion*/
            carga                  : false,
            srcModal               : '', /*Es la url al archivo de venta para mostrarse en modal. Este campo cambia al reutilizar componente en edicion*/

            canChangeCompra        : true,
            compraConsultada       : {
                //idcompra : 0,
                cantidad_tickets : 0,
                cantidad_compra : 0,
                peso_compra : 0,
                promedio_precio:0,
                montoConMerma : 0,
                precio_compra : 0,
                saldo :  0,
                /*proveedore: {
                    proveedor_proveedores : "----------"
                }*/
            },

            porcentaje_descuento   : 0,
            idDetalleAActualizar   : 0,

            idempleado            : 0,
            idtipopago            : 0,
            idcliente             : 0,
            fechahora_venta       : new Date(), /*Este campo cambia al reutilizar componente en edicion*/
            cancelada             : false,  /*Este campo cambia al reutilizar componente en edicion*/
            modalIsOpen           : false,
            textoFile             : 'Sin archivo',
            claseFile             : 'danger',
            clientes              : [],
            selected              : 100000000,
            errorCantidad         : false,
            date_consulta         : new Date(),
            date_consulta_hasta   : new Date(),

            cantidad_venta          : 0,
            peso_venta              : 0,
            precio_unitario_venta   : 0,
            merma                   : 0,
            fileVenta               : null,
            observaciones_venta     : '',
            disabled                : true,

            selectedOptionEmpleado : null, /*Este campo cambia al reutilizar componente en edicion*/
            selectedOptionCliente  : null, /*Este campo cambia al reutilizar componente en edicion*/
            selectedOptionTipoPago : null, /*Este campo cambia al reutilizar componente en edicion*/

            optionsSelectEmpleado : [],
            optionsSelectCliente  : [],
            optionsSelectTipoPago : [],

            headers : [
                {
                    title: "",
                    render: rowData => {
                      return (
                        <input
                          type="radio"
                          name= "selected"
                          ref={this.checkField}
                          onChange={this.selectRowForUpdate.bind(this, rowData.tableData.id, rowData.iddetalleventa)}
                        />
                      );
                    },
                },
                { title : 'id', field: 'iddetalleventa', type: 'numeric', hidden : true},
                { title: 'Fecha Compra Desde', field: 'fecha_consulta'},
                { title: 'Fecha Consulta', field: 'fecha_procedencia_compras', hidden: true},
                { title: 'Fecha Compra Hasta', field: 'fecha_consulta_hasta'},
                { title: 'Fecha Consulta Hasta', field: 'fecha_procedencia_compras_hasta', hidden: true},
                { title: 'Ticket Venta - Cliente', field: 'idventa'},
                { title: 'Cantidad Venta', field: 'cantidad_venta'},
                { title: 'Peso Venta Num', field: 'peso_venta_num', hidden: true},
                { title: 'Peso Venta', field: 'peso_venta'},
                { title: 'Precio Unitario Venta', field: 'precio_unitario_venta', type: 'numeric'},
                { title: 'Merma', field: 'merma'},
                { title: 'Merma Num', field: 'merma_num', hidden : true},
                { title: 'Monto Total', field: 'precio_venta'},
                { title: 'Observaciones', field: 'observaciones_venta', hidden: true}
            ],
            filasItem : [  /*Este campo cambia al reutilizar componente en edicion*/
            ],

        }

            
        this.onInputChange = this.onInputChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.consultarCompra = this.consultarCompra.bind(this);
        this.saveVenta = this.saveVenta.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.addOrUpdateItem = this.addOrUpdateItem.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.deleteItemVenta = this.deleteItemVenta.bind(this);
        this.cancelUpdate = this.cancelUpdate.bind(this);
        this.updateEstadoGeneral = this.updateEstadoGeneral.bind(this);
        this.cleanDetalle = this.cleanDetalle.bind(this);
        this.onDateConsultaChange = this.onDateConsultaChange.bind(this);
        this.onDateConsultaChangeHasta = this.onDateConsultaChangeHasta.bind(this);

        this.refFile = React.createRef();

        if(this.props.location.data){  //Se verifica si se recibe datos para usar el componente con la funcion de edicion y no de creacion
            let data = this.props.location.data;
            
            this.state.idTicketVentaGenerada = data.idTicketVentaGenerada;
            this.state.srcModal = data.srcModal;
            this.state.fechahora_venta = new Date(data.fechahora_venta+'T00:00');
            this.state.cancelada = data.cancelada;
            this.state.selectedOptionCliente = data.selectedOptionCliente;
            this.state.selectedOptionEmpleado = data.selectedOptionEmpleado;
            this.state.selectedOptionTipoPago = data.selectedOptionTipoPago;
            //this.state.porcentaje_descuento = data.porcentaje_descuento;
            
            let nuevoItem = {};

             data.filasItem.forEach(item => {
                nuevoItem = {
                        fecha_consulta : item.fechapro,
                        fecha_consulta_hasta : item.fechaprohasta,
                        fecha_procedencia_compras : item.fecha_procedencia_compras,  //La fecha viene en forma de string
                        fecha_procedencia_compras_hasta : item.fecha_procedencia_compras_hasta, //La fecha viene en forma de string
                        iddetalleventa : item.iddetalleventa,
                        idventa: 'Ticket ' + data.idTicketVentaGenerada + ' - ' + data.selectedOptionCliente.label,
                        cantidad_venta: item.cantidad_venta,
                        peso_venta_num: item.peso_venta,
                        peso_venta:  '' + item.peso_venta + ' lbs',
                        precio_unitario_venta : item.precio_unitario_venta,
                        merma: 'Q.'+ item.merma,
                        merma_num: item.merma,
                        precio_venta: 'Q.' + item.precio_venta,
                        observaciones_venta : item.observaciones_venta,
                        porcentaje_merma : item.porcentaje_merma
                    }

                    this.state.filasItem.push(nuevoItem);
             });
            
        }

    }

    componentDidMount(){
        
        if(this.props.location.data){  /*Si le fue pasado al componente una venta para actualizar sus items */
            console.log('RECEIVED');
        }else{
            AxiosInstance({
                method : 'get',
                url : '/api/getDatosSelectVenta'
            })
             .then(datos => {
                    let empleados = [];
                    let clientes = [];
                    let tiposPago = [];

                    datos.data.empleados.forEach(emp => {
                        empleados.push({value: emp.idempleado, label: emp.nombre_empleado +' '+ emp.apellido_empleado});
                    });

                    datos.data.clientes.forEach(cli => {
                        clientes.push({value: cli.idcliente, label: cli.cliente});
                    });

                    datos.data.tiposPago.forEach(tipo => {
                        tiposPago.push({value: tipo.idtipopago, label: tipo.tipopago});
                    });
                    
                    
                    this.setState(prevState => ({
                        optionsSelectEmpleado : empleados,
                        optionsSelectCliente  : clientes,
                        optionsSelectTipoPago : tiposPago,
                        clientes              : datos.data.clientes
                    }))
                }).catch(e =>{
                    console.log(e);
                })
      }
    }


    verFile = ()=>{
        AxiosInstance({
            method : 'post',
            url : '/api/getFileMortalidad',
            data : {filename : this.state.srcModal},
            responseType: "blob"
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data], {
                type: "application/pdf"
                })); 
                this.setState({
                    srcModal : url,
                    modalIsOpen : true
                })
        })
    }

    openModal() {
        if(this.state.srcModal !== '' && this.state.srcModal !== null && this.state.srcModal !== undefined){
            if(this.state.idTicketVentaGenerada !== 0){
                this.verFile();
            }else{
                this.setState({
                    modalIsOpen : true
                })
            }
        }
      }
     
    afterOpenModal() {
    
    }
    
    closeModal() {
    this.setState({modalIsOpen: false});
    }


    
    selectRowForUpdate = (id, iddetalleventa) => {
    
        let filaDetalleSeleccionada =  this.state.filasItem.find(fila => fila.iddetalleventa === iddetalleventa);
        

        this.setState({
            selected                : id,
            cantidad_venta          : filaDetalleSeleccionada.cantidad_venta,
            peso_venta              : filaDetalleSeleccionada.peso_venta_num,
            merma                   : filaDetalleSeleccionada.merma_num,
            porcentaje_descuento    : filaDetalleSeleccionada.porcentaje_merma,
            precio_unitario_venta   : filaDetalleSeleccionada.precio_unitario_venta,
            observaciones_venta     : filaDetalleSeleccionada.observaciones_venta,
            idDetalleAActualizar    : iddetalleventa,
            canChangeCompra         : false,
            date_consulta           : new Date(filaDetalleSeleccionada.fecha_procedencia_compras+'T00:00'), //La fecha string se pasa a Date con hora cero
            date_consulta_hasta     : new Date(filaDetalleSeleccionada.fecha_procedencia_compras_hasta+'T00:00') //La fecha string se pasa a Date con hora cero
        }, ()=>{
            this.consultarCompra();
        });

    }

   

    cancelUpdate(){
        this.cleanDetalle();
    }

    saveVenta(e){
        e.preventDefault();

                const {selectedOptionCliente, selectedOptionEmpleado, selectedOptionTipoPago} = this.state;

                if(!selectedOptionCliente || !selectedOptionEmpleado || !selectedOptionTipoPago){
                    SwalAlert.fire({
                        title: 'Complete los campos de seleccion',
                        showClass: {
                            popup: 'animated fadeInDown faster'
                        },
                        hideClass: {
                            popup: 'animated fadeOutUp faster'
                        },
                        showConfirmButton: false,
                        timer: 2000
                        }).then(result => {
                            return;
                        });
                }


                let form = new FormData();
                
                form.append('idempleado', this.state.selectedOptionEmpleado.value);
                form.append('idtipopago', this.state.selectedOptionTipoPago.value);
                form.append('idcliente', this.state.selectedOptionCliente.value);
                form.append('fechahora_venta', formatearFecha(this.state.fechahora_venta, "english"));
                form.append('cancelada', this.state.cancelada);
                form.append('user', this.props.user)

                
                if (this.state.fileVenta !== null && this.state.fileVenta !== undefined){
                    form.append('fileVenta', this.state.fileVenta)
                }
                SwalAlert.fire({
                    title: 'Espere',
                    html: 'Guardando...',
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                      Swal.showLoading()
                    }
                })

                AxiosInstance({
                    method: 'post',
                    url : '/api/saveVenta',
                    data : form,
                    headers : {
                        'Content-Type' : 'multipart/form-data'
                    }  
                })
                .then(savedVenta => {
                    if(!savedVenta.data.MensajeError){
                        
                        SwalAlert.close();
                        this.setState({
                            idTicketVentaGenerada : savedVenta.data.idventa,
                            srcModal              : savedVenta.data.path_documentos !== null? savedVenta.data.path_documentos : ''
                        }, () => {
                            SwalAlert.fire({
                                position: 'top-center',
                                icon: 'success',
                                title: 'Guardado exitosamente',
                                showConfirmButton: false,
                                timer: 900
                            }).then(result => {
                                console.log(result);
                            })
                        });
                        
                    }
                }).catch(()=>{
                    console.log('error');
                })
        

      
    }

    updateEstadoGeneral(){
       
        AxiosInstance({
            method: 'post',
            url : '/api/updateEstadoGeneral',
            data : {estado : this.state.cancelada, idventa : this.state.idTicketVentaGenerada}
        })
        .then(res => {
            if(res.data.estado !== undefined){
                this.setState({
                    cancelada : res.data.estado
                }, ()=>{
                    SwalAlert.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: this.state.cancelada? 'CANCELADA' : 'NO CANCELADA',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(result => {
                        console.log('');
                    })
                })
            }
        })

    }

    onInputChange(e){
        let inputName = e.target.name;

        this.setState({
            [e.target.name] : e.target.type ==="checkbox"? e.target.checked : e.target.value /*(e.target.type ==="number"? (Number(e.target.value) < 0 ? '' : Number(e.target.value)) : e.target.value)*/
        }, ()=>{
            if(inputName === "cancelada"){
                if(this.idTicketVentaGenerada !== 0){
                    this.updateEstadoGeneral();
                }
            }
            if(inputName ==="cantidad_venta"){
            
                if (this.state.canChangeCompra){
                   if(this.state.cantidad_venta > Number(this.state.compraConsultada.saldo)){
                        this.setState({
                            errorCantidad : true
                        })
                   }else{
                    this.setState({
                        errorCantidad : false
                    })
                   }
                }/*else{
                        let cantidadVendidaAnterior = this.state.filasItem.find(det => det.iddetalleventa === this.state.idDetalleAActualizar).cantidad_venta
                       
                        if (cantidadVendidaAnterior < this.state.cantidad_venta && Number(this.state.compraConsultada.saldo) < this.state.cantidad_venta){
                            this.setState({
                                errorCantidad : true
                            })
                        }else{
                            this.setState({
                                errorCantidad : false
                            })
                        }                   
                } */
            }
            if(inputName === "precio_unitario_venta" || inputName === "peso_venta" || inputName === "porcentaje_descuento"){
                if(this.state.precio_unitario_venta > 0 && this.state.peso_venta > 0){
                    this.setState({
                        merma : ((this.state.precio_unitario_venta * this.state.peso_venta) * (this.state.porcentaje_descuento/100)).toFixed(2)
                    })
                }
            }
        })
    }

    onDateChange(fechahora_venta){
        this.setState({
            fechahora_venta
        })
    }

    onDateConsultaChange(date_consulta){
        this.setState({
            date_consulta
        })
    }

    onDateConsultaChangeHasta(date_consulta_hasta){
        this.setState({
            date_consulta_hasta
        })
    }

    handleFileChange(e){
        this.setState({
            fileVenta : e.target.files[0]
        }, ()=>{
           
            if(this.state.fileVenta !== null && this.state.fileVenta !== undefined){
                this.setState({
                    textoFile : 'Archivo cargado',
                    claseFile : 'success',
                    srcModal  : URL.createObjectURL(this.state.fileVenta)
                })
            }else{
                this.setState({
                    srcModal  : '',
                    textoFile : 'Sin archivo',
                    claseFile : 'danger'
                })
            }
        })
    }

    handleChangeCliente(selectedOptionCliente){
        this.setState(prevState => ({
            selectedOptionCliente,
            //porcentaje_descuento : Number(prevState.clientes.find(cli => cli.idcliente === selectedOptionCliente.value).porcentaje_descuento)
        }))
    }

    handleChange(name, selectedOption){
        this.setState(prevState => ({
            [name] : selectedOption
        }))
    }


    addOrUpdateItem(){

        if(this.state.date_consulta === undefined || this.state.cantidad_venta <= 0 || this.state.precio_unitario_venta <= 0 || this.state.peso_venta <= 0){
            SwalAlert.fire({
                    title: 'CAMPOS VACIOS O INVALIDOS',
                    showClass: {
                      popup: 'animated fadeInDown faster'
                    },
                    hideClass: {
                      popup: 'animated fadeOutUp faster'
                    },
                    showConfirmButton: false,
                    timer: 2000
                  }).then(result => {
                       return;
                  });
        }else{
  

            SwalAlert.fire({
                title: 'Espere',
                html: 'Guardando...',
                timerProgressBar: true,
                onBeforeOpen: () => {
                  Swal.showLoading()
                }
            })
           
            this.setState({
                carga : true
            })

            let fecha_procedencia_compras = formatearFecha(this.state.date_consulta, "english");
            let fecha_procedencia_compras_hasta = formatearFecha(this.state.date_consulta_hasta, "english");

            let data = {
                idventa                 : this.state.idTicketVentaGenerada,
                cantidad_venta          : Number(this.state.cantidad_venta),
                observaciones_venta     : this.state.observaciones_venta,
                peso_venta              : Number(this.state.peso_venta),
                precio_unitario_venta   : Number(this.state.precio_unitario_venta),
                merma                   : this.state.merma,
                fecha_procedencia_compras,
                fecha_procedencia_compras_hasta,
                porcentaje_merma        : this.state.porcentaje_descuento
            }
            
            let urlCrearOActualizar = '/api/saveDetalleVenta';
           
            if(!this.state.canChangeCompra && this.state.selected !== 100000000){
              
                urlCrearOActualizar = '/api/updateDetalleVenta';
                data.idDetalleAActualizar = this.state.idDetalleAActualizar;

            }

            AxiosInstance(
                {
                    method : 'post',
                    url : urlCrearOActualizar,
                    data : data
                }
            )
            .then(itemGenerado => {

                let item = itemGenerado.data.savedItem;

                let fecha_consultada = formatearFecha(item.fecha_procedencia_compras);
                let fecha_consultada_hasta = formatearFecha(item.fecha_procedencia_compras_hasta);

                let nuevoItem = {
                    fecha_consulta          : fecha_consultada,
                    fecha_consulta_hasta    : fecha_consultada_hasta,
                    iddetalleventa          : item.iddetalleventa,
                    fecha_procedencia_compras       : item.fecha_procedencia_compras,
                    fecha_procedencia_compras_hasta : item.fecha_procedencia_compras_hasta,
                    idventa                 : 'Ticket ' + this.state.idTicketVentaGenerada + ' - ' + this.state.selectedOptionCliente.label,
                    cantidad_venta          : item.cantidad_venta,
                    peso_venta              :  '' + item.peso_venta + ' lbs',
                    peso_venta_num          : item.peso_venta,
                    precio_unitario_venta   : item.precio_unitario_venta,
                    merma                   : 'Q.'+ this.state.merma,
                    merma_num               : this.state.merma,
                    precio_venta            : 'Q.' + item.precio_venta,
                    observaciones_venta     : item.observaciones_venta
                }

                this.setState(prevState => ({
                    filasItem : this.state.canChangeCompra ? [...prevState.filasItem, nuevoItem] : prevState.filasItem.filter(det => det.iddetalleventa !== itemGenerado.data.idDetalleAActualizar).concat([nuevoItem]),
                    carga                   : false,
                    cantidad_venta          : 0,
                    peso_venta              : 0,
                    precio_unitario_venta   : 0,
                    merma                   : 0,
                    porcentaje_descuento    : 0,
                    observaciones_venta     : '',
                    selected                : 100000000,
                    canChangeCompra         : true,
                    compraConsultada        : {...itemGenerado.data.updatedCompra}
                }))

                SwalAlert.close();

                document.getElementsByName('selected').forEach(input =>{
                    input.checked = false;
                })

                SwalAlert.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Guardado exitosamente',
                    showConfirmButton: false,
                    timer: 900
                }).then(result => {
                    console.log(result);
                });

            }).catch(e => {
                console.log(e);
            })
        }
    }

    cleanDetalle(){
        this.setState({
            cantidad_venta          : 0,
            peso_venta              : 0,
            precio_unitario_venta   : 0,
            merma                   : 0,
            observaciones_venta     : '',
            selected                : 100000000,
            canChangeCompra         : true,
            porcentaje_descuento    : 0,
            idDetalleAActualizar    : 0
        })
        document.getElementsByName('selected').forEach(input =>{
            input.checked = false;
        })
    }


    deleteItemVenta(iddetalleventa){

        let data = {
            iddetalleventa
        }

       
        AxiosInstance({
            method : 'post',
            url : '/api/deleteDetalleVenta',
            data : data
        })
        .then(updatedCompra =>{
            
            this.setState(prevState => ({
                filasItem           : this.state.filasItem.filter(item=> item.iddetalleventa !== updatedCompra.data.ideliminado),
                compraConsultada    : {...updatedCompra.data.updatedCompra},
                date_consulta       : new Date(updatedCompra.data.fecha_procedencia_compras+'T00:00'),
                date_consulta_hasta : new Date(updatedCompra.data.fecha_procedencia_compras_hasta+'T00:00')
            }))

        }).catch(e => {
            console.log(e);
        })


    }

    consultarCompra(){
        let fecha_consulta = formatearFecha(this.state.date_consulta, "english");
        let fecha_consulta_hasta = formatearFecha(this.state.date_consulta_hasta, "english");
        AxiosInstance({
            method : 'get',
            url : `/api/getTotalesPorFecha/${fecha_consulta}/${fecha_consulta_hasta}`,
        })
        .then(compras =>{
            this.setState({
                compraConsultada : {...compras.data}
            })
        })
        .catch(e =>
            {console.log(e)})
    }


    render(){
        let AreRequiredDateRelated = this.state.selectedOptionCliente && this.state.selectedOptionEmpleado && this.state.selectedOptionTipoPago; 
        let canShowFileModal = this.state.srcModal !== '' && this.state.srcModal !== null && this.state.srcModal !== undefined;
        let IsVentaSaved = this.state.idTicketVentaGenerada !== 0;

        //let merma = ((this.state.precio_unitario_venta * this.state.peso_venta) * (this.state.porcentaje_descuento/100)).toFixed(2)
     
        return (
            <div className="container">
                    <Modal size="lg" show={this.state.modalIsOpen} onHide={this.afterOpenModal} >
                        <Modal.Header closeButton>
                        <Modal.Title>Visualicación de archivo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <iframe src={this.state.srcModal} width="100%" height="500"></iframe>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Cerrar
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    <br></br>
                    <form className="card" onSubmit={this.saveVenta} encType="multipart/form-data">
                        <h4 className="card-header bg-default">
                        <i className="fas fa-shopping-cart"></i> CREACION DE VENTA
                        </h4>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Empleado</label>
                                    <Select
                                       isDisabled={IsVentaSaved}
                                       value={this.state.selectedOptionEmpleado}
                                       options={this.state.optionsSelectEmpleado}
                                       onChange ={this.handleChange.bind(this, 'selectedOptionEmpleado')}
                                    >
                                    </Select>
                                </div>
                                <div className="col-md-3">
                                    <label>Cliente</label>
                                    <Select
                                        isDisabled={IsVentaSaved}
                                        value={this.state.selectedOptionCliente}
                                        options={this.state.optionsSelectCliente}
                                        onChange={this.handleChangeCliente.bind(this)}
                                    >
                                        
                                    </Select>
                                </div>
                                <div className="col-md-2">
                                    <label>Tipo Pago</label>
                                    <Select
                                      isDisabled={IsVentaSaved}
                                      value={this.state.selectedOptionTipoPago}
                                      options={this.state.optionsSelectTipoPago}
                                      onChange={this.handleChange.bind(this,'selectedOptionTipoPago')}
                                    >
                                    </Select>
                                </div>
                                <div className="col-md-3">
                                    <label>Fecha Venta</label>
                                    <DatePicker
                                      className="form-control"
                                      selected={this.state.fechahora_venta}
                                      onChange={this.onDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      locale="es"
                                    >
                                    </DatePicker>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <input type="file" onChange={this.handleFileChange} ref={this.refFile}></input>
                                            <button type="button" disabled={IsVentaSaved} className="btn btn-info" onClick={(e)=> this.refFile.current.click()}><i className="far fa-file-pdf"></i> Cargar Archivo</button>
                                            <p className={'text-'+this.state.claseFile}>{this.state.textoFile}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <button disabled={!canShowFileModal} type="button" className="btn btn-info" onClick={this.openModal}><i className="far fa-eye"></i> Visualizar Archivo</button>
                                            {
                                                !canShowFileModal ? <p className= "text-danger">No puede visualizar</p> : <p className="text-success">Puede visualizar</p> 
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <button type="submit" disabled={!AreRequiredDateRelated || IsVentaSaved} className="btn btn-success"><i className="fa fa-save"></i> Crear Venta </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <br></br>
                    <div id="consultaCompra">
                        {
                                IsVentaSaved ? <ConsultaCompra canChangeCompra={this.state.canChangeCompra} date_consulta={this.state.date_consulta} date_consulta_hasta={this.state.date_consulta_hasta} compra={this.state.compraConsultada} onDateConsultaChange={this.onDateConsultaChange} onDateConsultaChangeHasta={this.onDateConsultaChangeHasta} consultarCompra={this.consultarCompra}></ConsultaCompra>: null
                        }
                    </div>
                    <br></br>
                    <div className="row">
                        <div id="detalleVenta" className="col-md-12">
                            {
                                IsVentaSaved ? <DetalleVenta cancelUpdate={this.cancelUpdate} updateItem={this.updateItem} canChangeCompra={this.state.canChangeCompra} errorCantidad={this.state.errorCantidad} precio_unitario_venta={this.state.precio_unitario_venta} peso_venta={this.state.peso_venta} cantidad_venta={this.state.cantidad_venta} observaciones_venta={this.state.observaciones_venta} idventa={this.state.idTicketVentaGenerada} merma= {this.state.merma} porcentaje_descuento={this.state.porcentaje_descuento}  onInputChange={this.onInputChange} addOrUpdateItem={this.addOrUpdateItem}></DetalleVenta> : null
                            }
                        </div>
                        <div className="col-md-12">
                            {
                                IsVentaSaved ? <div className="card"><h5 className="card-header"></h5><div className="card-body"> <MaterialTable
                                    isLoading={this.state.carga}
                                    icons={tableIcons}
                                    title='Datos'
                                    columns={this.state.headers}
                                    data={this.state.filasItem}
                                    options={{
                                        exportButton: true,
                                        headerStyle:{fontSize: 10, padding: 8, zIndex: 0},
                                        cellStyle:{fontSize: 10, padding: 8},
                                        actionsColumnIndex: -1,
                                        rowStyle: rowData => ({
                                            backgroundColor: (this.state.selected === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    localization={
                                        {
                                            body: {
                                            emptyDataSourceMessage: 'Sin datos'
                                            },
                                            toolbar: {
                                            searchTooltip: 'Buscar...',
                                            searchPlaceholder: 'Buscar...',
                                            exportName: 'Exportar como CSV'
                                            },
                                            pagination: {
                                            labelRowsSelect: 'Filas',
                                            labelDisplayedRows: ' {from}-{to} de {count}',
                                            firstTooltip: 'Primera Página',
                                            previousTooltip: 'Página Anterior',
                                            nextTooltip: 'Siguiente Página',
                                            lastTooltip: 'Última Página'
                                            },
                                            header :{
                                                actions : 'OPCIONES'
                                            },
                                        }
                                    }
                                    actions={[
                                        {
                                        icon: () => <DeleteOutline/>,
                                        tooltip: 'Eliminar Item',
                                        onClick: (event, rowData) => this.deleteItemVenta(rowData.iddetalleventa)
                                        }
                                    ]}
                                >
                                </MaterialTable></div></div> : null
                            }
                        
                        </div>
                    </div>
            </div>

        )
    }

}

const mapStateToProps = (state) => ({
    authenticated : state.authenticated,
    user : state.user,
    clicked : state.clicked,
    accesos : state.accesos,
    routesForComponent : state.routesForComponent,
    errorAuthentication : state.errorAuthentication
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();

        dispatch({type : 'LOGOUT'})
    }
})

export default  connect(mapStateToProps, mapDispatchToProps)(Venta);
