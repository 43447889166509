import 'date-fns';
import React, {useState, useEffect} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import deLocale from "date-fns/locale/es";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import AxiosInstance from '../helpers'
import FilaInformativa from '../helpers/FilaInformativa'
import {formatearFecha} from '../helpers/helperFuncs'

const formatearDigitos =  new Intl.NumberFormat('en-US');
const sizeTextBody = 11;

const IndicePagos = (props) =>{

    const [clientes, setClientes] = useState([]);
    const [tiposPago, setTiposPago] = useState([]);
    const [idcliente, setIdCliente] = useState(0);
    const [idtipopago, setIdTipoPago] = useState(0);
    const [fechadesde, setDesde] = useState(new Date());
    const [fechahasta, setHasta] = useState(new Date());
    const [abonos, setAbonos] = useState([]);
    const [disabled, setDisabled] = useState(false);

   const [columnas, setColumnas] = useState([{titulo: 'CLIENTES', dato: 0, signo: '', icono:<i className="fas fa-sort-numeric-up-alt"></i>},
                                            {titulo: 'TOTAL ABONOS', dato: 0, signo: 'Q.', icono:<i className="fas fa-sort-numeric-up-alt"></i>}])

    useEffect(()=>{

        let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 7);

        setHasta(currentDate);

        getDatos();
        getAbonos();

    }, [])

    //Validacion de fechas;
   useEffect(()=>{    
      if((fechadesde === null || fechadesde?.toString() === 'Invalid Date') || (fechahasta === null || fechahasta?.toString() === 'Invalid Date')){
          setDisabled(true);
      }else{
        setDisabled(false);
      }

   }, [fechadesde, fechahasta])

 
    const getDatos = ()=>{      
            AxiosInstance({
                method : 'get',
                url : '/api/getclientsanddocs'
            }).then(res=>{
                setClientes(res.data.all);
                setTiposPago(res.data.tipoPago);
            })
    }

    const getAbonos = ()=>{

        let parametros = {
            fechadesde : formatearFecha(fechadesde, "english"),
            fechahasta : formatearFecha(fechahasta, "english")
        }

        if(idcliente !== '0' && idcliente !== 0){
            parametros = {
                ...parametros,
                idcliente : idcliente
            }
        }

        if(idtipopago !== '0' && idtipopago !== 0){
            parametros = {
                ...parametros,
                idtipopago : idtipopago
            }
        }

        AxiosInstance({
            method : 'get',
            url : '/api/getabonos',
            params : parametros
        }).then(res=>{

            let listAbonos = res.data.abonolist.map(abono => ({idabono: abono.idabono, 
                                                               fecha_abono : abono.fecha_abono,
                                                               cliente: abono.cliente.cliente,
                                                               cantidad : abono.cantidad,
                                                               tipos_pago : abono.tipos_pago.tipopago,
                                                               numero_documento : abono.numero_documento
                                                            }))
            setAbonos(listAbonos);

            let items = formatearDigitos.format(res.data.abonolist?.length);
            let total = formatearDigitos.format(Number(res.data.total));

            console.log(res.data.abonolist);

            setColumnas(
                [{titulo: 'ITEMS', dato: items, signo: '', icono:<i className="fas fa-sort-numeric-up-alt"></i>},
                {titulo: 'TOTAL ABONOS', dato: total, signo: 'Q.', icono:<i className="fas fa-sort-numeric-up-alt"></i>}]
            )
            
        })

    }

    const handleDateDesde = (date, format)=>{
        setDesde(date);
    }

    const handleDateHasta = (date, format)=>{
        setHasta(date);
    }

    const fechaFormatter = (cell, row)=>{
        return formatearFecha(cell);
    }

    const formatearNumero = (cell)=>{
        return formatearDigitos.format(cell);
    }

    return(
        <div className="container">
            <FilaInformativa columnas ={columnas} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                            </div>
                            <div className="card-body"> 
                                <div className="row">
                                    <div className="col-md-3">
                                            <label htmlFor="cliente">Cliente:</label>
                                            <select className="form-control" value={idcliente} onChange={(e)=>setIdCliente(e.target.value)}>
                                                    <option value={0}>Todos</option>
                                                    {
                                                        clientes.map(cliente => <option key={`c${cliente.idcliente}`} value={cliente.idcliente}>{cliente.cliente}</option>)
                                                    }
                                            </select>
                                    </div>
                                    <div className="col-md-3">  {/*maxDate = {new Date()} maxDateMessage ="No puede superar hoy"*/}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}> 
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline-1"
                                                label="Desde"
                                                autoOk
                                                minDate ="01/01/2010"
                                                minDateMessage ="La fecha es inferior" 
                                                value={fechadesde}
                                                onChange={handleDateDesde}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage = "Fecha invalida" 
                                            />
                                         </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-3"> {/*maxDate = {new Date()} maxDateMessage ="No puede superar hoy"*/}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>  
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline-2"
                                                label="Hasta"
                                                autoOk
                                                minDate ="01/01/2010"
                                                minDateMessage ="La fecha es inferior" 
                                                value={fechahasta}
                                                onChange={handleDateHasta}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage = "Fecha invalida" 
                                            />
                                         </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-2">
                                            <label htmlFor="cliente">Forma de Pago:</label>
                                            <select className="form-control" value={idtipopago} onChange={(e)=>setIdTipoPago(e.target.value)}>
                                                    <option value={0}>Todos</option>
                                                    {
                                                        tiposPago.map(tipo => <option key={`o1${tipo.idtipopago}`} value={tipo.idtipopago}>{tipo.tipopago}</option>)
                                                    }
                                            </select>
                                    </div>
                                    <div className="col-md-1">
                                        <br></br>
                                        <button className="btn btn-info" disabled={disabled} onClick={getAbonos}><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card row">
                    <div className="card-body">
                            <BootstrapTable data={abonos} search={true} searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                                <TableHeaderColumn isKey dataField='idabono' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:80}} thStyle={{fontSize: 11,width:80, backgroundColor: 'grey', color: 'white'}}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='fecha_abono' dataSort={ true } dataFormat={fechaFormatter} tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Fecha</TableHeaderColumn>
                                <TableHeaderColumn dataField='cliente'   dataSort={ true } tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Cliente</TableHeaderColumn>
                                <TableHeaderColumn dataField='cantidad' dataFormat={formatearNumero}  dataSort={ true } tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Cantidad Abono</TableHeaderColumn>
                                <TableHeaderColumn dataField='tipos_pago'  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Forma de Pago</TableHeaderColumn>
                                <TableHeaderColumn dataField='numero_documento' tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: 11,  backgroundColor: 'grey', color: 'white'}}>No. Documento</TableHeaderColumn>
                            </BootstrapTable>
                    
                    </div>
                </div>
        </div>
    )

}

export default IndicePagos;