import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Modal from 'react-modal'
import Compra from './Compra'
import AxiosInstance from '../helpers'
import DatePicker from 'react-datepicker';
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import Select from 'react-select';
import jsPDF from 'jspdf';
import {formatearFecha} from '../helpers/helperFuncs'
import {connect} from 'react-redux';
import 'jspdf-autotable';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import FilaInformativa2 from '../helpers/FilaInformativa2';

registerLocale('es', es)

Modal.setAppElement('#root')  //Especificando el elemento raiz de la aplicacion sobre la que va a funcionar

const customStylesModal = {
    overlay: {
        backgroundColor: ' rgba(0,0,0,0.5)'
      },
    content : {
      padding               : '4px',
      top                   : '50%',
      left                  : '55%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-40%',
      zIndex                : '30000000',
      background            : '#fff',
      transform             : 'translate(-50%, -50%)',
      border                : '3px solid #ccc',
    }
  };

class IndiceCompra extends Component {

    constructor(props){
        super(props);

        let fechaDesde = new Date();
        let fechaHasta = new Date();
        fechaHasta.setDate(fechaHasta.getDate() + 7);


        this.state = {
            modalIsOpen: false,
            carga: true,
            fechaDesde,
            fechaHasta,
            instanciaFormatter : new Intl.NumberFormat('en-US'),
            selectedPro : {value: null, label: "Todos"},
            selectedRow: {},
            proveedores: [],
            compras : [
            ],
            comprasAPI:[],
            columnas : [
            ],
        }

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateCompra = this.updateCompra.bind(this);
        this.getAllComprasFromAPI= this.getAllComprasFromAPI.bind(this);
        this.changeDesde = this.changeDesde.bind(this);
        this.changeHasta =  this.changeHasta.bind(this);
        this.selecProveedor = this.selecProveedor.bind(this);
        this.consultar = this.consultar.bind(this);
        this.getProveedores = this.getProveedores.bind(this);
        this.generarPdf = this.generarPdf.bind(this);
    }

    componentDidMount(){
        this.getProveedores();
        this.consultar();
    }

    getProveedores(){
       AxiosInstance({
            method : 'get',
            url : '/api/getProveedores'
        }).then(array => {
            let todos = array.data.map(pro => ({value: pro.idproveedor, label: pro.proveedor_proveedores}));
            todos.push({value : null, label: 'Todos'});

            this.setState({
                proveedores : todos
            })
        }).catch(e=>{
            console.log(e)
        })
    }

    getAllComprasFromAPI(url='/api/getCompras'){

        AxiosInstance({
            method : 'get',
            url
        })
        .then(compras => {

            let { allCompras, totales} = compras.data;

            let totalCantidad = this.state.instanciaFormatter.format(Number(totales.cantidad_compra));
            let totalPeso =     this.state.instanciaFormatter.format(Number(totales.peso_compra));
            let totalPrecio =   this.state.instanciaFormatter.format(Number(totales.precio_compra));
            let montoConMerma = this.state.instanciaFormatter.format(Number(totales.precio_compra) - Number(totales.montoMermado));
            let pesoConMerma =  this.state.instanciaFormatter.format(Number(totales.peso_compra) - Number(totales.pesoMermado));

            let dataTable = [];
            
            allCompras.forEach((com, i) => {
                
                let pesoMerma = Number(com.peso_compra) - (Number(com.porcentaje_merma/100)* Number(com.peso_compra));
                let precioTotalMerma = Number(com.precio_compra) - (Number(com.porcentaje_merma/100)* Number(com.peso_compra)* Number(com.preciounitario_compra));
                dataTable.push({
                    idcompra        : com.idcompra, 
                    proveedor       : com.proveedore.proveedor_proveedores,
                    documento       : com.tipos_documento.tipo_documento + ' #' + com.numero_documento,
                    fecha_compra    : formatearFecha(com.fecha_compra),//fecha_compra,
                    cantidad_compra : com.cantidad_compra,
                    cantidad_compraF : this.state.instanciaFormatter.format(Number(com.cantidad_compra)),
                    peso_compra     : com.peso_compra,
                    peso_compraF    : this.state.instanciaFormatter.format(Number(com.peso_compra)),
                    peso_merma      : pesoMerma,
                    peso_mermaF     : this.state.instanciaFormatter.format(pesoMerma),
                    precio_unitario : com.preciounitario_compra,
                    precio_unitarioF : this.state.instanciaFormatter.format(Number(com.preciounitario_compra)),
                    precio_total_merma : precioTotalMerma,
                    precio_total_mermaF : this.state.instanciaFormatter.format(precioTotalMerma),
                    precio_total    : com.precio_compra,
                    precio_totalF   : this.state.instanciaFormatter.format(Number(com.precio_compra)),
                    porcentaje_merma : com.porcentaje_merma,
                    saldo           : com.saldo + '-' + (com.saldo === 0 ? 'CERRADO' : 'PENDIENTE'),
                    saldoF          : this.state.instanciaFormatter.format(Number(com.saldo))
                })
            })
            this.setState({
                comprasAPI: allCompras,
                compras: dataTable,
                columnas: [{key : 'aves', titulo: 'AVES', dato: totalCantidad, signo: '', icono:<i className="fas fa-kiwi-bird"></i>},
                           {key : 'peso',titulo: 'PESO SIN MERMA / CON MERMA', dato: `${totalPeso} / ${pesoConMerma}`, signo: '', icono:<i className="fas fa-balance-scale"></i>},
                           {key : 'monto',titulo: 'MONTO SIN MERMA / CON MERMA', dato: `${totalPrecio} / ${montoConMerma}`, signo: 'Q.', icono:<i className="far fa-money-bill-alt"></i>},
                           {key : 'compras',titulo: 'COMPRAS', dato: dataTable.length, signo : '', icono:<i className="fas fa-sort-amount-up"></i>}
                        ],
                carga: false
            })
        })
        .catch(err => console.log(err));
    }

    openModal() {
        this.setState({modalIsOpen: true});
      }
     
      afterOpenModal() {
       
      }
     
      closeModal() {
        this.setState({modalIsOpen: false});
      }

    updateCompra(id){
        this.state.comprasAPI.forEach(comp => {
            if(comp.idcompra === Number(id)){
                this.setState({
                    selectedRow: comp
            })
        }
        })
        this.openModal();
    }

    changeDesde(fechaDesde){
        this.setState({
            fechaDesde
        })
    }

    changeHasta(fechaHasta){
        this.setState({
            fechaHasta
        })
    }

    selecProveedor(selectedPro){
        this.setState({
            selectedPro
        })
    }

  
    consultar(){

        let urlWithParams = '/api/getCompras';

        let fechaDesde = formatearFecha(this.state.fechaDesde, "english")
        let fechaHasta = formatearFecha(this.state.fechaHasta, "english")
        
        urlWithParams =  urlWithParams + `?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`

        if (this.state.selectedPro){
            if(this.state.selectedPro.value){
                urlWithParams = urlWithParams + `&idproveedor=${this.state.selectedPro.value}`
            }
        }

        this.getAllComprasFromAPI(urlWithParams);

    }

    generarPdf(){

        let rows = this.state.compras.map(compra => [
            compra.idcompra, 
            compra.proveedor, 
            compra.documento, 
            compra.fecha_compra, 
            compra.cantidad_compraF,
            compra.precio_unitarioF, 
            compra.peso_compraF, 
            compra.peso_mermaF,
            compra.precio_totalF,
            compra.precio_total_mermaF,
            compra.saldoF
            ]);


        let fecha_desde = formatearFecha(this.state.fechaDesde, "spanish")
        let fecha_hasta = formatearFecha(this.state.fechaHasta, "spanish")
          
        let fila = this.state.columnas.reduce((prev, current)=> [...prev, current.dato], []);
        
        var doc = new jsPDF('p', 'pt');
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.setFontStyle('normal');
          doc.text("AVICOLA CANIZ", 20, 50);

          doc.setFontSize(12);
          doc.setTextColor(22);
          doc.setFontStyle('normal');
          doc.text(`Rango de fechas: ${fecha_desde} al ${fecha_hasta} `, 20, 70);

          doc.setFontSize(12);
          doc.setTextColor(22);
          doc.setFontStyle('normal');
          doc.text(`Proveedor: ${this.state.selectedPro.label}`, 20, 90);

         
          
          doc.autoTable({
              head : [['AVES', 'PESO SIN MERMA / CON MERMA', 'MONTO SIN MERMA / CON MERMA', 'COMPRAS']],
              body : [fila],
              pageBreak : 'avoid',
              startY: doc.autoTableEndPosY() + 100,
              margin: { horizontal: 20 },
              bodyStyles: { valign: 'top', halign: 'center' },
              styles: { overflow: 'linebreak' },
              theme: "striped"
          })
          
          doc.autoTable({
            head: [['Id', 'Proveedor', 'No. Doc.', 'Fecha', 'No. Aves', 'P/U', 'Peso total', 'Peso con M.', 'Monto', 'Monto con M.', 'Saldo']],
            body: rows.sort((a, b) => a[0] - b[0]),
            pageBreak : 'avoid',
            startY: doc.autoTableEndPosY() + 50,
            margin: { horizontal: 20 },
            styles: { overflow: 'linebreak' },
            bodyStyles: { valign: 'top', fontSize: 8 },
            theme: "striped"
          });

           let date = new Date();

           let ano = date.getFullYear();
           let mes = (date.getMonth()+1) < 10? `0${date.getMonth()+1}` : date.getMonth()+1;
           let dia = date.getDate() < 10? `0${date.getDate()}`: date.getDate();
           let hora = date.getHours();
           let min = date.getMinutes();

           let fechayhora = `${dia}/${mes}/${ano} -- ${hora}:${min}`;
          
           let str = `Generado por: ${this.props.user}       Fecha: ${fechayhora}`
          
            doc.setFontSize(10);
            doc.text(str, 50, doc.internal.pageSize.height - 15);

          doc.save('Compras.pdf');
    }

    editFormatter = (cell, row)=>{
        return <button className="btn btn-info" onClick={()=>this.updateCompra(cell)}>Editar</button>
    }

    render(){

       
      const sizeTextBody = 11

        return(
            <div className="container">
                 <Modal
                         isOpen={this.state.modalIsOpen}
                         onAfterOpen={this.afterOpenModal}
                         onRequestClose={this.closeModal}
                         aria={{
                            labelledby: "heading",
                            describedby: "full_description"
                          }}
                         contentLabel="Example Modal"
                         style={customStylesModal}
                  >
                    <Compra isModal={true} closeModal={this.closeModal} selectedRow={this.state.selectedRow} getAllComprasFromAPI={this.consultar}></Compra>
                </Modal>
                  <br></br>
                  <Link to="/compras" className="btn btn-success"><i className="fa fa-plus"></i> Nueva Compra</Link>
                  <br></br>
                  <br></br>
                  <FilaInformativa2
                    columnas={this.state.columnas}
                  ></FilaInformativa2>
                  <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <h6 className="card-header bg-default"><i className="fas fa-search"></i> FILTROS DE BUSQUEDA</h6>
                                 <div className="card-body">
                                   <div className="row">
                                       <div className="col-md-2">
                                           <label className="label">Desde: </label>
                                            <DatePicker
                                                className="form-control"
                                                selected={this.state.fechaDesde}
                                                onChange={this.changeDesde}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                >
                                            </DatePicker>
                                       </div>
                                       <div className="col-md-2">
                                            <label className="label">Hasta: </label>
                                            <DatePicker
                                                className="form-control"
                                                selected={this.state.fechaHasta}
                                                onChange={this.changeHasta}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                >
                                            </DatePicker>
                                       </div>
                                       <div className="col-md-3">
                                            <label className="label">Proveedor: </label>
                                            <Select
                                               defaultValue ={this.state.selectedPro}
                                               onChange={this.selecProveedor}
                                               options={this.state.proveedores}
                                            >
                                            </Select>
                                       </div>
                                       <div className="col-md-2">
                                            <br></br>
                                            <button className="btn btn-success" onClick={this.consultar}><i className="fas fa-search"></i> Consultar</button>
                                       </div>
                                       <div className="col-md-2">
                                            <br></br>
                                            <button className="btn btn-info" onClick={this.generarPdf}> <i className="fas fa-file-pdf"></i> Generar PDF</button>
                                       </div>
                                   </div>
                                       
                                 </div>
                            </div>
                        </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                    <div className="card">
                    <h6 className="card-header bg-default"><i className="fas fa-list-ol"></i> LISTADO DE COMPRAS</h6>
                        <div className="card-body">
                              <BootstrapTable data={this.state.compras}  search={ true } searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                                <TableHeaderColumn isKey dataField='idcompra' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:60}} thStyle={{fontSize: 11,width:60, backgroundColor: 'grey', color: 'white'}}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='proveedor' dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:150}} thStyle={{fontSize: 11, width:150, backgroundColor: 'grey', color: 'white'}}>Proveedor</TableHeaderColumn>
                                <TableHeaderColumn dataField='documento' tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Tipo Doc.</TableHeaderColumn>
                                <TableHeaderColumn dataField='fecha_compra' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Fecha</TableHeaderColumn>
                                <TableHeaderColumn dataField='cantidad_compraF' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: 11,  backgroundColor: 'grey', color: 'white'}}>Cantidad Compra</TableHeaderColumn>
                                <TableHeaderColumn dataField='precio_unitarioF' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Precio Unitario</TableHeaderColumn>
                                <TableHeaderColumn dataField='peso_compraF' dataSort={ true }    tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Peso Compra</TableHeaderColumn>
                                <TableHeaderColumn dataField='porcentaje_merma' dataSort={ true }   tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>% Merma</TableHeaderColumn>
                                <TableHeaderColumn dataField='peso_mermaF' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 10, backgroundColor: 'grey', color: 'white'}}>Peso Con Merma</TableHeaderColumn>
                                <TableHeaderColumn dataField='precio_totalF' dataSort={ true } tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 10, backgroundColor: 'grey', color: 'white'}}>Monto</TableHeaderColumn>
                                <TableHeaderColumn dataField='precio_total_mermaF' dataSort={ true } tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, width:80,backgroundColor: 'grey', color: 'white'}}>Monto Con Merma</TableHeaderColumn>
                                <TableHeaderColumn dataField='saldoF' dataSort={ true } tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, width:80,backgroundColor: 'grey', color: 'white'}}>Saldo</TableHeaderColumn>
                                <TableHeaderColumn dataField='idcompra'  dataFormat={ this.editFormatter} tdStyle={{width:80}} thStyle={{fontSize: 11, width:80,backgroundColor: 'grey', color: 'white'}}></TableHeaderColumn>
                            </BootstrapTable>
                         </div>
                    </div>
                    </div>
                  </div>
            </div>
           
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated : state.authenticated,
    user : state.user,
    errorAuthentication : state.errorAuthentication
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type : 'LOGOUT'})
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(IndiceCompra);
