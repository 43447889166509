import React, {Component} from 'react'
import {Bar, Pie} from 'react-chartjs-2'
import {connect} from 'react-redux'
import FilaInformativa  from '../helpers/FilaInformativa'
import AxiosInstance from '../helpers'
import URI_BACKEND from '../confProyecto'


const configurationBar = {
    label: 'Totales',
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(255, 159, 64, 0.2)',
  ],
  borderColor: [
      'rgba(255,99,132,1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255,99,132,1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
  ],
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
    hoverBorderColor: 'rgba(255,99,132,1)'
};

const verticalOrientationLabel = {
  xAxes: [{
      ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90
      }
  }],
  yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
}

const meses = [
            {
                numero : 1,
                nombre : "Enero"
            },
            {
                numero : 2,
                nombre : "Febrero"
            },
            {
                numero : 3,
                nombre : "Marzo"
            },
            {
                numero : 4,
                nombre : "Abril"
            },
            {
                numero : 5,
                nombre : "Mayo"
            },
            {
                numero : 6,
                nombre : "Junio"
            },
            {
                numero : 7,
                nombre : "Julio"
            },
            {
                numero : 8,
                nombre : "Agosto"
            },
            {
                numero : 9,
                nombre : "Septiembre"
            },
            {
                numero : 10,
                nombre : "Octubre"
            },
            {
                numero : 11,
                nombre : "Noviembre"
            },
            {
                numero : 12,
                nombre : "Diciembre"
            },
        ]

        const options = {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              position: 'left',
              labels: {
                boxWidth: 10
              }
            }
          }


class Home extends Component{

    currentDate = new Date();

    constructor(props){
        super(props)

        this.changeValues = this.changeValues.bind(this);

        this.state ={
            total_ventas : 0,
            total_compras : 0,
            total_aves: 0,
            total_peso: 0,
            dataPie :  {
                labels: [],
                datasets: [
                  {
                    ...configurationBar,
                    data: []
                  }
                ]
              },
            digitFormater : new Intl.NumberFormat('en-US'),
            year : this.currentDate.getFullYear(),
            yearOptions : [0,2018,2019,2020,2021,2022,2023,2024,2025],
            columnas: [{titulo:  'TOTAL VENTAS',  dato: 0, signo: 'Q.', icono:<i className="fa fa-save"></i>},
                        {titulo: 'TOTAL COMPRAS', dato: 0, signo: 'Q.', icono:<i className="fa fa-save"></i>},
                        {titulo: 'CANTIDAD AVES', dato: 0, signo: 'Q.', icono:<i className="fa fa-save"></i>},
                        {titulo: 'TOTAL PESO',    dato: 0, signo: 'Q.', icono:<i className="fa fa-save"></i>}
                    ],
            
            dataBar : {
                labels: [],
                datasets: [
                  {
                    ...configurationBar,
                    data: []
                  }
                ]
              },
            empleados_vendedores : []
        }
    

        
    }


    componentDidMount(){
       this.getData();
    }

    getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
  }


    getData(){
        AxiosInstance({
            method : 'get',
            url : '/api/getdatosdashboard',
            params : {
                year : this.state.year
            }
        }).then((res)=>{

                const getventasperyear = this.state.digitFormater.format(Number(res.data.getventasperyear)); //formateado a coma para miles y punto para decimales
                const total_compras = this.state.digitFormater.format(Number(res.data.total_compras));
                const cantidad_aves = this.state.digitFormater.format(Number(res.data.cantidad_aves));
                const total_peso = this.state.digitFormater.format(Number(res.data.total_peso));

                //LLENANDO INFO DE DIAGRAMA DE BARRAS
                let datosGraficaBar = []
                let labelsGraficaBar = []

                    labelsGraficaBar = meses.map(mes => mes.nombre);

                    meses.forEach(mes => {

                        let valor = res.data.datos_ventas_mes.find(ventames => ventames.monthr === mes.numero)?.total_ventas || 0;
                        datosGraficaBar.push(Number(valor));
                       
                    })

                //LLENANDO INFO DE PIE    
                let datosGraficaPie = []
                let labelsGraficaPie = []
                let colores = []

                res.data.porcentaje_ventas.forEach(empleado => {
                     labelsGraficaPie.push(empleado.nombre);
                     datosGraficaPie.push(Number(empleado.porcentaje_numaves));
                     colores.push(this.getRandomColor());
                })


               

                //CAMBIANDO EL ESTADO

                this.setState({
                    columnas: [ {titulo: 'TOTAL VENTAS',   dato: getventasperyear, signo: 'Q.', icono:<i className="fa fa-save"></i>},
                                {titulo: 'TOTAL COMPRAS',  dato: total_compras,    signo: 'Q.', icono:<i className="fa fa-save"></i>},
                                {titulo: 'CANTIDAD AVES',  dato: cantidad_aves,    signo: '',   icono:<i className="fa fa-save"></i>},
                                {titulo: 'TOTAL PESO',     dato: total_peso,       signo: '',   icono:<i className="fa fa-save"></i>}
                              ],

                    dataBar : {
                        labels: labelsGraficaBar,
                        datasets: [
                            {
                            ...configurationBar,
                            data: datosGraficaBar
                            }
                        ]
                    },
                    dataPie : {
                            labels: labelsGraficaPie,
                            datasets: [
                                {
                                    data: datosGraficaPie,
                                    backgroundColor : [...colores]
                                }
                            ]
                        },
                    empleados_vendedores : res.data.porcentaje_ventas
                })
            

        }).catch(e=>{
            console.log(e);
        })
    }


     changeValues(e){
        this.setState({
            year : Number(e.target.value)
        })
     }

     componentDidUpdate(prevProps, prevState){
           if (prevState.year !== this.state.year){
               this.getData();
           }
     }


    render(){
        if (Number(this.props.idperfil) === 1){
    
                return (
                    <div className="container">
                        <br></br>
                        <div className="row justify-content-end">
                            <div className="col-md-3">
                                 <select className="form-control" value={this.state.year} name="year" onChange={this.changeValues}>
                                        {
                                            this.state.yearOptions.map(option=>
                                                    <option key={option} value={option}>{option === 0? 'Todos' : option}</option>
                                                )
                                        }
                                 </select>
                            </div>
                        </div>
                        <br></br>
                        <FilaInformativa columnas={this.state.columnas}/>
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="card">
                                        <div className="card-header">
                                            <h6>Ventas por Mes</h6>
                                        </div>
                                        <div className="card-body">
                                            <Bar
                                            data={this.state.dataBar}
                                            height={350}
                                            options={{
                                                maintainAspectRatio: false,
                                                scales: verticalOrientationLabel
                                            }}
                                            />
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                                <div className="col-md-8">
                                        <div className="card">
                                                <div className="card-header">
                                                    <h6>% Ventas por Empleado</h6>
                                                </div>
                                                <div className="card-body">
                                                <table className="table table-hover table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Foto</th>
                                                                <th>Nombre</th>
                                                                <th>Porcentaje Ventas</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.empleados_vendedores.map((vendedor, i) => {
                                                                        return (<tr key={`u${vendedor.idempleado}`}>
                                                                                    <td>{i}</td>
                                                                                    <td><img src={vendedor.url_photo ? `${URI_BACKEND}${vendedor.url_photo}` : 'images/anonimo.png'} style={{width: 50, height: vendedor.url_photo ? 50 : 40, borderRadius: "50%"}}/></td>
                                                                                    <td>{vendedor.nombre}</td>
                                                                                    <td>
                                                                                            {vendedor.porcentaje_venta}%
                                                                                            <div className="progress">
                                                                                                    <div className={`progress-bar ${i === this.state.empleados_vendedores.length-1? 'bg-danger' : 'bg-success' }`} role="progressbar" style={{width: `${vendedor.porcentaje_venta}%`}} aria-valuenow={vendedor.porcentaje_venta} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                            </div>
                                                                                    </td>
                                                                                </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                </table>
                                                    
                                                </div>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                       <div className="card">
                                            <div className="card-header"><h6>% Aves vendidas por Empleado</h6></div>
                                            <div className="card-body">
                                                <Pie data={this.state.dataPie} height={150} width={200} options={options}/>
                                            </div>
                                       </div>
                                </div>
                        </div>
                    </div>
                )
        }else {
            return <div className="row justify-content-center">
                    <div className="col-md-10">
                          <br></br>
                         <h1>BIENVENIDOS AL SISTEMA DE CANIZ</h1>
                    </div>
                </div>
        }
    }
}


const mapStateToProps = (state) => ({
    authenticated : state.authenticated,
    user : state.user,
    idperfil : state.idperfil,
    clicked : state.clicked,
    accesos : state.accesos,
    routesForComponent : state.routesForComponent,
    errorAuthentication : state.errorAuthentication
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type : 'LOGOUT'})
    }
})

export default  connect(mapStateToProps, mapDispatchToProps)(Home);