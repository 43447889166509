import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import Select from 'react-select';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import AxiosInstance from '../helpers';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {formatearFecha, formatearDigitos} from '../helpers/helperFuncs'
//import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';


registerLocale('es', es)
const SwalAlert = withReactContent(Swal);

const PagoComponente = (props) => {

    const [date, changeDate] = useState(new Date());
    const [selected, changeCliente] = useState(null);
    const [clientes, changeList] = useState([]);
    const [listabonos, changeListAbonos] = useState([]);
    const [tipospagoslist, changeTiposPagosList] = useState([]);
    const [tiposdocslist, changeTiposDocList] = useState([]);

    const [descripcion, changeDescripcion] = useState('');

    const [nuevoabono, changeNuevoAbono] = useState(0); 
    const [cantidad_abono, changeCantidadAbono] = useState(0);
    const [pendiente, changePendiente] = useState(0);
    const [pendienteDinamico, changePendienteDinamico] = useState(0);

    const [totalVendido, changeTotalVendido] = useState(0);  //Con descuento
    const [totalVendidoCompleta, changeTotalVentaCompleta] = useState(0);   //Sin descuento (Completa)
    const [descontado, setDescontado] = useState(0); //Lo vendido menos descuentos o mermas y mortalidades

    const [tipopago, changeTipoPago] = useState(null);
    //const [tipodocumento, changeTipoDocumento] = useState(null);
    const [numero_documento, changeNumeroDocumento] = useState('');

    const [modalIsOpenFile, changeModalEstate] = useState(false);

    const [esEfectivo, changeEstadoEfectivo] = useState(false);

    const [nombre, changeNombre] = useState('');


    useEffect(()=>{
        AxiosInstance({
            method : 'get',
            url : '/api/getclientsanddocs'
        }).then(res => {
            
       

          let allclients = res.data.all.map(cli => ({value : cli.idcliente, label : cli.cliente}));
          let alltipospagos = res.data.tipoPago.map(tipo => ({value: tipo.idtipopago, label: tipo.tipopago}));
          let alltiposdocs = res.data.tipoDocumento.map(tipo => ({value : tipo.idtipodocumento, label: tipo.tipo_documento}));
          changeList(allclients);  
          changeTiposPagosList(alltipospagos);
          changeTiposDocList(alltiposdocs);
        })
    }, [])


        async function seleccionaCliente (cliente){
            let selectedclient = cliente;
            changeNombre(cliente.label);
            changeCliente(cliente);
            await consultarSaldos(selectedclient);
        }

      async function consultarSaldos (selectedClient){
      let res = await  AxiosInstance({
            method : 'post',
            url: '/api/getsaldo',
            data : {
                idcliente : selectedClient.value
            }
        });
 
        console.log(res.data);
      
      let totalAbono = Number(res.data.cantidad_abono);
      let totalVenta = Number(res.data.totalvendido);
      let totalVentaCompleta = Number(res.data.totalvendidocompleto);
      let totalMortalidad = Number(res.data.cantidad_mortalidad);

      let listAbonos = res.data.abonolist.map(abono => ({idabono: abono.idabono, 
        fecha_abono : abono.fecha_abono,
        cliente: abono.cliente.cliente,
        cantidad : abono.cantidad,
        tipos_pago : abono.tipos_pago.tipopago,
        numero_documento : abono.numero_documento,
        descripcion : abono.descripcion
     }))

      changeListAbonos(listAbonos);

      changeCantidadAbono(totalAbono); //Lo que ya ha pagado
      changeTotalVendido(totalVenta);   //Con descuento
      changeTotalVentaCompleta(totalVentaCompleta)  //Sin descuento (Completa)
      setDescontado(totalVenta - totalMortalidad); //Lo que realmente debe pagar (Monto liquido desde la primera venta)
      changePendiente(totalVenta - totalMortalidad - totalAbono); //Lo que le resta por pagar
 
    }

    function mostrarModalAbono(){
        changeModalEstate(true);
    }

    function cerrarModalAbono(){
        changeModalEstate(false);
    }

    function registrarAbono(){
        guardarAbono();
    }

    function guardarAbono(){

        if(nuevoabono === 0 || tipopago === null){
            SwalAlert.fire({
                title: 'CAMPOS VACIOS O INVALIDOS',
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                },
                showConfirmButton: false,
                timer: 2000
              }).then(result => {
                   return;
              });
        }else{
            SwalAlert.fire({
                title: 'Espere',
                html: 'Actualizando...',
                timerProgressBar: true,
                onBeforeOpen: () => {
                  Swal.showLoading()
                }
            })

            AxiosInstance({
                method : 'post',
                url : '/api/saveabono',
                data : {
                    idcliente : selected.value,
                    idtipopago : tipopago.value,
                    numero_documento : numero_documento,
                    fecha_abono : date,
                    cantidad : nuevoabono, 
                    user : props.user,
                    descripcion : descripcion,
                    path_documento : ''
                }
            }).then(res=> {
                    SwalAlert.close();
                    SwalAlert.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Guardado exitosamente',
                        showConfirmButton: false,
                        timer: 900
                    }).then(result => {
                        consultarSaldos(selected).then(res => {
                            cerrarModalAbono(false);
                            limpiar();
                        });
                    });
            })
        }
    }

    function onChangeAbono (abono){
        let val = abono.target.value;
        changeNuevoAbono(val);
        changePendienteDinamico(pendiente - val);
    }

    function onChageNumero(numero){
        changeNumeroDocumento(numero.target.value);
    }

    function limpiar (){
        changeNuevoAbono(0);
        changeDescripcion('');
        changeNumeroDocumento('');
    }

    function seleccionaTipoPago (tipo){
        if(tipo.label === "Efectivo"){
             changeEstadoEfectivo(true);
        }else{
             changeEstadoEfectivo(false);
        }
        changeTipoPago(tipo);
    }

    async function eliminarAbono(id){

        SwalAlert.fire({
            title: 'Espere',
            html: 'Actualizando...',
            timerProgressBar: true,
            onBeforeOpen: () => {
              Swal.showLoading()
            }
        })
         
       let res = await  AxiosInstance({
            method:'post',
            url :'/api/deleteabono',
            data : {
                idabono : id
            }
        });
        await consultarSaldos(selected);

            SwalAlert.close();
            SwalAlert.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Actualizado exitosamente',
                showConfirmButton: false,
                timer: 900
            }).then(result => {
                console.log(result);
            });
    }

    function eliminarFormater(cell, row){
        return <Button variant="contained" color="secondary" onClick={()=>eliminarAbono(cell)}><i className="fa fa-cut"></i></Button>
    }

    function fechaFormater(cell, row){
        return formatearFecha(cell);
    }

  
    function onChangeDescripcion(des){
        changeDescripcion(des.target.value);
    }


    const formatearNumero = (cell, row)=>{
       return formatearDigitos(cell);
    }

    
      const sizeTextBody = 11

   

    
return (<div className="container">
        <Modal size="lg" show={modalIsOpenFile}>
                            <Modal.Header closeButton>
                            <Modal.Title> <i className="fas fa-search"></i> Registro de Pago - {nombre}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-2">
                                    <label>Cantidad</label>
                                       <input type ="number" className="form-control" value={nuevoabono} onChange={onChangeAbono}></input>
                                    </div>
                                    <div className="col-md-3">
                                    <label>Tipo Pago</label>
                                    <Select
                                        isDisabled={false}
                                        value={tipopago}
                                        options={tipospagoslist}
                                        onChange ={seleccionaTipoPago}
                                    >
                                    </Select>
                                    </div>
                                    <div className="col-md-3">
                                    <label>No. Doc.</label>
                                        <input type ="number" className="form-control" disabled={esEfectivo} value={numero_documento} onChange={onChageNumero}></input>
                                    </div>
                                    <div className="col-md-3">
                                    <label>Fecha</label>
                                    <DatePicker
                                                    className="form-control"
                                                    selected={date}
                                                    onChange={changeDate}
                                                    disabled={false}
                                                    dateFormat="dd/MM/yyyy"
                                                    locale="es"
                                                    >
                                    </DatePicker>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Descripción</label>
                                        <textarea className="form-control" rows="5" cols="20" value={descripcion} onChange={onChangeDescripcion}></textarea>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-3">
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr><th className="bg-info"><i class="fas fa-money-check-alt"></i> Pendiente Real </th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><h3 style={{textAlign : "center"}}>Q.{formatearDigitos(pendienteDinamico)}</h3></td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-4">
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr><th className="bg-info"><i class="fas fa-money-check-alt"></i> Pendiente Registrado </th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><h3 style={{textAlign : "center"}}>Q.{formatearDigitos(pendiente)}</h3></td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="contained" color="primary" onClick={registrarAbono}>
                                Guardar
                            </Button>
                            <Button variant="contained" color="secondary" onClick={cerrarModalAbono}>
                                Cerrar
                            </Button>
                            </Modal.Footer>
                    </Modal>
        <br></br>
         <div className="card">
             <div className="card-header"><h5><i class="fas fa-dollar-sign"></i> INFORMACION DE PAGOS</h5></div>
             <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                    <label>Cliente</label>
                        <Select
                            isDisabled={false}
                            value={selected}
                            options={clientes}
                            onChange ={seleccionaCliente}
                        >
                        </Select>

                    </div>
                    <div className="col-md-4">
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr><th className="bg-info"><i class="fas fa-money-check-alt"></i> Total Vendido</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><h3 style={{textAlign : "center"}}>Q.{formatearDigitos(totalVendidoCompleta)}</h3></td></tr>
                                            </tbody>
                                        </table>
                    </div>
                    <div className="col-md-4">
                                       <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr> <th className="bg-info"><i class="fas fa-money-check-alt"></i> Menos Descuentos</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><h3 style={{textAlign : "center"}}>Q.{formatearDigitos(descontado)}</h3></td></tr>
                                            </tbody>
                                        </table>
                    </div>
                </div>
                <div className="row">
                        <div className="col-md-3">
                                <br></br>
                                <Button variant="contained" disabled={selected === null} color="secondary" onClick={mostrarModalAbono}>
                                        Registrar Abono
                                </Button>
                                <br></br>
                        </div>
                        <div className="col-md-4">
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr><th className="bg-success"><i class="fas fa-money-check-alt"></i> Abonado</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><h3 style={{textAlign : "center"}}>Q.{formatearDigitos(cantidad_abono)}</h3></td></tr>
                                            </tbody>
                                        </table>
                        </div>
                        <div className="col-md-4">
                                        <table className="table table-bordered table-sm">
                                                <thead>
                                                    <tr> <th className="bg-danger"><i class="fas fa-money-check-alt"></i> Pendiente</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr><td><h3 style={{textAlign : "center"}}>Q.{formatearDigitos(pendiente)}</h3></td></tr>
                                                </tbody>
                                            </table>
                        </div>
                                
                </div>
             </div>
         </div>
         <br></br>
         
         <br>
         </br>
         <div className="card" style={{padding: 10}}>
                <BootstrapTable data={listabonos}  search={ true } searchPlaceholder={'Buscar...'} pagination={ true }  version='4' className="table table-hover table-sm">
                        <TableHeaderColumn isKey dataField='idabono'    dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:60}} thStyle={{fontSize: 11,width:60, backgroundColor: 'grey', color: 'white'}}>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='cliente'          dataSort={ true } tdStyle={{fontSize:sizeTextBody, width:150}} thStyle={{fontSize: 11, width:150, backgroundColor: 'grey', color: 'white'}}>Cliente</TableHeaderColumn>
                        <TableHeaderColumn dataField='tipos_pago'       dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Tipo Pago</TableHeaderColumn>
                        <TableHeaderColumn dataField='numero_documento' dataSort={ true }  tdStyle={{fontSize:sizeTextBody}}thStyle={{fontSize: 11,  backgroundColor: 'grey', color: 'white'}}>No. Doc.</TableHeaderColumn>
                        <TableHeaderColumn dataField='fecha_abono'      dataFormat={fechaFormater} dataSort={ true }  tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Fecha Abono</TableHeaderColumn>
                        <TableHeaderColumn dataField='cantidad'         dataFormat={formatearNumero} dataSort={ true }    tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Cantidad</TableHeaderColumn>
                        <TableHeaderColumn dataField='descripcion'      tdStyle={{fontSize:sizeTextBody}} thStyle={{fontSize: 11, backgroundColor: 'grey', color: 'white'}}>Descripcion</TableHeaderColumn>
                        <TableHeaderColumn dataField='idabono'          dataFormat={eliminarFormater} tdStyle={{width:80}} thStyle={{fontSize: 11, width:80,backgroundColor: 'grey', color: 'white'}}>Eliminar</TableHeaderColumn>
                    </BootstrapTable>
         </div>

        </div>)
}

const mapStateToProps = state => ({
    user : state.user
})

const mapDispatchToProps = dispatch => ({
    logout(){
        sessionStorage.clear();
        dispatch({type: 'LOGOUT'});
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(PagoComponente);

